import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery, useMutation} from '@apollo/client';

import {Button, Form, Item, Icon, Modal, ModalActions, Placeholder, Image} from 'semantic-ui-react';
import {GET_CURRENT_USER, GET_USER} from "../../../graphql/Queries";
import {
    getPermissionsArray,
    getPermissionTags,
    hasPermission,
    PERMISSIONS
} from "../../../utilities/PermissionUtils";
import {AllCharacters} from "../Character/CharacterCardPage";
import {SAVE_PERMISSIONS} from "../../../graphql/Mutations";
import {compose} from "recompose";
import {withAuthorization, withEmailVerification} from "../../Features/Session";
import PlayerLogTable from "./PlayerLogTable";

const permissionOptions = [
    {key: PERMISSIONS.PLAYER, text: "Player", value: PERMISSIONS.PLAYER, color: 'purple'},
    {key: PERMISSIONS.ARTIST, text: "Artist", value: PERMISSIONS.ARTIST, color: 'pink'},
    {key: PERMISSIONS.ARCHIVIST, text: "Archivist", value: PERMISSIONS.ARCHIVIST, color: 'green'},
    {key: PERMISSIONS.INSPECTOR, text: "Inspector", value: PERMISSIONS.INSPECTOR, color: 'teal'},
    {key: PERMISSIONS.GM, text: "Game Master", value: PERMISSIONS.GM, color: 'blue'},
    {key: PERMISSIONS.ADMIN, text: "Admin", value: PERMISSIONS.ADMIN, color: 'yellow'}
];

const PlayerPage = () => {
    const {data: myData} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'cache-and-network'});
    const [permissionsOpen, setPermissionsOpen] = useState(false);
    const closePermissions = () => setPermissionsOpen(false);
    const me = (myData && myData.CurrentUser) || {Permissions: PERMISSIONS.NONE};
    const [permissionValues, setPermissionValues] = useState([]);
    const [savePermissions] = useMutation(SAVE_PERMISSIONS, {
        onCompleted () {
            setPermissionsOpen(false);
        },
        onError() {}
    });

    const onChange = (e, {value}) => {
        setPermissionValues(value);
    };

    const permissionsRenderLabel = (label) => ({
        color: label.color,
        content: label.text
    });

    const {userID} = useParams();
    const {data: playerData, loading} = useQuery(GET_USER, {
        variables: {userID},
        fetchPolicy: 'cache-and-network'
    });

    const player = (playerData && playerData.User) || {};

    const onSubmit = () => {
        const permissions = permissionValues.reduce( (acc, val) => acc + val, 0);
        savePermissions({
            variables: {
                userID: player.id,
                permissions
            }
        });
    };

    useEffect(() => {
        if (player.Permissions) {
            setPermissionValues(getPermissionsArray(player.Permissions));
        }
    }, [player]);

    return (
        <>
            <Item.Group divided unstackable>
                <Item>
                    {loading && !player.id
                        ? (<Placeholder style={{
                            marginRight: '8px',
                            width: '100px',
                            height: '100px'
                        }}><Placeholder.Image/></Placeholder>)
                        : (<img width={100} height={100} src={player.Avatar || '/blank_profile_image.jpg'}
                                style={{
                                    marginRight: '8px',
                                    'maxWidth': '100px',
                                    'maxHeight': '100px',
                                    'objectFit': 'cover'
                                }}/>)
                    }
                    <Item.Content>
                        {loading && !player.id
                            ? (
                                <Placeholder>
                                    <Placeholder.Line/>
                                    <Placeholder.Line/>
                                </Placeholder>
                            )
                            : (
                                <>
                                    <Item.Header>{player.Name}</Item.Header>
                                    <Item.Description>
                                        <p>{hasPermission(me.Permissions, PERMISSIONS.ADMIN) &&
                                        <Icon circular link name='setting' onClick={() => setPermissionsOpen(true)}/>}
                                            {getPermissionTags(player.Permissions)}</p>
                                        <p title='Primordia Shards'><Image avatar src='/favicon-32x32.png' /> {player.PrimordiaShards}</p>
                                    </Item.Description>
                                </>
                            )
                        }

                    </Item.Content>
                </Item>
                <Item>
                    <Item.Content>
                        <h3>
                            {player.Name ? `${player.Name}'s Characters` : 'Characters'}
                        </h3>
                        <AllCharacters characters={player.Characters} loading={loading && !player.Characters}/>
                    </Item.Content>
                </Item>
            </Item.Group>
            {player.Logs &&
                <PlayerLogTable logs={player.Logs}/>
            }
            <Modal size='mini' open={permissionsOpen} onClose={closePermissions}>
                <Modal.Header>Set Permissions for {player.Name}</Modal.Header>
                <Modal.Content>
                    <Form.Dropdown
                        multiple
                        selection
                        fluid
                        required
                        width={16}
                        label="Permissions"
                        name="Permissions"
                        options={permissionOptions}
                        placeholder="Select Permissions"
                        renderLabel={permissionsRenderLabel}
                        onChange={onChange}
                        value={permissionValues}
                    />
                </Modal.Content>
                <ModalActions>
                    <Button negative onClick={closePermissions}>
                        Cancel
                    </Button>
                    <Button positive onClick={onSubmit}>
                        Submit
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default PlayerPage;
