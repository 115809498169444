import gql from 'graphql-tag';

export const CHARACTER_FRAGMENT = gql`
    fragment CharacterFragment on CharacterType {
        id: CharacterID
        CharacterID
        UserID
        User {
          __typename
          id: UserID
          UserID
          Name
        }
        Name
        Race
        Level
        Experience
        Mana
        Attunement
        Mythic
        Roles
        Description
        Avatar
        DateUpdated
        Status
        SheetURL
        Type
        TypeName
        ItemApproval
        Approver {
          __typename
          id: UserID
          UserID
          Name
        }
    }
`;

export const PLAYER_CHARACTERS_COMPANION_FRAGMENT = gql`
    fragment OwnerCharacterCompanionFragment on CharacterType {
        id: CharacterID
        CharacterID
        UserID
        User {
          __typename
          id: UserID
          UserID
          Name
          Permissions
        }
        Name
        Race
        Level
        Experience
        Mana
        Attunement
        Mythic
        Roles
        Description
        Avatar
        DateUpdated
        Status
        SheetURL
        ItemApproval
        Approver {
          __typename
          id: UserID
          UserID
          Name
        }
        Type
        TypeName
        LinkedCharacters {
          ...CharacterFragment
        }
        ParentCharacter {
           ...CharacterFragment
        }
    }
    ${CHARACTER_FRAGMENT}
`;

export const CHARACTER_LOG_FRAGMENT = gql`
    fragment CharacterLogFragment on CharacterLogType {
        id: LogID
        LogID
        Experience
        Mana
        Attunement
        Metadata
        Type
        Status
        DateCreated
        DateUpdated
    }
`;

export const PLAYER_LOG_FRAGMENT = gql`
    fragment PlayerLogFragment on PlayerLogType {
        id: LogID
        LogID
        PrimordiaShards
        Metadata
        Type
        Status
        DateCreated
        DateUpdated
    }
`;

export const SESSION_FRAGMENT = gql`
    fragment SessionFragment on SessionType {
      id: SessionID
      SessionID
      GameMaster {
        __typename
        id: UserID
        UserID
        Name
      }
      SignupCharacters {
        __typename
        ...CharacterFragment
      }
      PlayerCharacters {
        __typename
        ...CharacterFragment
      }
      GameMasterCharacter {
        __typename
        ...CharacterFragment
      }
      Title
      DateUpdated
      DateCreated
      Status
      MinLevel
      MaxLevel
      Format
      StartDate
      Rewards
      SessionCloseDate
      Runtime
      Prologue
      Epilogue
      EstimatedLength
      Difficulty
      PlayerCount
    }
    ${CHARACTER_FRAGMENT}
`;

export const ARTICLE_BASE_FRAGMENT = gql`
    fragment ArticleBaseFragment on ArticleType {
      id
      ArticleType
      Author {
        __typename
        id: UserID
        UserID
        Name
      }
      Title
      Summary
      Categories
      Location
      DateTime
      Approver {
        __typename
        id: UserID
        UserID
        Name
      }
      ApprovalDate
      Status
      DateCreated
      DateUpdated
    }
`;

export const ARTICLE_FULL_FRAGMENT = gql`
    fragment ArticleFullFragment on ArticleType {
      id
      ArticleType
      Author {
        __typename
        id: UserID
        UserID
        Name
      }
      Title
      Summary
      Categories
      Location
      DateTime
      Approver {
        __typename
        id: UserID
        UserID
        Name
      }
      Post {
        __typename
        id
        Content
      }
      ApprovalDate
      Status
      DateCreated
      DateUpdated
    }
`;

export const POST_FRAGMENT = gql`
    fragment PostFragment on PostType {
      id
      Type
      UserID
      Content
      Status
      DateCreated
      DateUpdated
    }
`;

export const INFO_CARD_FRAGMENT = gql`
    fragment InfoCardFragment on InfoCardType {
      __typename
      id
      Metadata
      Type
      Image
      Content
      Status
      CreatorUser {
        __typename
        id: UserID
        Name
      }
      UpdaterUser {
        __typename
        id: UserID
        Name
      }
      DateCreated
      DateUpdated
      CanEdit
      Visibility
    }
`;

export const ITEM_FRAGMENT = gql`
    fragment ItemFragment on ItemType {
        __typename
        id
        Creator {
            __typename
            id: UserID
            Name
        }
        InfoCard {
            ...InfoCardFragment
        }
        MarketCost
        CraftCost
        CreateType
        Location
    }
    ${INFO_CARD_FRAGMENT}
`;

export const FULL_CHARACTER_FRAGMENT = gql`
    fragment FullCharacterFragment on CharacterType {
        id: CharacterID
        CharacterID
        UserID
        User {
          __typename
          id: UserID
          UserID
          Name
          Permissions
        }
        Name
        Race
        Level
        Experience
        Mana
        Attunement
        Mythic
        Roles
        Description
        Avatar
        DateUpdated
        Status
        SheetURL
        Sheet
        Type
        TypeName
        ItemApproval
        Inventory {
          ...ItemFragment
        }
        Approver {
          __typename
          id: UserID
          UserID
          Name
        }
        Logs {
          ...CharacterLogFragment
        }
        LinkedCharacters {
          ...OwnerCharacterCompanionFragment
        }
        ParentCharacter {
          ...OwnerCharacterCompanionFragment
        }
    }
    ${ITEM_FRAGMENT}
    ${CHARACTER_LOG_FRAGMENT}
    ${PLAYER_CHARACTERS_COMPANION_FRAGMENT}
`;