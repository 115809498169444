import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Table, Input, Modal, Header} from "semantic-ui-react";
import TypeDropdown from "./TypeDropdown";
import './ModifierEditModal.scss';

const Row = props => {
    const {data, index, addRow, deleteRow, updateRow, isBaseEditable, isEditable} = props;
    const {value, type, source, sort} = data;
    const disableInput = (type === 'Base' && !isBaseEditable) || !isEditable;

    const onChange = e => {
        const name = e.target.name;
        let newValue = e.target.value;
        if (name === 'value') {
            newValue = parseInt(newValue);
            if (isNaN(newValue)) {
                newValue = e.target.value;
            }
        }
        updateRow({type, value, source, sort, [name]: newValue}, index);
    }

    const handleKeyDownAddRow = e => {
        if (e.key === 'Tab') {
            addRow();
        }
    }

    const getSign = () => {
        if (type === 'Base') {
            return '';
        }

        return value < 0 ? '-' : '+';
    }

    return isEditable ? (
            <Table.Row>
                <Table.Cell>
                    <Input fluid disabled={disableInput} name="value" value={value} onChange={onChange}/>
                </Table.Cell>
                <Table.Cell>
                    <div className='Row-dropdown'>
                        {type === "Base"
                            ?
                                <div className='Row-value'>
                                    Base
                                </div>
                            :
                                <TypeDropdown
                                    fluid
                                    name="type"
                                    disabled={type === "Base" || !isEditable}
                                    value={type}
                                    onChange={onChange}
                                />
                        }
                    </div>
                </Table.Cell>
                <Table.Cell>
                    {type === "Base"
                        ?
                        <div className='Row-value'>
                            Base
                        </div>
                        :
                        <Input fluid disabled={disableInput} name="source" value={source} onChange={onChange}/>
                    }
                </Table.Cell>
                <Table.Cell>
                    <div className='Row-removeButton' onKeyDown={handleKeyDownAddRow}>
                        {type !== "Base" && isEditable && <Button icon="minus" onClick={deleteRow(index)}/>}
                    </div>
                </Table.Cell>
            </Table.Row>
        ) : (
            <Table.Row>
                <Table.Cell>
                    <div className='Row-value'>
                        {`${getSign()}${value}`}
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div className='Row-value'>
                        {type}
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div className='Row-value'>
                        {source}
                    </div>
                </Table.Cell>
                <Table.Cell>
                </Table.Cell>
            </Table.Row>
        );
}

const ModifierEditModal = props => {
    const {modalOpen, defaultData, closeModal, saveData, isBaseEditable, isEditable, title} = props;
    const [modifiers, setModifiers] = useState(defaultData);
    const [isModified, setIsModified] = useState(false);

    const base = modifiers.find(modifier => modifier.type === "Base");
    const others = modifiers.filter(modifier => modifier.type !== "Base");

    const getTotal = () => {
        const highestModifiers = {};

        modifiers.forEach(modifier => {
            if (!modifier.type) return;
            const value = parseInt(modifier.value);
            if (isNaN(value)) return;
            const type = modifier.type === 'Untyped' ? `Untyped (${modifier.source})` : modifier.type;
            if (typeof highestModifiers[type] === 'undefined' || highestModifiers[type].value < value) {
                highestModifiers[type] = modifier;
            }
        });

        return Object.values(highestModifiers).reduce((acc, mod) =>  acc += mod.value, 0);
    }

    const updateRow = (value, index) => {
        if (!isEditable) return;
        setIsModified(true);
        const newModifiers = [
            ...modifiers
        ];

        newModifiers[index] = {
            value: value.value,
            source: value.source,
            sort: value.sort,
            type: value.type,
        }

        setModifiers(newModifiers);
    }

    const addRow = () => {
        if (!isEditable) return;

        setModifiers([...modifiers, {value: 0, source: "", sort: modifiers.length, type: null}]);
    }

    const deleteRow = deleteIndex => () => {
        if (!isEditable) return;
        const filteredModifiers = modifiers.filter((type, index) => index !== deleteIndex);
        setModifiers(filteredModifiers);
        setIsModified(true);
    }

    const save = () => {
        if (!isEditable) return;
        let filteredModifiers = modifiers.filter(mod => mod !== null);
        const baseModifier = filteredModifiers.find(mod => mod.type === "Base");
        if (!baseModifier) {
            filteredModifiers.unshift({value: 10, source: "Base", sort: 0, type: "Base"});
            setModifiers(filteredModifiers);
        }

        const baseModifiers = filteredModifiers.filter(mod => mod.type === "Base");
        if (baseModifiers.length > 1) {
            filteredModifiers = filteredModifiers.filter(mod => mod.type !== "Base");
            filteredModifiers.unshift({value: 10, source: "Base", sort: 0, type: "Base"});
            setModifiers(filteredModifiers);
        }

        saveData(filteredModifiers);
        setIsModified(false);
        closeModal();
    }

    return (
        <Modal size='small' open={modalOpen} onClose={closeModal}>
            {title !== null && (
                <Modal.Header>
                    {title}
                </Modal.Header>
            )}
            <Modal.Content>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <b>Modifier</b>
                            </Table.HeaderCell>
                            <Table.HeaderCell width={4}>
                                <b>Type</b>
                            </Table.HeaderCell>
                            <Table.HeaderCell width={6}>
                                <b>Source</b>
                            </Table.HeaderCell>
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>
                    {modifiers.map((value, index) => (
                        <Row
                            isEditable={isEditable}
                            data={value}
                            index={index}
                            addRow={addRow}
                            updateRow={updateRow}
                            deleteRow={deleteRow}
                            isBaseEditable={isBaseEditable}
                        />
                    ))}
                    {isEditable &&
                        <Table.Row>
                            <Table.Cell width={2}>
                                <Button icon="plus" onClick={addRow}/>
                            </Table.Cell>
                        </Table.Row>
                    }
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <div style={{float: 'left', marginTop: '6px'}}>
                    <Header>
                        Total: {parseInt(getTotal())}
                    </Header>
                </div>
                <Button negative onClick={closeModal}>
                    {isEditable ? 'Cancel' : 'Close'}
                </Button>
                {isEditable &&
                    <Button positive disabled={!isModified} onClick={save}>
                        Set
                    </Button>
                }
            </Modal.Actions>
        </Modal>
    );
};

ModifierEditModal.propTypes = {
    modalOpen: PropTypes.bool.isRequired,
    defaultData: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveData: PropTypes.func.isRequired,
    isBaseEditable: PropTypes.bool,
    isEditable: PropTypes.bool,
    title: PropTypes.string,
};

ModifierEditModal.propTypes = {
    isBaseEditable: false,
    isEditable: false,
    title: null,
};

export default ModifierEditModal;