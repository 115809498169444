import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Header,
    Item,
    Placeholder,
    PlaceholderLine,
} from 'semantic-ui-react';
import CharacterSelectModal from "./CharacterSelectModal";
import {RemovableCharacterList} from "../Character/CharacterCardPage";
import {useMutation} from "@apollo/client";
import {SAVE_CHARACTER, SESSION_SIGNUP, SESSION_WITHDRAW} from "../../../graphql/Mutations";

const SessionSignupSection = params => {
    const {refetchSession, session, user} = params;

    const handleClick = sessionID => () => {
        const test = sessionID;
    }

    const loading = !session || !user;
    const eligibleSignupCharacters =
        user && user.Characters
            ?
                user.Characters.filter(character => character.Approver !== null && character.Type === 1) || []
            :
                [];

    const gmUserID = session && session.GameMaster && session.GameMaster.UserID
        ? session.GameMaster.UserID
        : null;

    const userID = user && user.UserID
        ? user.UserID
        : null;

    const userData = user || {Characters: []};

    const canSignup = () => {
        if (gmUserID === userID || userID === null || gmUserID === null) return false;
        if (session.Status !== 3 && session.Status !== 4) return false;
        if (loading || !userData.Characters) return false;
        if (!userData.Characters.length || !eligibleSignupCharacters.length) return false;

        // const signupCharacter = user.Characters.find(character =>
        //     session.SignupCharacters.find(sessionCharacter => sessionCharacter.CharacterID === character.CharacterID)
        // );
        //
        // if (signupCharacter) return false;
        if (!userData.Characters) {
            return false;
        }
        return !!userData.Characters.find(character => character.Approver !== null);
    }

    const [signupModalOpen, setSignupModalOpen] = useState(false);
    const handleOpenSignupModal = () => setSignupModalOpen(true);
    const handleCloseSignupModal = () => setSignupModalOpen(false);

    const [saveSession] = useMutation(SESSION_SIGNUP, {
        onCompleted() {
            setSignupModalOpen(false);
        },
        onError() {
            setSignupModalOpen(false);
        }
    });

    const handleSignupCharacterConfirmation = characterIDs => () => {
        saveSession({
            variables: {
                sessionID: session.SessionID,
                characterIDs,
            }
        }).then(() =>setSignupModalOpen(false));
    };

    const [withdrawCharacter] = useMutation(SESSION_WITHDRAW, {
        onCompleted () {},
        onError (error) {
            console.log(error.message);
        }
    });

    const handleWithdrawCharacter = character => {
        withdrawCharacter({
            variables: {
                sessionID: session.SessionID,
                characterID: character.CharacterID
            }
        })
    }

    return (
        <>
            <Item style={{padding: '8px', borderRadius: '4px'}}>
                <Item.Content>
                    <div style={{display: 'block'}}><Header>Session Signups {canSignup() && <Button basic onClick={handleOpenSignupModal} style={{float: 'right'}}>Sign Up</Button>}</Header></div>
                    <Item.Description>
                        {session.SignupCharacters
                            ?
                                <RemovableCharacterList
                                    hideStatus
                                    showDelete
                                    onClickDelete={handleWithdrawCharacter}
                                    characters={session.SignupCharacters}
                                    loading={loading}
                                    refetch={refetchSession}
                                    user={user}
                                />
                            :
                                <>No Signups Yet!</>
                        }
                        <br />
                    </Item.Description>
                </Item.Content>
            </Item>
            <CharacterSelectModal
                multiselect
                isOpen={signupModalOpen}
                characters={eligibleSignupCharacters}
                closeModal={handleCloseSignupModal}
                onConfirmSelection={handleSignupCharacterConfirmation}
            />
        </>
    );
};

SessionSignupSection.propTypes = {
    session: PropTypes.object.isRequired,
    refetchSession: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
}

export default SessionSignupSection;