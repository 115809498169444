import React, {useState} from 'react';
import {useMutation} from "@apollo/client";
import {APPROVE_CHARACTER, CANCEL_INSPECTION, SET_FOR_INSPECTION} from "../../../graphql/Mutations";
import {hasPermission, PERMISSIONS} from "../../../utilities/PermissionUtils";
import {Button} from "semantic-ui-react";

const CharacterPageActionButtons = props => {
    const {character, loading, openModal, refetchCharacter, user} = props;
    const [setReadyForInspection] = useMutation(SET_FOR_INSPECTION, {
        onCompleted() {
            setRunningInspection(false);
        },
        onError() {
            setRunningInspection(false);
        }
    });
    const [runningInspection, setRunningInspection] = useState(false);
    const setForInspection = () => {
        setRunningInspection(true);
        setReadyForInspection({variables: {characterID: character.id}})
    }

    const canApprove = character.Status === 2 && character.UserID !== user.id && hasPermission(user.Permissions, PERMISSIONS.INSPECTOR);
    const [markSheetApproved] = useMutation(APPROVE_CHARACTER, {
        onCompleted() {
            refetchCharacter()
                .finally(() => {
                    setApproving(false);
                });
        },
        onError() {
            setApproving(false);
        }
    });
    const [approving, setApproving] = useState(false);
    const approveSheet = () => {
        setApproving(true);
        markSheetApproved({variables: {characterID: character.id}})
    }

    const [cancellingInspection, setCancellingInspection] = useState(false);
    const [cancelInspect] = useMutation(CANCEL_INSPECTION, {
        onCompleted() {
            refetchCharacter()
                .finally(() => {
                    setCancellingInspection(false);
                });
        },
        onError() {
            setCancellingInspection(false);
        }
    });
    const cancelInspection = () => {
        setCancellingInspection(true);
        cancelInspect({variables: {characterID: character.id}})
    }

    return !loading && (
        <>
            {character.UserID && character.UserID === user.id && (
                <Button
                    basic
                    color='black'
                    onClick={openModal}
                    style={{width: '150px'}}
                >
                    Edit
                </Button>
            )}
            {character.Type !== 3 &&
                <>
                    {character.Status === 1 && character.UserID === user.id && (
                        <>
                            <br/>
                            <Button
                                color='green'
                                loading={runningInspection}
                                onClick={setForInspection}
                                style={{width: '150px', marginTop: '8px'}}
                            >
                                Mark For Inspection
                            </Button>
                        </>
                    )}
                    {character.Status === 2 && (canApprove || character.UserID === user.id) &&
                    <>
                        <br/>
                        <Button
                            color='red'
                            loading={cancellingInspection}
                            onClick={cancelInspection}
                            style={{width: '150px', marginTop: '8px'}}
                        >
                            Cancel Inspection
                        </Button>
                    </>
                    }
                    {character.Status === 2 && canApprove && (
                        <>
                            <br/>
                            <Button
                                color='green'
                                loading={approving}
                                onClick={approveSheet}
                                style={{width: '150px', marginTop: '8px'}}
                            >
                                Approve
                            </Button>
                        </>
                    )}
                </>
            }
        </>
    );
};

export default CharacterPageActionButtons;