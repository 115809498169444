import React from 'react';
import ReactDOM from 'react-dom';

import '@fontsource/roboto';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import './overrides.css';

import './index.css';
import './fonts/stylesheet.css';
import * as serviceWorker from './serviceWorker';

import App from './components/App';
import FirebaseContext from './components/Context/FirebaseContext';
import Firebase from './components/Helpers/Firebase';

import {createTheme, ThemeProvider} from "@mui/material";

String.prototype.capitalize = function() {
    return this.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

const localTheme = parseInt(localStorage.getItem('theme'));

const render = async () => {
    console.log(localTheme);
    console.log('Index');
    ReactDOM.render(
        <ThemeProvider theme={createTheme({
            palette: {
                mode: localTheme === 1 ? 'light' : 'dark',
            },
        })}>
            <FirebaseContext.Provider value={new Firebase()}>
                <App />
            </FirebaseContext.Provider>
        </ThemeProvider>,
        document.getElementById('root'),
    );
};



if (localTheme === 1) {
    import('semantic-ui-css/semantic.min.css').then(render).catch(console.error);
} else {
    import('./semantic.semantic-ui.css').then(render).catch(console.error);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
