import React from 'react';

export const getMythic = (level, experience) => {
    if (level < 3 || isNaN(level)) {
        return -1;
    }
    switch (level) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
            return 0;
        case 8:
        case 9:
        case 10:
            return 1;
        case 11:
        case 12:
        case 13:
            return 2;
        case 14:
        case 15:
            return 3;
        case 16:
        case 17:
            return 4;
        case 18:
        case 19:
            return 5;
        case 20:
            return 6;
        case 21:
            return 7;
        case 22:
            return 8;
        case 23:
            return 9
        case 24:
            return 10;
        default:
            return 10;
    }
}

const NEXT_LEVEL_EXP = {
    3:      9000,
    4:     15000,
    5:     23000,
    6:     35000,
    7:     51000,
    8:     75000,
    9:    105000,
    10:   155000,
    11:   220000,
    12:   315000,
    13:   445000,
    14:   635000,
    15:   890000,
    16:  1300000,
    17:  1800000,
    18:  2550000,
    19:  3600000,
    20:  5175000,
    21:  6975000,
    22:  9000000,
    23: 11250000,
    24: 13725000,
    25: 16425000,
    26: 19350000,
    27: 22500000,
    28: 25875000,
    29: 29475000,
    30: 33525000,
    31: 38025000,
    32: 42975000,
    33: 48375000,
    34: 54225000,
    35: 60525000,
    36: 67275000,
    37: 74475000,
    38: 82125000,
    39: 90225000
};

export const getNextLevelXP = level => {
    return NEXT_LEVEL_EXP[level];
};

const ATTUNEMENT_TO_LEVEL = {
    0: 0,
    1: 20,
    2: 60,
    3: 115,
    4: 180,
    5: 255,
    6: 340,
    7: 99999
};

const ATTUNEMENT_SCORE_BENEFIT = {
    0: 'None',
    1: '+2 to one Ability Score',
    2: '+2/+2 among two Ability Scores',
    3: '+4/+2 among two Ability Scores',
    4: '+4/+2/+2 among three Ability Scores',
    5: '+4/+4/+2 among three Ability Scores',
    6: '+6/+4/+2 among three Ability Scores'
};

export const getAttunementLevel = attunement => {
    return Object.keys(ATTUNEMENT_TO_LEVEL).find(
        key => ATTUNEMENT_TO_LEVEL[key] > attunement
    ) - 1;
};

export const getAttunementToNextLevel = attunement => {
    const level = getAttunementLevel(attunement);
    if (level === 6) {
        return 0;
    }
    return ATTUNEMENT_TO_LEVEL[level + 1] - attunement;
}

export const getAttunementBenefit = level => {
    return ATTUNEMENT_SCORE_BENEFIT[level];
};