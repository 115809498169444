import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, Header, Menu} from "semantic-ui-react";
import {isMobile} from "react-device-detect";
import AbilityScoreBox from "../Components/AbilityScoreBox";
import {SheetContext} from "../Components/SheetContext";
import ParagraphTextArea from "../Components/ParagraphTextArea";
import EditableTitle from "../Components/EditableTitle";
import RaceSection from "./RaceSection";
import "./RaceTab.scss";

const DEFAULT_RACE_DATA = {
    Name: 'Unknown Race',
    Source: '',
    Sections: [{
        title: 'Standard Racial Traits',
        deletable: false,
        traits: [
            {
                name: 'Ability Score Modifiers',
                deletable: false,
                description: ''
            },
            {
                name: 'Size',
                deletable: false,
                description: ''
            },
            {
                name: 'Type',
                deletable: false,
                description: ''
            },
            {
                name: 'Base Speed',
                deletable: false,
                description: ''
            },
            {
                name: 'Languages',
                deletable: false,
                description: ''
            }
        ]
    }]
};

const RaceTab = props => {
    const {sheetData, mode, updateSheetData} = useContext(SheetContext);
    const isEditable = mode === "edit";
    let {Race} = sheetData;
    if (!Race) {
        updateSheetData([], 'Race', DEFAULT_RACE_DATA);
        Race = DEFAULT_RACE_DATA;
    }
    const [modified, setModified] = useState(false);
    const handleChangeName = e => {
        updateSheetData(['Race'], 'Name', e.target.value);
    }

    const handleClickDelete = deleteIndex => () => {
        const sections = sheetData.Race.Sections;
        const newSections = sections.filter((section, sectionIndex) => sectionIndex !== deleteIndex);
        updateSheetData(['Race', 'Sections'], null, newSections);
    }

    const handleClickAddSection = () => {
        const sections = Race.Sections;
        sections.push({title: '', deletable: true, traits: [{name: '', replacement: '', deletable: true, description: ''}]});
        updateSheetData(['Race'], 'Sections', sections);
    }

    const handleResetRace = () => {
        updateSheetData([], 'Race', DEFAULT_RACE_DATA);
    }

    return (
        <div className='silver-border'>
            <Grid stretched divided='vertically' className='race'>
                <Grid.Column width={16}>
                    <Button style={{marginLeft: 'auto', width: '180px'}} onClick={handleResetRace}>Reset Race Data</Button>
                </Grid.Column>
                <EditableTitle titleEditable isEditable={isEditable} headingSize='h1' onChange={handleChangeName} value={Race.Name} name='Name' onClickDelete={() => {}} />
                {Race.Sections.map((section, index) => (
                    <RaceSection key={`${section.sectionTitle}_${index}`} isEditable={isEditable} data={section} index={index} onClickDelete={handleClickDelete} />
                ))}
                {isEditable &&
                    <Grid.Column width={16}>
                        <Button style={{marginLeft: 'auto', width: '120px'}} positive onClick={handleClickAddSection}>Add Section</Button>
                    </Grid.Column>
                }
            </Grid>
        </div>
    );
};

RaceTab.propTypes = {
    character: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

export default RaceTab;
