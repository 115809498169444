import React from 'react';
import PropTypes from 'prop-types';
import './AbilityScoreBox.scss';
import {Button, Grid, Header, Input} from "semantic-ui-react";

const EditableTitle = props => {
    const {name, titleEditable, value, className, isEditable, deletable, headingSize, paddingWidth, onChange, onClickDelete} = props;
    const mainColumnWidth = 16 - paddingWidth;
    return isEditable ? (
        <>
            {paddingWidth > 0 &&
                <Grid.Column width={paddingWidth} className={className} />
            }
            <Grid.Column width={mainColumnWidth} className={className}>
                <div style={{overflow: 'hidden'}}>
                    {deletable &&
                        <div style={{float: 'right', marginLeft: '24px'}}>
                            <Button icon="minus" onClick={onClickDelete}/>
                        </div>
                    }
                    <label className='sheet' />
                    <Input fluid disabled={!titleEditable} name={name} value={value} onChange={onChange}/>
                </div>
            </Grid.Column>
        </>
    ) : (
        <Grid.Column width={16} className={className}>
            <Header as={headingSize}>
                {value}
            </Header>
        </Grid.Column>
    );
};

EditableTitle.propTypes = {
    className: PropTypes.string,
    deletable: PropTypes.bool,
    headingSize: PropTypes.string,
    paddingWidth: PropTypes.number,
    isEditable: PropTypes.bool,
    name: PropTypes.string.isRequired,
    titleEditable: PropTypes.bool,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

EditableTitle.defaultProps = {
    className: '',
    deletable: false,
    path: null,
    headingSize: 'h3',
    paddingWidth: 0,
    isEditable: false,
    titleEditable: false,
};

export default EditableTitle;