import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import './AbilityScoreBox.scss';
import {SheetContext} from "./SheetContext";
import ModifierEditModal from "./ModifierEditModal";

/*
    {
        sort: 5,
        prefix: "CHA",
        enabled: true,
        modifiers: [
            {source: "Base", value: 10, type: "Base"}
        ]
    }
 */

const AbilityScoreBox = props => {
    const {path} = props;
    const {sheetData, updateSheetData, isEditable, mode} = useContext(SheetContext);
    const [modalOpen, setModalOpen] = useState(false);
    const data = path.reduce((acc, loc) => {
        return acc[loc];
    }, sheetData);

    const onClick = () => {
        setModalOpen(true);
    };

    const saveData = updateData => {
        updateData.forEach(item => {
            if (item === null) {
                console.log("WE GOT A NULL ONE!");
            }
        })
        const base = updateData.find(mod => mod.type === "Base");
        updateSheetData(path, 'enabled', (base.value > 0));
        updateSheetData([...path, 'modifiers'], null, updateData);
    }

    const getScore = () => {
        const highestModifiers = {};

        data.modifiers.forEach(modifier => {
            if (!modifier || !modifier.type) return;
            const value = parseInt(modifier.value);
            if (isNaN(value)) return;
            const type = modifier.type === 'Untyped' ? `Untyped (${modifier.source})` : modifier.type;
            if (typeof highestModifiers[type] === 'undefined' || highestModifiers[type].value < value) {
                highestModifiers[type] = modifier;
            }
        });

        return Object.values(highestModifiers).reduce((acc, mod) =>  acc += mod.value, 0);
    }

    const score = data.modifiers.reduce((acc, mod) => {
        return acc + (mod ? mod.value : 0);
    }, 0);

    const modifier = Math.floor((getScore() - 10) / 2);
    const prefix = modifier < 0 ? "" : "+";
    return (
        <>
            <div className="AbilityScoreBox-container" style={{cursor: 'pointer'}} onClick={onClick}>
                <div className="AbilityScoreBox-box">
                    <div className="AbilityScoreBox-name">
                        {data.prefix}
                    </div>
                    <div className="AbilityScoreBox-score">
                        {data.enabled ? getScore() : "—"}
                    </div>
                </div>
                <div className="AbilityScoreBox-modifier">
                    {data.enabled ? `${prefix}${modifier}` : "—"}
                </div>
            </div>
            <ModifierEditModal
                isBaseEditable
                isEditable={isEditable && mode === 'edit'}
                title={path[path.length - 1]}
                modalOpen={modalOpen}
                defaultData={data.modifiers}
                closeModal={() => setModalOpen(false)}
                saveData={saveData}
            />
        </>
    );
};

AbilityScoreBox.propTypes = {
    name: PropTypes.string,
    score: PropTypes.number,
    onClick: PropTypes.func,
};

AbilityScoreBox.defaultProps = {
    name: "TBD",
    score: 66,
    onClick() {},
};

export default AbilityScoreBox;