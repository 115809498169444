import {IconButton, Menu, MenuItem} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {Edit, ThreeSixty} from "@mui/icons-material";
import React, {useState} from "react";
import {hasAtLeastPermission, hasPermission, PERMISSIONS} from "../../../utilities/PermissionUtils";
import {SAVE_MAP, UPDATE_INFOCARD_VISIBILITY} from "../../../graphql/Mutations";
import {useMutation} from "@apollo/client";
import {useHistory} from "react-router-dom";
import {BESTIARY_CREATE, BESTIARY_CREATE_NULL} from "../../../constants/routes";

const getVisibilityString = visibility => {
    switch (visibility) {
        case 0:
            return 'Private';
        case 10:
            return 'GM';
        case 100:
            return 'GM + Archivist';
        case 1000:
            return 'Staff';
        case 10000:
            return 'Public';
    }
}

const BestiaryCardToolbar = props => {
    const history = useHistory();
    const {card, user, onFlip} = props;
    const [visibility, setVisibility] = useState(card.Visibility);
    const [anchorElement, setAnchorElement] = useState(null);
    const open = Boolean(anchorElement);
    const [updateVisibility] = useMutation(UPDATE_INFOCARD_VISIBILITY, {
        onCompleted() {},
        onError (error) {
            console.log(error.message);
        }
    });

    const handleClickVisibility =  e => {
        setAnchorElement(e.currentTarget);
    }

    const handleCloseVisibilityMenu = () => {
        setAnchorElement(null);
    }

    const checkShowVisibility = () => {
        return !!(user.UserID === card.CreatorUser.id
            || user.UserID === card.UpdaterUser.id
            || hasPermission(user.Permissions, PERMISSIONS.ADMIN));
    }

    const handleClickSetVisibility = visibility => () => {
        updateVisibility({
            variables: {
                id: card.id,
                visibility
            }
        }).then(() => {
            handleCloseVisibilityMenu();
            setVisibility(visibility);
        });
    }

    const getVisibilityMenuOptions = () => {
        if (!user.UserID) return;
        if (!checkShowVisibility()) return;

        const isGM = hasAtLeastPermission(user.Permissions, PERMISSIONS.GM);
        const isArchivist = hasPermission(user.Permissions, PERMISSIONS.ARCHIVIST);
        const isInspector = hasPermission(user.Permissions, PERMISSIONS.INSPECTOR);

        const options = [];
        options.push((
            <MenuItem key='private' onClick={handleClickSetVisibility(0)}>
                Private
            </MenuItem>
        ));

        if (isGM) {
            options.push((
                <MenuItem key='gm' onClick={handleClickSetVisibility(10)}>
                    GM
                </MenuItem>
            ));
        }

        if (isGM || isArchivist) {
            options.push((
                <MenuItem key='archivist' onClick={handleClickSetVisibility(100)}>
                    GM + Archivist
                </MenuItem>
            ));
        }

        if (isGM || isArchivist || isInspector) {
            options.push((
                <MenuItem key='staff' onClick={handleClickSetVisibility(1000)}>
                    Staff
                </MenuItem>
            ));
        }

        options.push((
            <MenuItem key='general' onClick={handleClickSetVisibility(10000)}>
                Public
            </MenuItem>
        ));

        return options;
    }

    const handleClickEdit = () => {
        history.push(BESTIARY_CREATE.replace(':cardID', card.id));
    }

    const handleClickFlip = () => {
        onFlip();
    }

    return (
        <div style={{marginTop: '-4px'}}>
            <div style={{position: 'absolute'}}>
                {card.CanEdit &&
                    <IconButton
                        id={`card-${card.id}`}
                        style={{color: 'black'}}
                        onClick={handleClickVisibility}
                        aria-controls={open ? `visibility-menu-${card.id}` : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <VisibilityIcon/>
                    </IconButton>
                }
                <span style={{position: 'absolute', top: '8px', fontSize: 'medium', width: '110px'}}>
                    {getVisibilityString(visibility)}
                </span>
                <Menu
                    id={`visibility-menu-${card.id}`}
                    anchorEl={anchorElement}
                    open={open}
                    onClose={handleCloseVisibilityMenu}
                    MenuListProps={{
                        'aria-labelledby': `card-${card.id}`,
                    }}
                >
                    {getVisibilityMenuOptions()}
                </Menu>
            </div>
            <div style={{position: 'absolute', right: 'calc(50% - 16px)'}}>
                <IconButton aria-label="flip" style={{color: 'black'}} onClick={handleClickFlip}>
                    <ThreeSixty/>
                </IconButton>
                <span style={{position: 'absolute', top: '8px', fontSize: 'medium', width: '110px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                    {card.UpdaterUser.Name}
                </span>
            </div>
            {card.CanEdit &&
                <div style={{position: 'absolute', right: '0px'}}>
                    <IconButton aria-label="edit" style={{color: 'black'}} onClick={handleClickEdit}>
                        <Edit/>
                    </IconButton>
                </div>
            }
        </div>
    );
}

export default BestiaryCardToolbar;