import React, {useState} from 'react';
import PropTypes from "prop-types";
import {AppBar, Avatar, Box, Button, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer, Toolbar} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import {AccountCircle} from "@mui/icons-material";
import AccountMenu from "./AccountMenu";
import * as ROUTES from "../../constants/routes";
import {useHistory} from "react-router-dom";
import SiteMenu from "./SiteMenu";
import {useQuery} from "@apollo/client";
import {GET_CURRENT_USER} from "../../graphql/Queries";
import {useAuthState} from "react-firebase-hooks/auth";

const NavBar = () => {
    const {data, loading} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'cache-and-network'});
    const user = data && data.CurrentUser ? data.CurrentUser : {};

    const [menuOpen, setMenuOpen] = useState(false);
    const [accountOpen, setAccountOpen] = useState(false);
    const history = useHistory();

    const handleClickMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickAccount = () => {
        setAccountOpen(!accountOpen);
    };

    const handleClickLogin = () => {
        history.push(ROUTES.SIGN_IN);
    };

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="fixed">
                <Toolbar>
                    {user.Name &&
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{mr: 2}}
                            onClick={handleClickMenu}
                        >
                            <MenuIcon/>
                        </IconButton>
                    }
                    <Avatar
                        alt="Primordia Living World"
                        src="/crystals-small.png"
                    />
                    <Box sx={{ flexGrow: 1 }} />
                    {!loading && user.Name
                        ?
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="Current user account"
                                aria-controls='account-menu'
                                aria-haspopup="true"
                                onClick={handleClickAccount}
                                color="inherit"
                            >
                                <AccountCircle/>
                            </IconButton>
                        :
                            !loading && <Button color="inherit" onClick={handleClickLogin}>Login</Button>
                    }
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                anchor='left'
                open={menuOpen}
                onClose={handleClickMenu}
                onOpen={() => {}}
            >
                <Box
                    sx={{
                        width: 240,
                        flexShrink: 0
                    }}
                >
                    <SiteMenu onClose={handleClickMenu} />
                </Box>
            </SwipeableDrawer>
            <SwipeableDrawer
                anchor='right'
                open={accountOpen}
                onClose={handleClickAccount}
                onOpen={() => {}}
            >
                <Box
                    sx={{
                        width: 240,
                        flexShrink: 0
                    }}
                >
                    <AccountMenu onClose={handleClickAccount} />
                </Box>
            </SwipeableDrawer>
        </Box>
    );
};

NavBar.propTypes = {
    loading: PropTypes.bool
};

NavBar.defaultProps = {
    loading: false
};

export default NavBar;