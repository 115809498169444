import InfoCardContainer, {INFOCARD_TYPES} from "../InfoCard/InfoCardContainer";
import BestiaryCardEditor from "./BestiaryCardEditor";
import {BESTIARY} from "../../../constants/routes";
import BestiaryCard from "./BestiaryCard";
import {useQuery} from "@apollo/client";
import {GET_BESTIARY_CARD} from "../../../graphql/Queries";
import {useHistory} from "react-router-dom";

const BestiaryEditContainer = ({cardID}) => {
    const history = useHistory();
    const {data, loading, refetch} = useQuery(GET_BESTIARY_CARD, {
        variables: {
            cardID
        },
        fetchPolicy: 'cache-and-network'
    });

    const handleSuccess = () => history.push(BESTIARY);

    const card = (data && data.Bestiary && data.Bestiary.Card) ? data.Bestiary.Card : {};
    const metadata = card.Metadata ? JSON.parse(card.Metadata) : {};
    return card.id ? (
        <InfoCardContainer
            id={card.id}
            allowImageUpload
            defaultValues={{
                Name: metadata.Name,
                Traits: metadata.Traits,
                Level: metadata.Level,
                Content: card.Content
            }}
            cardInputs={BestiaryCardEditor}
            cardPreview={BestiaryCard}
            type={INFOCARD_TYPES.CREATURE}
            onSuccess={handleSuccess}
        />
    ) : "Loading";
};

export default BestiaryEditContainer;