import React from 'react';
import PropTypes from 'prop-types';

import {Modal} from 'semantic-ui-react';
import CharacterSelect from "../Character/CharacterSelect";

const FORMATS = [
    {key: 1, text: 'Roll20', value: 1},
    {key: 2, text: 'Foundry', value: 2},
    {key: 3, text: 'Play-By-Post', value: 3}
];

const CharacterSelectModal = props => {
    const {characters, multiselect, closeModal, isOpen, onConfirmSelection} = props;

    const onCancel = () => closeModal();

    return (
        <Modal open={isOpen} onClose={closeModal}>
            <Modal.Header>Select Character{multiselect && 's'}</Modal.Header>
            <Modal.Content>
                <CharacterSelect
                    showButtons
                    multiselect={multiselect}
                    characters={characters}
                    onClickCancel={closeModal}
                    onConfirmSelection={onConfirmSelection}
                />
            </Modal.Content>
        </Modal>
    );
};

CharacterSelectModal.propTypes = {
    multiselect: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    characters: PropTypes.array.isRequired,
    closeModal: PropTypes.func.isRequired,
    onConfirmSelection: PropTypes.func.isRequired,
};

CharacterSelectModal.defaultProps = {
    multiselect: false,
};

export default CharacterSelectModal;
