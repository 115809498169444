import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import './AbilityScoreBox.scss';
import {Button, Header} from "semantic-ui-react";
import {SheetContext} from "./SheetContext";
import {getPathNode} from "./SheetData";
import TextAreaModal from "./TextAreaModal";

const ParagraphTextArea = props => {
    const {path} = props;
    const {sheetData, updateSheetData, isEditable, mode} = useContext(SheetContext);
    const data = getPathNode(path, sheetData);
    const [modalOpen, setModalOpen] = useState(false);
    const saveData = text => {
        updateSheetData(path, 'text', text);
        setModalOpen(false);
    }

    return (
        <>
            <div className='silver-border'>
                <Header as='h3'>
                    {data.title}
                    <span style={{float: 'right'}}>
                        {isEditable && mode === 'edit' &&
                            <Button basic size="mini" icon="pencil" onClick={() => setModalOpen(true)}/>
                        }
                    <span/>
                </span>
                </Header>
                {data.text.split("\n").map((item, idx) => (
                    <span key={idx}>
                        {item}
                        <br />
                    </span>
                ))}
            </div>
            <TextAreaModal defaultValue={data.text} onSave={saveData} title={data.title} isOpen={modalOpen} closeModal={() => setModalOpen(false)} />
        </>
    );
};

ParagraphTextArea.propTypes = {
    path: PropTypes.array,
};

ParagraphTextArea.defaultProps = {
    path: null,
};

export default ParagraphTextArea;