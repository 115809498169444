import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Button,
    Dialog,
    IconButton,
    Toolbar,
    Typography,
    Slide,
    Tooltip,
    tooltipClasses, Drawer
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {ITEM_TYPE, SLOTS} from "../../constants/item";
import {styled} from '@mui/material/styles';
import {brown} from '@mui/material/colors';
import InventoryEditContainer from "../Pages/Inventory/InventoryEditContainer";
import InventoryCard from "../Pages/Inventory/InventoryCard";
import {useMutation} from "@apollo/client";
import {TOGGLE_ITEM_EQUIP} from "../../graphql/Mutations";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BagButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(brown[500]),
    backgroundColor: brown[500],
    '&:hover': {
        backgroundColor: brown[700],
    },
}));

const getEquipmentLocation = (slot) => {
    const slotNumber = parseInt(slot);
    switch (slotNumber) {
        case 10: return [0, 0];
        case 11: return [0, 230];
        case 12: return [74, 0];
        case 7: return [74, 230];
        case 6: return [148, 0];
        case 16: return [148, 230];
        case 1: return [222, 0];
        case 5: return [222, 230];
        case 4: return [296, 0];
        case 17: return [296, 230];
        case 13: return [370, 0];
        case 14: return [370, 230];
        case 9: return [444, 0];
        case 8: return [444, 230];
        case 2: return [518, 40];
        case 3: return [518, 114];
        case 15: return [518, 188];
        default: return null;
    }
};

const InventoryTab = ({character, user, refetchCharacter}) => {
    const [open, setOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [toggleEquip] = useMutation(TOGGLE_ITEM_EQUIP, {
        onCompleted(){},
        onError(){},
    });

    const inventory = useMemo(() => {
        const inventoryData = character && character.Inventory ? character.Inventory : [];
        return inventoryData.reduce((acc, item) => {
            if (item.Location > 0 && item.Location < 18) return acc;
            const metadata = JSON.parse(item.InfoCard.Metadata);
            const newItem = {
                ...metadata,
                ...item,
                CanEdt: item.InfoCard.CanEdit,
            };
            acc.push(newItem);
            return acc;
        }, []);
    }, character);

    const equippedItems = useMemo(() => {
        const inventoryData = character && character.Inventory ? character.Inventory : [];
        return inventoryData.reduce((acc, item) => {
            if (item.Location < 1 && item.Location > 17) return acc;
            const metadata = JSON.parse(item.InfoCard.Metadata);
            const newItem = {
                ...metadata,
                ...item,
                CanEdt: item.InfoCard.CanEdit,
            };
            acc[item.Location] = newItem;
            return acc;
        }, {});
    }, character);

    /**const inventorySlots = inventory.reduce((acc, item) => {
        if (!acc[item.Slot]) {
            acc[item.Slot] = [];
        }
        acc[item.Slot].push(item);
    }, []);*/

    const getSlot = slot => {
        let path = '/items/default/%.png';
        if (slot.includes('Ring')) {
            return path.replace('%', 'ring');
        } else if (slot.includes('Weapon')) {
            return path.replace('%', 'weapon');
        }

        return path.replace('%', slot.toLowerCase());
    }

    const fakeArray = inventory.length < 16
        ? Array.from(new Array(16 - inventory.length), (x, i) => i + 1)
        : Array.from(new Array(4 - (inventory.length % 4)), (x, i) => i + 1);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSuccess = () => {
        refetchCharacter();
        setOpen(false);
    };

    const handleCloseDrawer = () => {
        setSelectedItem(null);
        setDrawerOpen(false);
    };

    const handleClickItem = item => () => {
        setSelectedItem(item);
        setDrawerOpen(true);
    };

    const handleEquip = async () => {
        const {id: ItemID, Slot, Location} = selectedItem;
        const ToggleSlot = Slot < 1 || Slot > 17 ? 0 : Slot;
        const result = await toggleEquip({
            variables: {
                ItemID,
                Slot: Location === 0 ? ToggleSlot : 0
            }
        });
        if (result.Item && result.Item.ToggleEquip) {
            setDrawerOpen(false);
            refetchCharacter();
        }
    }

    const getEquipped = item => item.Location > 0 && item.Location < 18;

    return (
        <>
            <Box sx={{paddingTop: '12px', flexGrow: 1, height: '1000px'}}>
                <div className='inventory-border' style={{
                    marginRight: '12px',
                    maxWidth: '330px',
                    margin: '12px auto 0 auto',
                    float: 'left',
                    height: '684px',
                    position: 'relative'
                }}>
                    <img width={298} height={654} src="/equipment-shadow.png"/>
                    {Object.keys(SLOTS).map(index => {
                        const result = getEquipmentLocation(index);
                        return result && (
                            <div style={{position: 'absolute', top: `${result[0]}px`, left: `${result[1]}px`}}>
                                {equippedItems[index]
                                    ?
                                        <ItemSlot item={equippedItems[index]} onClick={handleClickItem(equippedItems[index])}/>
                                    :
                                        <ItemSlot item={{Slot: index, Image: getSlot(SLOTS[index])}} />
                                }
                            </div>
                        );
                    })}
                </div>
                <div className="inventory-border" style={{
                    float: 'left',
                    maxWidth: '326px',
                    margin: '12px auto 0 auto',
                    height: `${Math.ceil((fakeArray.length + inventory.length) / 4) * 72 + 74}px`
                }}>
                    <div style={{width: '100%', height: '38px'}}>
                        <div style={{float: 'right'}}>
                            <BagButton onClick={handleClickOpen} variant='contained'>{character.ItemApproval === 1 ? "Create Item" : "Buy Item"}</BagButton>
                        </div>
                    </div>
                    {inventory.map((item) => (
                        <ItemSlot item={item} onClick={handleClickItem(item)}/>
                    ))}
                    {fakeArray.map((index) => (
                        <ItemSlot />
                    ))}
                </div>
            </Box>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Item Editor
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{padding: '8px'}}>
                    <InventoryEditContainer creatorID={character.id} onClose={handleClose} onSuccess={handleSuccess} />
                </div>
            </Dialog>
            <Drawer open={drawerOpen} onClose={handleCloseDrawer} anchor='right'>
                {selectedItem &&
                    <>
                        <div style={{margin: '4px 4px 4px auto'}}>
                            <Button variant='outlined' onClick={handleEquip}>{getEquipped(selectedItem) ? 'Unequip' : 'Equip'}</Button>
                        </div>
                        <div style={{width: '400px', maxWidth: '100%'}}>
                            <InventoryCard values={{...selectedItem.InfoCard, ...selectedItem}} />
                        </div>
                    </>
                }
            </Drawer>
        </>
    );
};

const getImage = (slot, image) => {
    let path = `/items/%s/${image}`;
    if (SLOTS[slot].includes('Ring')) {
        path = path.replace('%s', 'ring');
    } else if (SLOTS[slot].includes('Weapon')) {
        path = path.replace('%s', 'weapons');
    } else if (SLOTS[slot].includes('None')) {
        path = path.replace('%s', 'slotless');
    } else {
        path = path.replace('%s', SLOTS[slot].toLowerCase());
    }

    return path;
}

const ItemSlot = ({item, onClick}) => {
    const {Name, Slot, ItemType, Image, MarketCost, id} = item;
    const icon = Slot && Image && !Image.includes('default') ? getImage(Slot, Image) : Image;
    const itemType = typeof ItemType !== 'undefined' ? ITEM_TYPE[ItemType] : null;

    const getAura = () => {
        const test = item;
        if (!id) return 'darkgrey 0px 0px 20px inset';
        if (ITEM_TYPE[ItemType] === 'Artifact') return 'rgb(255 181 56) 0px 0px 25px inset';
        if (MarketCost === 0) return 'black 0px 0px 20px inset'
        if (MarketCost < 1000) return 'white 0px 0px 20px inset'
        if (MarketCost < 10000) return 'lightgreen 0px 0px 20px inset'
        if (MarketCost < 100000) return '#3298f7 0px 0px 40px inset'
        return '#8e00b7c9 0px 0px 40px inset';
    }

    return (
        <BootstrapTooltip arrow title={Name || itemType || SLOTS[Slot]}>
            <div style={{height: '72px', width: '72px', float: 'left'}}>
                <div style={{position: 'relative'}}>
                    <img height={72} width={72} src="/inventory-slot.png" />
                    {icon &&
                        <div style={{
                            position: 'absolute',
                            top: '4px',
                            left: '4px',
                            height: '64px',
                            width: '64px',
                            boxShadow: getAura(),
                            cursor: 'pointer'
                        }} onClick={onClick}>
                            <img height={64} width={64} src={icon}/>
                        </div>
                    }
                </div>
            </div>
        </BootstrapTooltip>
    );
}

ItemSlot.propTypes = {
    item: PropTypes.object,
};

ItemSlot.defaultProps = {
    item: {},
};

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: '14px'
    },
}));

InventoryTab.propTypes = {
    character: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

export default InventoryTab;
