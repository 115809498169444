import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './AbilityScoreBox.scss';
import {defaultSheetData, updateSheetData} from "./SheetData";
import useInterval from "../../../Helpers/useInterval";
import {useMutation} from "@apollo/client";
import {SAVE_SHEET} from "../../../../graphql/Mutations";

export const SheetContext = React.createContext({sheetData: defaultSheetData, updateSheetData});

const SheetContextProvider = props => {
    const {children, sheetData: {mode: initialMode, sheetData: userSheet}, isEditable, characterID} = props;
    const [sheetData, setSheetData] = useState(userSheet || defaultSheetData);
    const [count, setCount] = useState(0);
    const [lastSaveCount, setLastSaveCount] = useState(0);
    const [mode, setMode] = useState(initialMode);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const [runSaveSheetMutation] = useMutation(SAVE_SHEET, {
        onCompleted() {
            setSavingSheet(false);
            setCount(0);
            setMode('view');
            localStorage.removeItem('CharacterSheet_' + characterID);
        },
        onError() {
            setSavingSheet(false);
        }
    });

    const saveSheet = () => {
        setSavingSheet(true);
        runSaveSheetMutation(
            {
                variables: {
                    characterID,
                    sheet: JSON.stringify(sheetData),
                }
            }
        );
    }

    const [savingSheet, setSavingSheet] = useState(false);

    const value = {
        count, testSetCount: setCount, isEditable, mode, setMode, sheetData, saveSheet, savingSheet, setSavingSheet,
        updateSheetData: updateSheetData(sheetData, setSheetData, count, setCount, characterID)
    };

    useEffect(() => {
        if (mode === 'view' && lastSaveCount !== count) {
            localStorage.setItem('CharacterSheet_' + characterID, JSON.stringify(sheetData));
            console.log("View Toggle Save");
            setLastSaveCount(count);
        } else if (mode === 'edit' && count === 0) {
            setCount(1);
            setLastSaveCount(1);
        }
        return () => {
            localStorage.setItem('CharacterSheet_' + characterID, JSON.stringify(sheetData));
            console.log("Redirect Save");
        }
    }, [characterID, isFirstLoad, setIsFirstLoad, userSheet, sheetData, setSheetData, mode]);

    useInterval(
        () => {
            if (mode === 'edit' && lastSaveCount !== count) {
                localStorage.setItem('CharacterSheet_' + characterID, JSON.stringify(sheetData));
                console.log("Edit Interval Save");
                setLastSaveCount(count);
            }
        },
        1000
    );

    return (
        <SheetContext.Provider value={value}>
            {children}
        </SheetContext.Provider>
    )
};

SheetContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
    sheetData: PropTypes.object,
    characterID: PropTypes.number,
    isEditable: PropTypes.bool,
    initialMode: PropTypes.string,
};

SheetContextProvider.defaultProps = {
    sheetData: defaultSheetData,
    characterID: null,
    isEditable: false,
    initialMode: 'view',
};

export default SheetContextProvider;