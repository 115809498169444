import {GET_ARTICLES, GET_CATEGORIES, GET_CURRENT_USER} from "../../../graphql/Queries";
import {useHistory} from "react-router-dom";
import React, {useMemo, useState} from "react";
import {Button, CardGroup, Header} from "semantic-ui-react";
import {hasPermission, PERMISSIONS} from "../../../utilities/PermissionUtils";
import {ARTICLE_CREATE, ARTICLE_EDIT} from "../../../constants/routes";
import {useMutation, useQuery} from "@apollo/client";
import ArticleCard from "./ArticleCard";
import {TOGGLE_ARTICLE_PUBLISH} from "../../../graphql/Mutations";
import {FormControl, Grid, IconButton, InputBase, InputLabel, MenuItem, Paper, Select} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const ArticleHomePage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const {data: userData} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'cache-and-network'});
    const {data: articles, loading: isFetchingArticles, refetch} = useQuery(GET_ARTICLES, {fetchPolicy: 'cache-and-network'});
    const {data: categories, loading: isFetchingCategories} = useQuery(GET_CATEGORIES, {fetchPolicy: 'cache-and-network'});
    const [togglePublishArticle] = useMutation(TOGGLE_ARTICLE_PUBLISH, {
        onCompleted() {},
        onError(e) {
            console.log(e.message);
        }
    });

    const history = useHistory();
    const user = userData && userData.CurrentUser ? userData.CurrentUser : {};
    const handleCreateArticle = () => history.push(ARTICLE_CREATE);

    const handleClickPublish = (articleID) => () => {
        togglePublishArticle({variables: {articleID}});
    };

    const handleClickEdit = article => () => {
        history.push(ARTICLE_EDIT.replace(':articleID', article.id));
    }

    const handleSearchChange = e => {
        setSearchTerm(e.target.value);
        if (e.target.value === '') {
            refetch({searchTerm: null});
        }
    }

    const handleSearchKey = e => {
        e.preventDefault();
        if (e.key === 'Enter') {
            handleClickSearch();
        }
    }

    const handleClickSearch = () => {
        if (searchTerm === '') return;
        refetch({searchTerm});
    }

    const handleCategorySelect = e => {
        setSelectedCategory(e.target.value);
    }

    const filteredArticles = useMemo(() => {
        if (!articles || !articles.Articles) return [];
        let filteredArticles = [...articles.Articles];
        if (selectedCategory) {
            filteredArticles = filteredArticles.filter(article => !!article.Categories.find(category => category === selectedCategory));
        }

        return filteredArticles.sort((a, b) => a.Title > b.Title ? 1 : -1);
    }, [articles, selectedCategory]);

    const filteredColumns = useMemo(() => {
        const results = [[],[],[]];
        for(let i = 0; i < filteredArticles.length; i++) {
            results[(i % 3)].push(filteredArticles[i]);
        }

        return results;
    }, [filteredArticles]);

    const sortedCategories = categories?.Categories
        ?
            [...categories.Categories].sort()
        :
            [];

    return (
        <>
            <Header as="h2">Knowledge Base {hasPermission(user.Permissions, PERMISSIONS.ARCHIVIST) && (
                <Button basic color='black' onClick={handleCreateArticle} style={{float: 'right'}}>+ Create Article</Button>
            )}</Header>
            <Grid container>
                <Grid item xs={12} sm={8}>
                    <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', mb: 4 }}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search Knowledge Base"
                            onChange={handleSearchChange}
                            onKeyUp={handleSearchKey}
                        />
                        <IconButton onClick={handleClickSearch} sx={{ p: '10px' }}>
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 200, mt: -1}}>
                        <InputLabel id="category-label">Category</InputLabel>
                        <Select
                            disabled={isFetchingCategories}
                            value={selectedCategory}
                            onChange={handleCategorySelect}
                            label="Category"
                        >
                            <MenuItem value='All'>All</MenuItem>
                            {sortedCategories.map(
                                category => <MenuItem value={category}>{category}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <div style={{paddingTop: '30px', paddingLeft: '10px'}}>
                <Grid container spacing={3}>
                    {filteredColumns.map(column => (
                        <Grid item xs={12} md={4}>
                            <CardGroup>
                                {column.map(article => (
                                    <ArticleCard
                                        key={article.id}
                                        article={article}
                                        user={user}
                                        onClickEdit={handleClickEdit(article)}
                                        onClickPublish={handleClickPublish(article.id)}
                                    />
                                ))}
                            </CardGroup>
                        </Grid>
                    ))}
                </Grid>
                {!filteredArticles.length && !isFetchingArticles &&
                    <Header as="h2">No results match the search and category specified.</Header>
                }
            </div>
        </>
    );
};

export default ArticleHomePage;