import InfoCardContainer, {INFOCARD_TYPES} from "../InfoCard/InfoCardContainer";
import BestiaryCardEditor from "./BestiaryCardEditor";
import {useHistory, useParams} from "react-router-dom";
import {BESTIARY} from "../../../constants/routes";
import BestiaryCard from "./BestiaryCard";
import BestiaryEditContainer from "./BestiaryEditContainer";
import {useQuery} from "@apollo/client";
import {GET_ALL_BESTIARY_CARDS} from "../../../graphql/Queries";

const BestiaryEditPage = () => {
    const history = useHistory();
    const {cardID} = useParams();

    const handleSuccess = () => history.push(BESTIARY);

    return cardID === "null" ? (
        <InfoCardContainer
            allowImageUpload
            defaultValues={{
                Name: null,
                Traits: null,
                Level: null,
                Content: null
            }}
            cardInputs={BestiaryCardEditor}
            cardPreview={BestiaryCard}
            type={INFOCARD_TYPES.CREATURE}
            onSuccess={handleSuccess}
        />
    ) : (
        <BestiaryEditContainer cardID={cardID} />
    );
};

export default BestiaryEditPage;