import React from "react";
import PropTypes from 'prop-types';
import {useQuery} from "@apollo/client";
import {GET_ARTICLE, GET_CATEGORIES} from "../../../graphql/Queries";
import {useParams} from "react-router-dom";
import {Header} from "semantic-ui-react";
import ArticleEditorPage, {ARTICLE_MODE} from "./ArticleEditorPage";

const ArticleEditPageWrapper = () => {
    const {articleID} = useParams();
    const {data, loading: articleLoading} = useQuery(GET_ARTICLE, {
        variables: {
            articleID
        },
        fetchPolicy: 'network-only'
    });
    const {data: categories, loading: isFetchingCategories} = useQuery(GET_CATEGORIES, {fetchPolicy: 'network-only'});

    const article = data && data.Article ? data.Article : null;

    return article && !articleLoading && categories ? (
        <ArticleEditorPage article={article} type={ARTICLE_MODE.EDIT} />
    ) : (
        <Header>Article Loading...</Header>
    );
};

export default ArticleEditPageWrapper;