import React, {useState, useEffect} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {GET_CURRENT_USER, GET_LANDING_PAGE} from "../../graphql/Queries";
import {SET_LANDING_PAGE} from "../../graphql/Mutations";
import QuillTextEditor from "../CustomComponents/Quill/QuillTextEditor";
import {hasPermission, PERMISSIONS} from "../../utilities/PermissionUtils";
import {Button, Placeholder, PlaceholderLine} from "semantic-ui-react";

const Landing = () => {
    const [editable, setEditable] = useState(false);
    const [quill, setQuill] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useQuery(GET_LANDING_PAGE, {fetchPolicy: 'cache-and-network'});
    const {data: myData} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'cache-and-network'});
    const [setLandingPage] = useMutation(SET_LANDING_PAGE, {
        onCompleted() {},
        onError() {}
    });

    const post = data && data.LandingPage ? data.LandingPage : null;
    const user = (myData && myData.CurrentUser) ? myData.CurrentUser : {};

    useEffect(() => {
        if (user.Permissions && hasPermission(user.Permissions, PERMISSIONS.ADMIN)) {
            setEditable(true);
        }
    }, [setEditable, user]);

    const handleClickSave = () => {
        setIsSaving(true);
        const content = JSON.stringify(quill.getContents());
        setLandingPage({
            variables: {
                content
            }
        }).finally(() => setIsSaving(false));
    };

    return (
        <div>
            {post
                ?
                    (<QuillTextEditor
                        disabled={!editable}
                        defaultValue={post.Content}
                        setQuill={setQuill}
                    />)
                :
                <>
                    <Placeholder>
                        <h1>
                            <PlaceholderLine />
                        </h1>
                        <br />
                    </Placeholder>
                    <br />
                    <br />
                    <Placeholder>
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </Placeholder>
                </>
            }
            {editable && <Button basic positive loading={isSaving} onClick={handleClickSave}>Save</Button>}
        </div>
    );
};

export default Landing;
