import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useParams, useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {Card, Menu} from 'semantic-ui-react';
import CharacterCard from "./CharacterCard";
import CharacterCreateModal from "./CharacterCreateModal";
import {GET_ALL_CHARACTERS, GET_CURRENT_USER} from "../../../graphql/Queries";
import {APPROVED_CHARACTERS, PENDING_CHARACTERS, NEW_CHARACTERS, USER_CHARACTERS} from "../../../constants/routes";
import {Button, Tab, Tabs} from "@mui/material";

const LoadingCards = () => (
    <span style={{marginLeft: '-8px'}}>
        <Card.Group>
            <CharacterCard loading />
            <CharacterCard loading />
            <CharacterCard loading />
        </Card.Group>
    </span>
);

export const RemovableCharacterList = (props) => {
    const {characters, user, loading, refetch, showDelete, onClickCard, onClickDelete, hideStatus} = props;
    const userID =
        !user
            ? null
            : user.CurrentUser
                ? user.CurrentUser.UserID
                : user.UserID;
    if (loading) {
        return <LoadingCards />
    }

    return (
        <span style={{marginLeft: '-8px'}}>
            <Card.Group>
                {
                    !loading && characters && characters.filter(character => character.Status).map(character => (
                        <CharacterCard
                            showDelete={showDelete && (character.UserID === userID)}
                            hideStatus={hideStatus}
                            onClick={onClickCard}
                            onClickDelete={onClickDelete}
                            refetchCharacters={refetch}
                            key={character.id}
                            character={character}
                        />
                    ))
                }
            </Card.Group>
        </span>
    );
};

RemovableCharacterList.propTypes = {
    characters: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    refetch: PropTypes.func.isRequired,
    hideStatus: PropTypes.bool,
    showDelete: PropTypes.bool,
    onClickCard: PropTypes.func,
    onClickDelete: PropTypes.func,
};

RemovableCharacterList.defaultProps = {
    hideStatus: false,
    loading: false,
    showDelete: false,
    onClickCard: null,
    onClickDelete: null,
};

export const AllCharacters = (props) => {
    const {characters, loading} = props;
    if (loading) {
        return <LoadingCards/>;
    }

    return (
        <span style={{marginLeft: '-8px'}}>
            <Card.Group>
                {
                    !loading && characters && characters.filter(character => character.Status && character.User.Permissions > 0).map(character =>
                        <CharacterCard
                            key={character.id}
                            character={character}
                        />
                    )
                }
            </Card.Group>
        </span>
    );
};

AllCharacters.propTypes = {
    characters: PropTypes.array,
    loading: PropTypes.bool.isRequired,
};

AllCharacters.defaultProps = {
    characters: []
};

const CharacterTabs = () => {
    const {loading: userLoading, data: userData, refetch: userRefetch} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'cache-and-network'});
    const [createCharacterOpen, setCreateCharacterOpen] = useState(false);
    const {tab} = useParams();
    const history = useHistory();

    const openModal = () => setCreateCharacterOpen(true);
    const closeModal = () => setCreateCharacterOpen(false);

    const {loading: allCharactersLoading, data: allCharactersData, refetch: allRefetch} = useQuery(GET_ALL_CHARACTERS, {fetchPolicy: 'cache-and-network'});
    const allCharacters = (allCharactersData && allCharactersData.Characters) || [];
    const approvedCharacters = allCharacters.filter(character => character.Status === 3);
    const pendingCharacters = allCharacters.filter(character => character.Status === 2);
    const pendingCohorts = allCharacters.filter(character => {
        if (!character.LinkedCharacters.find(minion => minion.Type === 2 && minion.Status === 2)) {
            return false;
        }
        return true;
    })
        .map(character => character.LinkedCharacters.find(minion => minion.Type === 2 && minion.Status === 2));

    const pendingApproval = [...pendingCharacters, ...pendingCohorts];

    const newCharacters = allCharacters.filter(character => character.Status === 1);

    const userCharacters = userData && userData.CurrentUser && userData.CurrentUser.Characters;

    const loggedIn = !!(userData && userData.CurrentUser && userData.CurrentUser.id);

    const handleChangeTab = (e, newValue) => {
        switch (newValue) {
            case 'user':
                history.push(USER_CHARACTERS);
                break;
            case 'approved':
                history.push(APPROVED_CHARACTERS);
                break;
            case 'pending':
                history.push(PENDING_CHARACTERS);
                break;
            case 'new':
                history.push(NEW_CHARACTERS);
                break;
        }
    }

    return (
        <>
            <h2>Characters
                <div style={{float: 'right'}}>
                    <Button variant='outlined' onClick={openModal}>Create Character</Button>
                </div>
            </h2>
            <br />
            <Tabs value={tab} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto" aria-label="character list tabs">
                <Tab value='user' label='My Characters' />
                <Tab value='approved' label='Approved Characters' />
                <Tab value='pending' label='Pending Characters' />
                <Tab value='new' label='New Characters' />
            </Tabs>
            {tab === "user" && <RemovableCharacterList showDelete characters={userCharacters} loading={userLoading && !userCharacters} refetch={userRefetch} user={userData} />}
            {tab === "approved" && <AllCharacters characters={approvedCharacters} loading={allCharactersLoading && !approvedCharacters.length} />}
            {tab === "pending" && <AllCharacters characters={pendingApproval} loading={allCharactersLoading && !pendingApproval.length} />}
            {tab === "new" && <AllCharacters characters={newCharacters} loading={allCharactersLoading && !newCharacters.length} />}
            {createCharacterOpen &&
                <CharacterCreateModal
                    characters={userCharacters}
                    isOpen={createCharacterOpen}
                    closeModal={closeModal}
                    refetchCharacters={() => {
                        userRefetch();
                        allRefetch();
                    }}
                />
            }
        </>
    );
};

const condition = authUser => !!authUser;

export default CharacterTabs;
