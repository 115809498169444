import React, {useMemo, useState} from 'react';
import PropTypes from "prop-types";
import {
    Button,
    Dialog, DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {ITEM_TYPE, SLOTS} from "../../../constants/item";
import {useMutation, useQuery} from "@apollo/client";
import {GET_ITEM_IMAGES} from "../../../graphql/Queries";
import {CREATE_ITEM, SET_LANDING_PAGE} from "../../../graphql/Mutations";

const TWO_MEGABYTES_IN_BYTES = 2000000;
const REQUIRED_PARAMS = ['Name', 'CasterLevel', 'Image', 'Slot', 'ItemType', 'MarketCost', 'CraftCost', 'Content'];
const CASTER_LEVELS = Array.from(new Array(100), (x, i) => i + 1);

const ItemIconDialog = ({open, slot, onClose, onChange}) => {
    console.log(slot);
    const folder = slot === 'none' ? 'slotless' : slot;
    const {data, loading} = useQuery(GET_ITEM_IMAGES, {variables: {folder}});
    const [printedImages, setPrintedImages] = useState(false);
    const [image, setImage] = useState(null);

    if (!loading && !printedImages) {
        setPrintedImages(true);
        console.log(data);
    }

    const handleClick = newImage => () => {
        setImage(image === newImage ? null : newImage);
    };

    const handleSubmit = () => {
        onChange({target: {name: 'Image', value: image}});
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle>
                Select Icon
                <IconButton onClick={() => onClose()} sx={{float: 'right'}}>
                    <CloseIcon />
                </IconButton>
                <Button variant='outlined' disabled={!image} onClick={handleSubmit} sx={{float: 'right', paddingRight: '8px'}}>
                    Select Icon
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid container columns={36}>
                    {data && data.ItemImages && data.ItemImages.map(img => (
                        <Grid item xs={9} sm={6} md={4} onClick={handleClick(img)}>
                            <img width={64} height={64} src={`/items/${folder}/${img}`}  style={{backgroundColor: image === img && 'lightgrey', borderRadius: image === img && '8px'}} />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

const ItemCardEditor = props => {
    const {values, editorProps, onChange, onSuccess, editorBody: EditorBody} = props;
    const {CreatorID} = editorProps;
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [saveItem] = useMutation(CREATE_ITEM, {
        onCompleted() {},
        onError() {}
    });

    const slotFolder = useMemo(() => {
        const {Slot} = values;
        if (!Slot) return '';
        if (Slot === 18 || Slot === 19) {
            return SLOTS[18];
        } else if (Slot === 2 || Slot === 3) {
            return 'Weapons';
        } else if (Slot === 13 || Slot === 14) {
            return 'Ring';
        }

        return SLOTS[Slot];
    }, values.Slot);

    const handleChange = e => {
        if (e.target.name === 'Slot' && values.Image) {
            onChange(e, 'Image');
        } else {
            onChange(e);
        }

        if (Object.keys(errors).length) {
            checkForErrors();
        }
    }

    const checkForErrors = () => {
        let hasErrors = false;
        const newErrors = {};

        REQUIRED_PARAMS.forEach(key => {
            if ((typeof values[key] === 'undefined') || (typeof values[key] === 'string' && !values[key].length)) {
                newErrors[key] = true;
                hasErrors = true;
            }
        });

        if(isNaN(values.MarketCost)) {
            newErrors.MarketCost = true;
            hasErrors = true;
        }

        if(isNaN(values.CraftCost)) {
            newErrors.CraftCost = true;
            hasErrors = true;
        }

        setErrors(newErrors);
        return hasErrors;
    }

    const handleSubmit = async () => {
        if (checkForErrors()) return;
        try {
            const result = await saveItem({
                variables: {
                    input: {...values, CreatorID}
                }
            });
            if (result.data && result.data.Item && result.data.Item.Create) {
                onSuccess();
            } else {
                alert('Item was not saved successfully.');
            }
        } catch (e) {
            alert('Item was not saved.');
        }
    };

    const folder = values.Slot ? slotFolder.toLowerCase() : '';

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div style={{textAlign: 'right'}}>
                        <Button variant="outlined" onClick={handleSubmit}>
                            Save Item
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={8} md={6}>
                            <FormControl fullWidth variant="standard" required error={!!errors.Name}>
                                <InputLabel htmlFor="component-name">Name</InputLabel>
                                <Input id="component-name" name='Name' value={values.Name || ''} onChange={handleChange}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControl fullWidth required error={!!errors.CasterLevel} variant="standard">
                                <InputLabel htmlFor="component-caster-level">Caster Level</InputLabel>
                                <Select
                                    labelId="select-caster-level"
                                    name="CasterLevel"
                                    value={values.CasterLevel}
                                    onChange={handleChange}
                                    label="Caster Level"
                                >
                                    {CASTER_LEVELS.map(key => (
                                        <MenuItem value={key}>{key}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControl fullWidth required error={!!errors.Slot} variant="standard">
                                <InputLabel htmlFor="component-slot">Slot</InputLabel>
                                <Select
                                    labelId="select-slot"
                                    name="Slot"
                                    value={values.Slot}
                                    onChange={handleChange}
                                    label="Slot"
                                >
                                    {Object.keys(SLOTS).map(key => (
                                        <MenuItem value={parseInt(key)}>{SLOTS[key]}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormControl fullWidth required error={!!errors.ItemType} variant="standard">
                                <InputLabel htmlFor="component-slot">Item Type</InputLabel>
                                <Select
                                    labelId="select-item-type"
                                    name="ItemType"
                                    value={values.ItemType}
                                    onChange={handleChange}
                                    label="Item Type"
                                >
                                    {Object.keys(ITEM_TYPE).map(key => (
                                        <MenuItem value={parseInt(key)}>{ITEM_TYPE[key]}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormControl fullWidth variant="standard" required error={!!errors.MarketCost}>
                                <InputLabel htmlFor="component-market-price">Market Price</InputLabel>
                                <Input type='number' id="component-market-price" name='MarketCost' value={values.MarketCost || ''}
                                       onChange={handleChange}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormControl fullWidth variant="standard" required error={!!errors.CraftCost}>
                                <InputLabel htmlFor="component-crafting-cost">Crafting Cost</InputLabel>
                                <Input type='number' id="component-crafting-cost" name='CraftCost' value={values.CraftCost || ''}
                                       onChange={handleChange}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={3} md={2}>
                            <Button
                                disabled={!values.Slot}
                                variant='outlined'
                                color={!!errors.Image ? 'error' : 'primary'}
                                onClick={() => setOpen(true)}
                                sx={{marginTop: '12px'}}
                            >
                                Select Icon
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ItemIconDialog open={open && values.Slot} onClose={() => setOpen(false)} onChange={handleChange} slot={folder} />
        </>
    )
};

ItemCardEditor.propTypes = {
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    editorBody: PropTypes.node.isRequired,
};

export default ItemCardEditor;