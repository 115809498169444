import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {Menu, Checkbox, Button, Modal} from "semantic-ui-react";
import {SheetContext} from "./SheetContext";
import {useMutation} from "@apollo/client";
import {SAVE_SHEET} from "../../../../graphql/Mutations";

const EditMenu = props => {
    const {character, loading} = props;
    const {saveSheet, savingSheet, mode, setMode, count, setCount} = useContext(SheetContext);

    const handleClickSaveSheet = () => {
        saveSheet();
    }

    const handleClickClear = () => {
        localStorage.removeItem('CharacterSheet_' + character.CharacterID);
        window.location.reload();
    }

    const handleClickToggleEditable = () => {
        setMode(mode === 'view' ? 'edit' : 'view');
    }

    const [isClearModalOpen, setIsClearModalOpen] = useState(false);

    return (
        <>
            <Menu style={{marginBottom: '0px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px'}}>
                <Menu.Item>
                    <Checkbox checked={mode === 'edit'} label="Edit Mode" onClick={handleClickToggleEditable} toggle />
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item>
                        <Button
                            negative
                            loading={savingSheet}
                            disabled={count === 0 || savingSheet || loading}
                            onClick={() => setIsClearModalOpen(true)}
                        >
                            Clear
                        </Button>
                    </Menu.Item>
                    <Menu.Item>
                        <Button
                            positive
                            loading={savingSheet}
                            disabled={count === 0 || savingSheet || loading}
                            onClick={handleClickSaveSheet}
                        >
                            Save Sheet
                        </Button>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Modal size="mini" open={isClearModalOpen} onOpen={() => setIsClearModalOpen(true)} onClose={() => setIsClearModalOpen(false)}>
                <Modal.Header>Clear Sheet Changes</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        Are you sure you want to clear changes to your sheet?
                        This cannot be undone!
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => setIsClearModalOpen(false)}>
                        Cancel
                    </Button>
                    <Button positive onClick={handleClickClear}>
                        Yes, I'm Sure
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

EditMenu.propTypes = {
    character: PropTypes.object,
    loading: PropTypes.bool.isRequired,
};

EditMenu.defaultProps = {
    character: {},
};

export default EditMenu;