import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useMutation} from '@apollo/client';

import {Button, Dropdown, Form, Header, Image, Modal} from 'semantic-ui-react';
import {SAVE_CHARACTER} from "../../../graphql/Mutations";
import {reduceImage} from "../../Helpers/ImageHelper";

const CharacterUpdateModal = (props) => {
    const [submitting, setSubmitting] = useState(false);
    const {character} = props;
    const editLevelAndSheet = character.Status < 3;
    const [saveCharacter] = useMutation(SAVE_CHARACTER, {
        onCompleted () {
            props.closeModal();
        },
        onError (error) {
            console.log(error.message);
        }
    });

    const defaultRoles = [];
    for (let i = 1; i < 128; i *= 2) {
        const test = character.Roles & i;
        if ((character.Roles & i) === i) {
            defaultRoles.push(i);
        }
    }

    const defaultValues = {
        CharacterID: character.id,
        Name: character.Name,
        Race: character.Race,
        Level: character.Level,
        Mythic: character.Mythic || 0,
        Avatar: character.Avatar,
        SheetURL: character.SheetURL,
        Description: character.Description,
        Roles: defaultRoles,
        Type: character.Type,
        TypeName: character.TypeName,
    };

    const [values, setValues] = useState(defaultValues);

    const levels = [];

    for (let i = 3; i < 21; i++) {
        levels.push({
            key: i, text: i, value: i
        });
    }

    const roleOptions = [
        { key: 1, text: "Tank", value: 1, color: 'blue', icon: 'shield' },
        { key: 2, text: "Control", value: 2, color: 'yellow', icon: 'chess' },
        { key: 3, text: "Healer", value: 4, color: 'green', icon: 'heartbeat' },
        { key: 4, text: "Buffer", value: 8, color: 'purple', icon: 'arrow alternate circle up outline' },
        { key: 5, text: "Debuffer", value: 16, color: 'black', icon: 'arrow alternate circle down outline' },
        { key: 6, text: "Damage", value: 32, color: 'red', icon: 'bolt' }
    ];

    const roleRenderLabel = (label) => ({
        color: label.color,
        content: label.text,
        icon: label.icon
    });

    const onChange = (e, { name, value }) => {
        setValues({...values, [name]: value});
    };

    const onUploadAvatar = (e, val) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            reduceImage(reader.result, AvatarData => setValues({...values, AvatarData}));
        };
        reader.onerror = error => console.log(error);
    };

    const onSubmit = event => {
        setSubmitting(true);
        const input = {...values};
        input.Roles = input.Roles.reduce((role, acc) => acc += role, 0);
        input.Level = (input.Level && input.Level > 2 && input.Level < 21) ? input.Level : 3;

        console.log(JSON.stringify(values));

        delete input.Avatar;
        saveCharacter({
            variables: {
                input
            }
        }).then(results => {
            console.log(results);
        }).finally(() => {
            setSubmitting(false);
        })
    };

    const onCancel = () => {
        setValues(defaultValues);
        props.closeModal();
    };

    return (
        <Modal
            onClose={onCancel}
            open={props.isOpen}
        >
            <Modal.Header>Create a Character</Modal.Header>
            <Modal.Content>
                <Header>Character Details</Header>
                <Form onSubmit={onSubmit}>
                    <Form.Group>
                        <Form.Input fluid label='Name' required name="Name" width={4} placeholder='Character Name' value={values.Name} onChange={onChange} />
                        <Form.Input fluid label='Race' required name="Race" width={4} placeholder='Character Race' value={values.Race} onChange={onChange} />
                        {editLevelAndSheet && character.Type === 1 &&
                            <Form.Select
                                fluid
                                required
                                label='Level'
                                name="Level"
                                options={levels}
                                value={values.Level}
                                onChange={onChange}
                            />
                        }
                        {character.Type === 3 &&
                            <Form.Input fluid label='Type Name' required name="TypeName" width={4} placeholder='Familiar, etc.'
                                    value={values.TypeName} onChange={onChange}/>
                        }
                    </Form.Group>
                    <Form.Group>
                        {character.Type !== 3 &&
                            <Form.Dropdown
                                multiple
                                selection
                                fluid
                                required
                                width={8}
                                label="Roles"
                                name="Roles"
                                options={roleOptions}
                                placeholder="Select Roles"
                                renderLabel={roleRenderLabel}
                                onChange={onChange}
                                value={values.Roles}
                            />
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Input
                            fluid
                            width={16}
                            label='Classes'
                            required
                            name="Description"
                            placeholder={
                                character.Type !== 3
                                    ?
                                        'Monk (Zen Archer) [Ranger (Divine Tracker)]'
                                    :   'Enter a nice little description here..'
                            }
                            value={values.Description}
                            onChange={onChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input fluid width={16} label='Character Sheet' accept="image/*" name="SheetURL" value={values.SheetURL}
                                    placeholder='https://myth-weavers.com/' onChange={onChange}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Input fluid type="file" label='Avatar URL' name='AvatarData' width={6} placeholder='https://' onChange={onUploadAvatar} />
                        <img width={200} height={200} style={{'objectFit': 'cover'}} src={values.AvatarData || values.Avatar || 'https://react.semantic-ui.com/images/wireframe/white-image.png'} />
                    </Form.Group>
                    <Button positive type='submit' loading={submitting}>Submit</Button>
                    <Button negative onClick={onCancel}>Cancel</Button>
                </Form>
            </Modal.Content>
        </Modal>
    );
};

CharacterUpdateModal.propTypes = {
    character: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    updateCharacter: PropTypes.func.isRequired,
};

export default CharacterUpdateModal;
