import React from 'react';
import PropTypes from 'prop-types';
import {Icon, Item, Label, Placeholder} from "semantic-ui-react";
import {Box, LinearProgress, Typography} from '@mui/material';
import {
    getAttunementBenefit,
    getAttunementLevel,
    getAttunementToNextLevel,
    getMythic,
    getNextLevelXP
} from "../../../utilities/CharacterUtils";

const LinearProgressWithLabel = (props) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress color="success" variant="determinate" {...props} />
            </Box>
            {props.showPercent &&
                <Box sx={{minWidth: 70}}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value,
                    )}% to ${props.nextLevel}`}</Typography>
                </Box>
            }
        </Box>
    );
}

const Roles = props => (
    <span>
        {(props.roles & 1) === 1 && <Label color='blue'><Icon name='shield' title='Tank' /> Tank</Label>}
        {(props.roles & 2) === 2 && <Label color='yellow'><Icon name='chess' title='Control' /> Control</Label>}
        {(props.roles & 4) === 4 && <Label color='green'><Icon name='heartbeat' /> Healer</Label>}
        {(props.roles & 8) === 8 && <Label color='purple'><Icon name='arrow alternate circle up outline' /> Buffer</Label>}
        {(props.roles & 16) === 16 && <Label color='black'><Icon name='arrow alternate circle down outline' /> Debuffer</Label>}
        {(props.roles & 32) === 32 && <Label color='red'><Icon name='bolt' title='Damage' /> Damage</Label>}
    </span>
);

Roles.propTypes = {
    roles: PropTypes.number.isRequired,
};

const CharacterPageHeader = props => {
    const {character, loading} = props;
    const roles = character ? character.Roles : 0;
    const characterUser = character && character.User ? character.User : {};
    const mythic = getMythic(character.Level);

    const xpToNextLevel = getNextLevelXP(character.Level ? character.Level : 20);
    const xpToCurrentLevel = getNextLevelXP(character.Level ? character.Level - 1 : 20);
    const expForCurrentLevel = xpToNextLevel - xpToCurrentLevel;
    const expToNextLevel = xpToNextLevel - character.Experience || 0;
    const showPercent = !!character?.Experience;
    const expPercent = character.Experience
        ?
            ((character.Experience - xpToCurrentLevel) / expForCurrentLevel) * 100
        :
            0;

    const attunementLevel = getAttunementLevel(character?.Attunement || 0);
    const attunementToNextLevel = getAttunementToNextLevel(character?.Attunement || 0);
    const attunementBenefit = getAttunementBenefit(attunementLevel);

    const numberWithSpaces = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return (
        <>
            <Item.Group>
                <Item>
                    {!character.id || loading
                        ?
                        <Placeholder style={{
                            width: '200px',
                            height: '200px',
                            marginRight: '8px',
                            marginBottom: '4px',
                            border: '1px solid black'
                        }}>
                            <Placeholder.Image/>
                        </Placeholder>
                        :
                        <img width={200} height={200}
                             style={{
                                 objectFit: 'cover',
                                 marginRight: '8px',
                                 marginBottom: '4px',
                                 border: '1px solid black',
                                 backgroundColor: '#E6E6E6'
                             }}
                             src={character.Avatar || '/blank_profile_image.jpg'}/>
                    }
                    <Item.Content>
                        {character.Name && !loading &&
                        <Item.Header>
                            <div style={{display: 'flex'}}>
                                {character.Name}
                                {character.Status === 2 &&
                                <>
                                    &nbsp;<Icon title="Pending Approval" circular color='grey' name='question'
                                                size='tiny'/>
                                </>
                                }
                                {character.Status === 3 &&
                                <>
                                    &nbsp;<Icon
                                    title={`Approved Character${character.Approver !== null && ` (Approved By ${character.Approver.Name})`}`}
                                    circular color='green' name='check' size='tiny'/>
                                </>
                                }
                            </div>
                        </Item.Header>
                        }
                        <Item.Description>
                            {
                                character.id && !loading ?
                                    <>
                                        {character.Type !== 3 &&
                                        <Roles roles={roles}/>
                                        }
                                        <br/>
                                        <b>Player</b> {characterUser.Name || 'Unknown'}

                                        {character.Type !== 3 &&
                                        <>
                                            <br/>
                                            <span className='price'><b>Level</b> {character.Level}</span>
                                        </>
                                        }
                                        {character.Type === 1 && mythic !== 0
                                            ?
                                            <span style={{marginLeft: '8px'}}>
                                                <b>Mythic</b> {mythic}
                                                <br/>
                                            </span>
                                            :
                                            <><br/></>
                                        }
                                        {character.Type === 1 &&
                                            <>
                                                <b>Experience</b> {numberWithSpaces(character.Experience)}
                                                <span
                                                    style={{marginLeft: '12px'}}><b>Mana</b> {numberWithSpaces(character.Mana)}</span>
                                                <span
                                                    style={{marginLeft: '12px'}}
                                                >
                                                <b>Attunement</b>
                                                {` ${numberWithSpaces(character.Attunement)} (${attunementToNextLevel} to Next Attunement)`}
                                                </span>
                                                <br/>
                                                <b>Attunement Benefit: </b>
                                                {attunementBenefit}
                                                <br />
                                                <b>Exp to Next Level:</b> {numberWithSpaces(expToNextLevel)}
                                                <br/>
                                            </>
                                        }
                                        <b>{character.Type < 3 ? 'Classes' : 'Description'}</b> {character.Description}
                                        <br/>
                                        {character.SheetURL &&
                                        <>
                                            <a href={character.SheetURL} target="_blank"><b>Character Sheet</b></a>
                                            <br/>
                                        </>
                                        }
                                        {character.ParentCharacter &&
                                        <>
                                            <b>{character.TypeName}</b> of
                                            <a href={`/characters/character/${character.ParentCharacter.CharacterID}/log`}>
                                                &nbsp;{character.ParentCharacter.Name}
                                            </a>
                                        </>
                                        }
                                    </>
                                    :
                                    (<Placeholder>
                                        <Placeholder.Header>
                                            <Placeholder.Line/>
                                        </Placeholder.Header>
                                        <Placeholder.Paragraph>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                        </Placeholder.Paragraph>
                                    </Placeholder>)
                            }
                        </Item.Description>
                    </Item.Content>
                </Item>
            </Item.Group>
            <LinearProgressWithLabel value={isNaN(expPercent) ? 0 : expPercent} showPercent={showPercent} nextLevel={character.Level + 1} />
        </>
    );
};

CharacterPageHeader.propTypes = {
    character: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default CharacterPageHeader;