import Phaser from 'phaser';
import {EVENT} from "../../MapEvents";
import {hasPermission, PERMISSIONS} from "../../../../../utilities/PermissionUtils";
import {ICON_COUNT} from "../hex_helper_functions";

const ICON_SCALE = 0.56;

export default class WorldMap extends Phaser.Scene {
	constructor(user, tiles, icons) {
		super('hello-world');
		this.controls = undefined;
		this.layers = {};
		this.dragChangeX = 0;
		this.dragChangeY = 0;
		this.objectNames = {};
		this.map = undefined;
        this.user = user;
        this.fogName = user && user.Permissions && hasPermission(user.Permissions, PERMISSIONS.ADMIN)
            ? 'AdminFog'
            : 'Fog';
		this.tileData = tiles;
        this.iconData = icons;
		this.toggles = {};
	}

	preload() {
        this.load.image('basicTerrain', 'tilesets/BasicTerrain.png');
        this.load.image('rivers', 'tilesets/Rivers.png');
        this.load.tilemapTiledJSON('map', 'tilemaps/WorldMap.json');
        for(let i = 0; i < ICON_COUNT; i++) {
            const numString = i.toString().padStart(3, '0');
            this.load.image(`icon${numString}`, `objects/Icon${numString}.png`);
        }

        const mapFiles = (context) => {
            const keys = context.keys();
            const values = keys.map(context);
            return keys.reduce((accumulator, key, index) => ({
                ...accumulator,
                [key]: values[index],
            }), {});
        };

        const allImages = mapFiles(require.context('../../../../../../public/objects', true, /\.(png|gif|ico|jpg|jpeg)$/));
        Object.keys(allImages).forEach(imageURL => {
            const name = imageURL.substr(2, imageURL.length - 6);
            this.objectNames[name] = true;
            this.load.image(name, 'objects/' + name + '.png');
        })
    }

    create() {
        const map = this.make.tilemap({key: 'map'});
        const basicTerrain = map.addTilesetImage('BasicTerrain', 'basicTerrain');
        const rivers = map.addTilesetImage('Rivers', 'rivers');
        const tilesets = [basicTerrain, rivers];

        this.layers = {
            Base: map.createLayer('Base', tilesets, 0, 0),
            Overland: map.createLayer('Overland', tilesets, 0, 0),
            Locations: map.createLayer('Locations', tilesets, 0, 0),
            RiversRoads: map.createLayer('Rivers-Roads', tilesets, 0, 0),
        };

        const objects = [...map.objects[0].objects, ...map.objects[1].objects];

        objects.forEach(object => {
            this.add.sprite(object.x + object.width / 2, object.y + object.height / 2, object.name);
        });

        this.layers.Fog = map.createLayer(this.fogName, tilesets, 0, 0);

        //const fog = map.createBlankLayer('Fog', [], 0, 0, map.widthInPixels, map.heightInPixels, 128, 128);

        // Phaser supports multiple cameras, but you can access the default camera like this:
        const camera = this.cameras.main;

        // Constrain the camera so that it isn't allowed to move outside the width/height of tilemap
        camera.setBounds(0, 0, map.widthInPixels, map.heightInPixels);

        camera.setZoom(1);

        const settlement = this.layers.Locations.findTile((tile) =>
            tile.index === 79
        );

        if (settlement) {
            const mapWidth = map.widthInPixels;
            this.settlementX = (settlement.x + 0.5) * 128;
            this.settlementY = (settlement.y + 1.33) * 96;
            const startX = settlement.x * 128 - (this.game.canvas.width / 2);
            const startY = settlement.y * 96 - (this.game.canvas.height / 3);
            camera.scrollX = startX;
            camera.scrollY = startY;
        }

        // Set up the arrows to control the camera
        const cursors = this.input.keyboard.createCursorKeys();
        this.controls = new Phaser.Cameras.Controls.FixedKeyControl({
            camera: camera,
            left: cursors.left,
            right: cursors.right,
            up: cursors.up,
            down: cursors.down,
            speed: 1.0
        });

        // Help text that has a "fixed" position on the screen
        this.add
            .text(16, 16, "Arrow keys to scroll", {
                font: "18px monospace",
                fill: "#ffffff",
                padding: { x: 20, y: 10 },
                backgroundColor: "#000000"
            })
            .setScrollFactor(0);

        this.input.on(Phaser.Input.Events.POINTER_UP, (pointer) => {
            const distanceChange = this.dragChangeX + this.dragChangeY;
            this.dragChangeX = 0;
            this.dragChangeY = 0;

            // If the user likely was dragging the map, don't count it as clicking a tile.
            if (distanceChange > 60) {
                return;
            }
            const {worldX, worldY} = pointer;
            let oldTile = this.layers.Base.getTileAtWorldXY( worldX, worldY - 64);
            let tileX = oldTile.x;
            let tileY = oldTile.y;
            let xStartOfTile = oldTile.pixelX;
            let yStartOfTile = oldTile.pixelY;

            const xOffset = Math.round(worldX - xStartOfTile);
            const yOffset = Math.round(worldY - yStartOfTile - 64);

            if (yOffset < 32) {
                const xMin = (32 - yOffset) * 2;
                const xMax = 128 - xMin;
                if (xOffset < xMin || xOffset > xMax) {
                    tileY = oldTile.y - 1;
                    if (tileY % 2 === 0) {
                        tileX = oldTile.x + (xOffset > 64 ? 1 : 0);
                    } else {
                        tileX = oldTile.x + (xOffset < 64 ? -1 : 0);
                    }

                    oldTile = this.layers.Base.getTileAt(tileX, tileY);
                }
            }

            const tiles = Object.values(this.layers).map(layer => layer.getTileAt(tileX, tileY));
            const fogTile = this.layers.Fog.getTileAt(tileX, tileY);
            if (this.toggles.defog) {
                this.layers.Fog.removeTileAt(tileX, tileY);
                defog(this.layers, tileX, tileY);

                this.game.events.emit(EVENT.DEFOG_TILE, {x: tileX - settlement.x, y: tileY - settlement.y});
            } else if (!fogTile || fogTile.alpha < 1) {
                this.game.events.emit(EVENT.CLICK_TILE, {x: tileX - settlement.x, y: tileY - settlement.y, tiles});
            }
        });

        this.game.events.on(EVENT.TOGGLE_DEFOG_TILE, () => {
            this.toggles.defog = !this.toggles.defog;
        });

        this.input.on(Phaser.Input.Events.POINTER_MOVE, (pointer) => {
            if (!pointer.isDown) return;
            const cam = this.cameras.main;

            const xDiff = (pointer.x - pointer.prevPosition.x) / cam.zoom;
            const yDiff = (pointer.y - pointer.prevPosition.y) / cam.zoom;

            this.dragChangeX = Math.abs(xDiff) + this.dragChangeX;
            this.dragChangeY = Math.abs(yDiff) + this.dragChangeY;

            cam.scrollX -= xDiff;
            cam.scrollY -= yDiff;
        });

        this.layers.Fog.removeTileAt(settlement.x, settlement.y);
        if (this.tileData.revealed) {
            this.tileData.revealed.forEach(
                coordinate => {
                    const revealedX = settlement.x + coordinate.x;
                    const revealedY = settlement.y + coordinate.y;
                    defog(this.layers, revealedX, revealedY);
                    this.layers.Fog.removeTileAt(revealedX, revealedY);
                }
            );
        }

        for(let i = 0; i < this.iconData.icons.length; i++) {
            const iconDatum = this.iconData.icons[i];
            ICON_LAYOUT[iconDatum.icons.length](this, iconDatum);
        }
    }

    update = (time, delta) => {
        // Apply the controls to the camera each update tick of the game
        this.controls.update(delta);
    }
}

const getIconName = iconNum => `icon${iconNum.toString().padStart(3, '0')}`;

const iconLayoutOne = (scene, iconData) => {
    const [x, y] = getCenter(scene, iconData.x, iconData.y);
    const sprite = scene.add.sprite(x, y, getIconName(iconData.icons[0]));
    sprite.setScale(ICON_SCALE);
};

const iconLayoutTwo = (scene, iconData) => {
    const [x, y] = getCenter(scene, iconData.x, iconData.y);
    const spriteOne = scene.add.sprite(x, y - 32, getIconName(iconData.icons[0]));
    spriteOne.setScale(ICON_SCALE);

    const spriteTwo = scene.add.sprite(x, y + 32, getIconName(iconData.icons[1]));
    spriteTwo.setScale(ICON_SCALE);
}

const iconLayoutThree = (scene, iconData) => {
    const [x, y] = getCenter(scene, iconData.x, iconData.y);
    const spriteOne = scene.add.sprite(x, y - 32, getIconName(iconData.icons[0]));
    spriteOne.setScale(ICON_SCALE);

    const spriteTwo = scene.add.sprite(x - 28, y + 22, getIconName(iconData.icons[1]));
    spriteTwo.setScale(ICON_SCALE);

    const spriteThree = scene.add.sprite(x + 28, y + 22, getIconName(iconData.icons[2]));
    spriteThree.setScale(ICON_SCALE);
}

const iconLayoutFour = (scene, iconData) => {
    const [x, y] = getCenter(scene, iconData.x, iconData.y);
    const spriteOne = scene.add.sprite(x - 28, y - 28, getIconName(iconData.icons[0]));
    spriteOne.setScale(ICON_SCALE);

    const spriteTwo = scene.add.sprite(x + 28, y - 28, getIconName(iconData.icons[1]));
    spriteTwo.setScale(ICON_SCALE);

    const spriteThree = scene.add.sprite(x - 28, y + 28, getIconName(iconData.icons[2]));
    spriteThree.setScale(ICON_SCALE);

    const spriteFour = scene.add.sprite(x + 28, y + 28, getIconName(iconData.icons[3]));
    spriteFour.setScale(ICON_SCALE);
}

const iconLayoutFive = (scene, iconData) => {
    const [x, y] = getCenter(scene, iconData.x, iconData.y);
    const spriteOne = scene.add.sprite(x - 28, y - 28, getIconName(iconData.icons[0]));
    spriteOne.setScale(ICON_SCALE);

    const spriteTwo = scene.add.sprite(x + 28, y - 28, getIconName(iconData.icons[1]));
    spriteTwo.setScale(ICON_SCALE);

    const spriteThree = scene.add.sprite(x, y, getIconName(iconData.icons[2]));
    spriteThree.setScale(ICON_SCALE);

    const spriteFour = scene.add.sprite(x - 28, y + 28, getIconName(iconData.icons[3]));
    spriteFour.setScale(ICON_SCALE);

    const spriteFive = scene.add.sprite(x + 28, y + 28, getIconName(iconData.icons[4]));
    spriteFive.setScale(ICON_SCALE);
}

const iconLayoutSix = (scene, iconData) => {
    const [x, y] = getCenter(scene, iconData.x, iconData.y);

    const spriteOne = scene.add.sprite(x, y - 40, getIconName(iconData.icons[0]));
    spriteOne.setScale(ICON_SCALE);

    const spriteTwo = scene.add.sprite(x - 42, y - 20, getIconName(iconData.icons[1]));
    spriteTwo.setScale(ICON_SCALE);

    const spriteThree = scene.add.sprite(x + 42, y - 20, getIconName(iconData.icons[2]));
    spriteThree.setScale(ICON_SCALE);

    const spriteFour = scene.add.sprite(x - 42, y + 20, getIconName(iconData.icons[3]));
    spriteFour.setScale(ICON_SCALE);

    const spriteFive = scene.add.sprite(x + 42, y + 20, getIconName(iconData.icons[4]));
    spriteFive.setScale(ICON_SCALE);

    const spriteSix = scene.add.sprite(x, y + 40, getIconName(iconData.icons[5]));
    spriteSix.setScale(ICON_SCALE);
}

const iconLayoutSeven = (scene, iconData) => {
    const [x, y] = getCenter(scene, iconData.x, iconData.y);

    const spriteOne = scene.add.sprite(x, y - 40, getIconName(iconData.icons[0]));
    spriteOne.setScale(ICON_SCALE);

    const spriteTwo = scene.add.sprite(x - 42, y - 20, getIconName(iconData.icons[1]));
    spriteTwo.setScale(ICON_SCALE);

    const spriteThree = scene.add.sprite(x + 42, y - 20, getIconName(iconData.icons[2]));
    spriteThree.setScale(ICON_SCALE);

    const spriteFour = scene.add.sprite(x - 42, y + 20, getIconName(iconData.icons[3]));
    spriteFour.setScale(ICON_SCALE);

    const spriteFive = scene.add.sprite(x + 42, y + 20, getIconName(iconData.icons[4]));
    spriteFive.setScale(ICON_SCALE);

    const spriteSix = scene.add.sprite(x, y + 40, getIconName(iconData.icons[5]));
    spriteSix.setScale(ICON_SCALE);

    const spriteSeven = scene.add.sprite(x, y, getIconName(iconData.icons[6]));
    spriteSeven.setScale(ICON_SCALE);
}

const ICON_LAYOUT = {
    1: iconLayoutOne,
    2: iconLayoutTwo,
    3: iconLayoutThree,
    4: iconLayoutFour,
    5: iconLayoutFive,
    6: iconLayoutSix,
    7: iconLayoutSeven,
}

const getCenter = (scene, x, y) => {
    const centerY = scene.settlementY + (y * 96);
    const centerX = (y % 2 === 0)
        ?
            scene.settlementX + (x * 128)
        :
            scene.settlementX + ((x + 0.5) * 128);
    return [centerX, centerY];
}

const defog = (layers, tileX, tileY, explode = true, alpha = 0.7) => {
    const tiles = [
        {x: tileX - 1,                    y: tileY},
        {x: tileX+1,                      y: tileY},
        {x: tileX + (tileY % 2 ? 1 : 0),  y: tileY-1},
        {x: tileX + (tileY % 2 ? 1 : 0),  y: tileY+1},
        {x: tileX + (tileY % 2 ? 0 : -1), y: tileY+1},
        {x: tileX + (tileY % 2 ? 0 : -1), y: tileY-1},
    ];

    tiles.forEach(tileCoordinates => trySetAlpha(tileCoordinates, layers, alpha));

    if (explode) {
        tiles.forEach(tile => defog(layers, tile.x, tile.y, false, 0.9));
    }
}

const trySetAlpha = (tileCoordinates, layers, alpha) => {
    const tile = layers.Fog.getTileAt(tileCoordinates.x, tileCoordinates.y);
    if (tile && tile.alpha > alpha) {
        tile.setAlpha(alpha);
    }
}