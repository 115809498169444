import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from "semantic-ui-react";

const TYPES = [
    "Alchemical", "Armor", "Base Attack Bonus", "Character Advancement", "Circumstance", "Competence", "Deflection", "Dodge", "Enhancement",
    "Enhancement (Armor)", "Enhancement (Nat Armor)", "Enhancement (Shield)", "Inherent", "Insight", "Luck", "Morale", "Mythic",
    "Natural Armor", "Profane", "Racial", "Resistance", "Sacred", "Shield", "Size", "Trait", "Untyped"
];

const TypeDropdown = props => {
    const onChange = (e, data) => {
        props.onChange({target: data})
    }

    return (
        <Dropdown
            placeholder='Type'
            search
            selection
            options={
                TYPES.map(
                    (type, index) => (
                        {key: index, text: type, value: type}
                    )
                )
            }
            {...props}
            onChange={onChange}
        />
    );
};

TypeDropdown.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default TypeDropdown;