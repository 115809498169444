import gql from 'graphql-tag';
import {
  PLAYER_CHARACTERS_COMPANION_FRAGMENT,
  FULL_CHARACTER_FRAGMENT,
  PLAYER_LOG_FRAGMENT,
  POST_FRAGMENT,
  SESSION_FRAGMENT,
  ARTICLE_BASE_FRAGMENT,
  ARTICLE_FULL_FRAGMENT,
  INFO_CARD_FRAGMENT,
  ITEM_FRAGMENT
} from './Fragments';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    CurrentUser {
      __typename
      id: UserID
      UserID
      Name
      Permissions
      Avatar
      DateCreated
      Theme
      PrimordiaShards
      Characters {
        ...OwnerCharacterCompanionFragment
      }
    }
  }
  ${PLAYER_CHARACTERS_COMPANION_FRAGMENT}
`;

export const GET_BASE_USER = gql`
    query GetBaseUser {
    CurrentUser {
      id: UserID
      UserID
      Name
      Permissions
      Avatar
      DateCreated
      Theme
      PrimordiaShards
    }
  }
`;

export const GET_MAP = gql`
  query GetMap {
    Map {
      Tiles
      Icons
    }
  }
`;

export const GET_CATEGORIES = gql`
  query GetCategories {
    Categories
  }
`;

export const GET_USER = gql`
  query GetUser($userID: Int!) {
    User(userID: $userID){
      __typename
      id: UserID
      UserID
      Name
      Permissions
      Avatar
      DateCreated
      PrimordiaShards
      Logs {
        ...PlayerLogFragment
      }
      Characters {
        ...OwnerCharacterCompanionFragment
      }
    }
  }
  ${PLAYER_LOG_FRAGMENT}
  ${PLAYER_CHARACTERS_COMPANION_FRAGMENT}
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    Users {
      __typename
      id: UserID
      UserID
      Name
      Permissions
      Avatar
      DateCreated
      PrimordiaShards
    }
  }
`;

export const GET_DISCORD_USER = gql`
  query GetDiscordUser {
    CurrentUser {
      __typename
      id: UserID
      UserID
      DiscordUser {
        __typename
        id: UserID
        Name
        Discriminator
        DiscordID
        UserID
        AuthKey
        AuthCreateDate
      }
    }
  }
`;

export const GET_SESSION = gql`
  query GetSession($sessionID: Int!) {
    Session(SessionID: $sessionID) {
      __typename
      ...SessionFragment
    }
  }
  ${SESSION_FRAGMENT}
`;

export const GET_SESSIONS = gql`
  query GetSessions {
    ActiveSessions: Sessions(type: "active") {
      __typename
      ...SessionFragment
    }
    NewSessions: Sessions(type: "new"){
      __typename
      ...SessionFragment
    }
    ConcludedSessions: Sessions(type: "concluded", offset: 0, limit: 20) {
      __typename
      ...SessionFragment
    }
  }
  ${SESSION_FRAGMENT}
`;

export const GET_CHARACTER = gql`
  query GetCharacter($characterID: Int!) {
    Character(characterID: $characterID) {
        __typename
        ...FullCharacterFragment
    }
  }
  ${FULL_CHARACTER_FRAGMENT}
`;

export const GET_LANDING_PAGE = gql`
  query GetLandingPage {
    LandingPage {
        __typename
        ...PostFragment
    }
  }
  ${POST_FRAGMENT}
`;

export const GET_ALL_CHARACTERS = gql`
  query GetAllCharacters {
    Characters {
        __typename
        ...OwnerCharacterCompanionFragment
    }
  }
  ${PLAYER_CHARACTERS_COMPANION_FRAGMENT}
`;

export const GET_BESTIARY_CARD = gql`
  query GetBestiaryCard($cardID: Int!) {
    Bestiary {
      Card(CardID: $cardID) {
        __typename
        ...InfoCardFragment
      }
    }
  }
  ${INFO_CARD_FRAGMENT}
`;

export const GET_ALL_BESTIARY_CARDS = gql`
  query GetAllBestiaryCards {
    Bestiary {
      Cards {
        __typename
        ...InfoCardFragment
      }
    }
  }
  ${INFO_CARD_FRAGMENT}
`;

export const GET_ARTICLES = gql`
  query GetArticles($searchTerm: String) {
    Articles(searchTerm: $searchTerm) {
      __typename
      ...ArticleBaseFragment
    }
  }
  ${ARTICLE_BASE_FRAGMENT}
`;

export const GET_ARTICLE = gql`
  query GetArticle($articleID: Int!) {
    Article(ArticleID: $articleID) {
      __typename
      ...ArticleFullFragment
    }
  }
  ${ARTICLE_FULL_FRAGMENT}
`;

export const GET_ARTICLE_BY_LINK = gql`
  query GetArticleByLink($title: String!) {
    ArticleLink(title: $title) {
      __typename
      ...ArticleFullFragment
    }
  }
  ${ARTICLE_FULL_FRAGMENT}
`;

export const GET_ALL_ITEMS = gql`
  query GetAllItems {
    Items {
      AllItems {
        __typename
        ...ItemFragment
      }
    }
  }
  ${ITEM_FRAGMENT}
`;

export const GET_ITEM_IMAGES = gql`
  query GetItemImages($folder: String!) {
    ItemImages(folder: $folder)
  }
`;