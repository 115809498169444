import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useMutation} from '@apollo/client';

import {Button, Dropdown, Form, Header, Image, Modal} from 'semantic-ui-react';
import {SAVE_CHARACTER} from '../../../graphql/Mutations';
import {reduceImage} from "../../Helpers/ImageHelper";

const types = [
    {key: 'pc', text: 'Player Character', value: 1},
    {key: 'cohort', text: 'Cohort', value: 2},
    {key: 'other', text: 'Other', value: 3}
];

const CharacterCreateModal = props => {
    const {characters, closeModal} = props;
    const [saveCharacter] = useMutation(SAVE_CHARACTER, {
        onCompleted () {
            closeModal();
        },
        onError (error) {
            console.log(error.message);
        }
    });

    const initialValues = {Level: 3, Type: 1, TypeName: 'Player Character', AvatarData: null};
    const [values, setValues] = useState({...initialValues});

    const levels = [];

    for (let i = 3; i < 21; i++) {
        levels.push({
            key: i, text: i, value: i
        });
    }

    const defaultCharacterList = [];
    characters.forEach(character => {
        defaultCharacterList.push({key: character.CharacterID, text: character.Name, value: character.CharacterID});
    });

    const [characterList, setCharacterList] = useState(defaultCharacterList);
    const roleOptions = [
        { key: 1, text: 'Tank', value: 1, color: 'blue', icon: 'shield' },
        { key: 2, text: 'Control', value: 2, color: 'yellow', icon: 'chess' },
        { key: 3, text: 'Healer', value: 4, color: 'green', icon: 'heartbeat' },
        { key: 4, text: 'Buffer', value: 8, color: 'purple', icon: 'arrow alternate circle up outline' },
        { key: 5, text: 'Debuffer', value: 16, color: 'black', icon: 'arrow alternate circle down outline' },
        { key: 6, text: 'Damage', value: 32, color: 'red', icon: 'bolt' }
    ];

    const roleRenderLabel = (label) => ({
        color: label.color,
        content: label.text,
        icon: label.icon
    });

    const onChange = (e, { name, value }) => {
        if (name === 'Type' && value !== values.Type) {
            if (value === 1) {
                setValues({...initialValues, Type: value, TypeName: 'Player Character'});
            } else if (value === 2) {
                setValues({...initialValues, Type: value, TypeName: 'Cohort'});
                setCharacterList(
                    characters.filter(character => character.Type === 1)
                        .map(character => ({key: character.CharacterID, text: character.Name, value: character.CharacterID}))
                );
            } else {
                setValues({...initialValues, Type: value});
                setCharacterList(
                    characters.filter(character => character.Type === 1 || character.Type === 2)
                        .map(character => ({key: character.CharacterID, text: character.Name, value: character.CharacterID}))
                );
            }
        } else {
            setValues({...values, [name]: value});
        }
    };

    const onUploadAvatar = (e, val) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            reduceImage(reader.result, AvatarData => setValues({...values, AvatarData}));
        };
        reader.onerror = error => console.log(error);
    };

    const onSubmit = event => {
        const RoleSum = values.Roles ? values.Roles.reduce((role, acc) => acc += role, 0) : 0;
        values.Level = (values.Level && values.Level > 2 && values.Level < 21) ? values.Level : 3;
        if (values.Type !== 1 && !values.LinkedCharacterID) {
            return -1;
        }
        saveCharacter({
            variables: {
                input: {...values, Roles: RoleSum}
            }
        }).then(() => {
            props.refetchCharacters();
        })
    };

    const onCancel = () => {
        setValues({Level: 2});
        closeModal();
    };

    return (
        <Modal
            onClose={onCancel}
            open={props.isOpen}
            size='tiny'
        >
            <Modal.Header>Create a Character</Modal.Header>
            <Modal.Content>
                    <Header>Character Details</Header>
                    <Form onSubmit={onSubmit}>
                        <Form.Group>
                            <Form.Select
                                fluid
                                required
                                width={values.Type === 3 ? 4 : 7}
                                defaultValue={1}
                                label='Type'
                                name='Type'
                                options={types}
                                value={values.Type}
                                onChange={onChange}
                            />
                            {values.Type === 3 &&
                                <Form.Input fluid label='Type Name' required name='TypeName' width={6}
                                        placeholder='Familiar, etc.' onChange={onChange}/>
                            }
                            {values.Type !== 1 &&
                                <Form.Select
                                    fluid
                                    required
                                    width={values.Type === 3 ? 6 : 7}
                                    defaultValue={null}
                                    label='Character'
                                    name='LinkedCharacterID'
                                    options={characterList}
                                    value={values.LinkedCharacterID || null}
                                    onChange={onChange}
                                />
                            }
                        </Form.Group>
                        <Form.Group>
                            <Form.Input fluid label='Name' required name='Name' width={7} placeholder='Character Name'
                                        onChange={onChange} value={values.Name || null} />
                            <Form.Input fluid label='Race' required name='Race' width={6} placeholder='Character Race'
                                        onChange={onChange} value={values.Race || null} />
                            {values.Type === 1 &&
                                <Form.Select
                                    fluid
                                    required
                                    width={3}
                                    defaultValue={3}
                                    label='Level'
                                    name='Level'
                                    options={levels}
                                    value={values.Level || undefined}
                                    onChange={onChange}
                                />
                            }
                        </Form.Group>
                        {values.Type !== 3 &&
                            <Form.Group>
                                <Form.Dropdown
                                    multiple
                                    selection
                                    fluid
                                    required={values.Type !== 3}
                                    width={16}
                                    label='Roles'
                                    name='Roles'
                                    options={roleOptions}
                                    placeholder='Select Roles'
                                    renderLabel={roleRenderLabel}
                                    onChange={onChange}
                                    value={values.Roles || null}
                                />
                            </Form.Group>
                        }
                        <Form.Group>
                            <Form.Input
                                fluid
                                width={16}
                                label={values.Type !== 3 ? 'Classes' : 'Description'}
                                required={values.Type !== 3}
                                name='Description'
                                placeholder={
                                    values.Type !== 3
                                        ? 'Monk (Zen Archer) [Ranger (Divine Tracker)]'
                                        : 'Tell us a little about your companion...'
                                }
                                onChange={onChange}
                                value={values.Description || null}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input fluid width={16} label='Character Sheet' name='SheetURL'
                                        placeholder='https://myth-weavers.com/' onChange={onChange}
                                        value={values.SheetURL || null} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input fluid type='file' label='Avatar URL' name='AvatarData' width={16}
                                        placeholder='Select File' accept="image/*" onChange={onUploadAvatar} />
                        </Form.Group>
                        <Form.Group>
                            <img width={200} height={200} style={{objectFit: 'cover', margin: '0 auto'}}
                                 src={values.AvatarData || 'https://react.semantic-ui.com/images/wireframe/white-image.png'} />
                        </Form.Group>
                        <Button positive type='submit'>Submit</Button>
                        <Button negative onClick={onCancel}>Cancel</Button>
                    </Form>
            </Modal.Content>
        </Modal>
    );
};

CharacterCreateModal.propTypes = {
    characters: PropTypes.array,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    refetchCharacters: PropTypes.func.isRequired,
};

CharacterCreateModal.defaultProps = {
    characters: [],
}

export default CharacterCreateModal;
