import React from 'react';
import {Paper} from "@mui/material";
import NavBar from "./Navigation/NavBar";
import {useQuery} from "@apollo/client";
import {GET_BASE_USER, GET_CURRENT_USER} from "../graphql/Queries";
import EmptyPage from "./Pages/EmptyPage";
import {Container} from "semantic-ui-react";
import LoggedOutRoutes from "./Navigation/LoggedOutRoutes";
import PlayerUpdatePage from "../components/Pages/Player/PlayerUpdatePage";
import LoggedInRoutes from "./Navigation/LoggedInRoutes";

const SiteContainer = () => {
    const {data, loading} = useQuery(GET_BASE_USER, {fetchPolicy: 'cache-and-network'});
    const user = (data && data.CurrentUser) ? data.CurrentUser : {};
    const loadingUser = loading;
    console.log('Loading: ' + loadingUser);
    return (
        <Paper>
            {loadingUser
                ?
                    <EmptyPage />
                :
                    <>
                        <NavBar />
                        <div className="AppPage">
                            <div style={{height: '76px'}} />
                            <Container>
                                {!user.id && <LoggedOutRoutes />}
                                {user.id && <LoggedInRoutes user={user} />}
                            </Container>
                        </div>
                    </>
            }
        </Paper>
    )
}

export default SiteContainer;