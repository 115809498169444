import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {useMutation, useQuery} from "@apollo/client";
import {GET_CATEGORIES, GET_CURRENT_USER, GET_SESSIONS} from "../../../graphql/Queries";
import {useHistory, useParams} from "react-router-dom";

import {Button, Dropdown, Header, Form, Label} from "semantic-ui-react";
import {hasPermission, PERMISSIONS} from "../../../utilities/PermissionUtils";
import {isMobile} from "react-device-detect";
import {ARTICLE_CREATE, ARTICLES} from "../../../constants/routes";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import QuillTextEditor from "../../CustomComponents/Quill/QuillTextEditor";
import {CREATE_ARTICLE, UPDATE_ARTICLE} from "../../../graphql/Mutations";

export const ARTICLE_MODE = {
    CREATE: 'create',
    EDIT: 'edit',
};

const ArticleEditorPage = ({article, type}) => {
    const {data: userData} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'cache-and-network'});
    const {data: categories, loading: isFetchingCategories} = useQuery(GET_CATEGORIES, {fetchPolicy: 'cache-and-network'});
    const history = useHistory();
    const [createArticle] = useMutation(CREATE_ARTICLE, {
        onCompleted() {
            history.push(ARTICLES);
        },
        onError(e) {
            console.log(e.message);
        }
    });
    const [updateArticle] = useMutation(UPDATE_ARTICLE, {
        onCompleted() {
            history.push(ARTICLES);
        },
        onError(e) {
            console.log(e.message);
        }
    });
    const [quill, setQuill] = useState(null);
    const [categorySearch, setCategorySearch] = useState('');
    const [modifiedCategoryOptions, setModifiedCategoryOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [values, setValues] = useState(article || {});
    const [categoriesLoaded, setCategoriesLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {tab} = useParams();
    const user = userData && userData.CurrentUser ? userData.CurrentUser : {};

    const ARTICLE_TYPES = [
        {key: 0, text: "General", value: 0},
        {key: 1, text: "Person", value: 1},
        {key: 2, text: "Location", value: 2},
        {key: 3, text: "Object", value: 4},
    ];

    const header = type === ARTICLE_MODE.CREATE ? 'Create New Article' : 'Edit Article';

    const handleChange = (e, { name, value }) => {
        if (name === 'Categories') {
            value = value.map(val => val.capitalize());
            if (categorySearch.length &&
                !categoryOptions.filter(
                    category => category.key.toLowerCase().includes(categorySearch.toLowerCase())
                ).length &&
                value.filter(val => val === categorySearch).length
            ) {
                const data = [...categoryOptions, {key: categorySearch, text: categorySearch, value: categorySearch}];
                setCategoryOptions(data);
            }
        }

        setValues({...values, [name]: value});
    };

    const handleChangeCategorySearch = (e, {searchQuery}) => {
        const matches = categoryOptions.filter(cat => {
            const test = cat.key;
            return cat.key.toLowerCase().includes(searchQuery.toLowerCase());
        });
        if (!matches.length && searchQuery.length) {
            setModifiedCategoryOptions([...categoryOptions, {key: searchQuery, text: searchQuery, value: searchQuery}]);
        } else {
            setModifiedCategoryOptions(categoryOptions);
        }

        setCategorySearch(searchQuery);
    };

    const handleSubmit = () => {
        const text = quill.getText();
        if (!text || text === '\n') return;
        if (!values.Categories) return;

        const Categories = values.Categories.map(category => category.capitalize());

        if (type === ARTICLE_MODE.CREATE) {
            const input = {...values, Categories, Content: JSON.stringify(quill.getContents()), Text: quill.getText()};
            createArticle({variables: {input}});
        } else {
            const {Title, Summary, DateTime, ArticleType} = values;
            const input = {Title, Summary, DateTime, ArticleType, Categories, Content: JSON.stringify(quill.getContents()), Text: quill.getText()};
            updateArticle({variables: {input, articleID: article.id}});
        }
    };

    useEffect(() => {
        if (categories && !categoriesLoaded) {
            const newCategories = categories.Categories.map(category => ({
                key: category,
                text: category,
                value: category
            }));

            setCategoriesLoaded(true);
            setCategoryOptions(newCategories);
            setModifiedCategoryOptions(newCategories);
        }
        if (quill && values.Post && values.Post.Content) {
            quill.setContents(JSON.parse(values.Post.Content));
        }
    }, [categories, categoriesLoaded, quill]);

    return (
        <>
            <Header as="h2">{header}</Header>
            <Form onSubmit={handleSubmit}>
                <Form.Input required fluid label='Title' name="Title" placeholder='Title' onChange={handleChange} value={values.Title || null} />
                <Form.TextArea required label='Summary' name="Summary" placeholder='Summary' onChange={handleChange} value={values.Summary || null} />
                <Form.Group widths='equal'>
                    <Form.Dropdown
                        fluid
                        selection
                        multiple
                        search
                        required
                        label='Categories'
                        name='Categories'
                        placeholder='Categories'
                        defaultValue={values.Categories || null}
                        options={modifiedCategoryOptions}
                        onChange={handleChange}
                        onSearchChange={handleChangeCategorySearch}
                        disabled={isFetchingCategories}
                        loading={isFetchingCategories}
                    />
                    <SemanticDatepicker required label="Date" name="DateTime" format='MM-DD-YYYY' onChange={handleChange} value={values.DateTime ? new Date(values.DateTime) : null} />
                    <Form.Select required fluid label="Article Type" name="ArticleType" options={ARTICLE_TYPES} onChange={handleChange} value={values.ArticleType || null} />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        required
                        width={16}
                        name="Content"
                        onChange={handleChange}
                        control={QuillTextEditor}
                        setQuill={setQuill}
                        defaultValue=''
                        placeholder='Article Contents'
                        maxHeight='800px'
                    />
                </Form.Group>
                <Form.Button type='submit' disabled={isSubmitting}>{!isSubmitting ? 'Submit' : 'Submitting'}</Form.Button>
            </Form>
        </>
    );
};

ArticleEditorPage.propTypes = {
    article: PropTypes.object,
    type: PropTypes.string,
};

ArticleEditorPage.defaultProps = {
    article: null,
    type: ARTICLE_MODE.CREATE,
};

export default ArticleEditorPage;