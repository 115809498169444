import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, Header, Menu} from "semantic-ui-react";
import {isMobile} from "react-device-detect";
import AbilityScoreBox from "../Components/AbilityScoreBox";
import {SheetContext} from "../Components/SheetContext";
import ParagraphTextArea from "../Components/ParagraphTextArea";

const VitalsTab = props => {
    const {sheetData} = useContext(SheetContext);
    return (
        <div>
            <div class='silver-border' style={{width: isMobile ? '100%' : '280px', float: 'left', clear: 'left', marginRight: isMobile ? '0px' : '10px', paddingRight: isMobile ? '0px' : '18px'}}>
                <div style={{margin: '0 0 8px 16px'}}>
                    <Header textAlign='center'>Ability Scores</Header>
                </div>
                <Grid columns={3}>
                    {Object.keys(sheetData.AbilityScores).map(abilityScore => (
                            <Grid.Column>
                                <AbilityScoreBox path={['AbilityScores', abilityScore]} />
                            </Grid.Column>
                        )
                    )}
                </Grid>
            </div>
            {Object.keys(sheetData.Vitals).map(key => (
                <ParagraphTextArea path={['Vitals', key]} />
            ))}
        </div>
    );
};

VitalsTab.propTypes = {
    character: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

export default VitalsTab;
