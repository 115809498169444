import React, {Component, useState} from 'react';
import {compose} from 'recompose';
import {GoogleAuthProvider} from 'firebase/auth';

import {SignUpLink} from './SignUpPage';
import {PasswordForgetLink} from './PasswordForgetPage';
import {withFirebase} from '../../../components/Context/FirebaseContext';
import * as ROUTES from '../../../constants/routes';

import {Button, Divider, Form, Grid, Header, Icon, Message,} from 'semantic-ui-react';
import {useHistory} from "react-router-dom";
import {InMemoryCache, useMutation} from "@apollo/client";
import {SAVE_USER} from "../../../graphql/Mutations";
import {createHttpLink} from "@apollo/client/link/http";

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL || 'https://primordia.online/graphql/'
});

const cache = new InMemoryCache();

const SignInPage = () => (
  <>
      <Header as="h2" textAlign="center">
        Sign In
      </Header>
      <SignInForm />
      <SignInGoogle />
      <SignUpLink />
  </>
);

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

const SignInFormBase = props => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const history = useHistory();

  const onSubmit = event => {
    props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(async result => {
          setEmail('');
          setPassword('');
          setError(null);
          // Create a user in your Firebase Realtime Database too
          props.firebase.user(result.user.uid).set({
              username: result.user.displayName,
              email: result.user.email,
              roles: {},
          });
          const token = await result.user.getIdToken(true);
          localStorage.setItem('authUser', JSON.stringify({...result.user, token}));
          const formData = new FormData();

          formData.append('action', 'AUTHENTICATE');
          formData.append('authToken', token);
          fetch(process.env.REACT_APP_GRAPHQL_URL, {
              method: 'POST',
              body: formData
          }).then(async response => {
              const result = await response.json();
              localStorage.setItem('sessionKey', result.token);
              window.location.href = ROUTES.USER_CHARACTERS;
          })
              .catch(error => {
                  console.log(error);
                  alert('Sign in failure Type 3. Contact Haskalah!');
              });
      })
      .catch(error => {
        setError(error);
      });

    event.preventDefault();
  };

  const onChange = event => {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value);
        break;
      case 'password':
        setPassword(event.target.value);
        break;
    }
  };

  const isInvalid = password === '' || email === '';

  return (
    <div>
      {error && (
        <Message negative>
          <p>{error.message}</p>
        </Message>
      )}
      <Form onSubmit={onSubmit}>
        <Form.Field>
          <label>Email</label>
          <input
            name="email"
            value={email}
            onChange={onChange}
            type="text"
            placeholder="Email Address"
          />
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <input
            name="password"
            value={password}
            onChange={onChange}
            type="password"
            placeholder="Password"
          />
        </Form.Field>
        <Button primary disabled={isInvalid} type="submit">
          Submit
        </Button>
        <PasswordForgetLink />
        <Divider horizontal>Or sign in with</Divider>
      </Form>
    </div>
  );
};

const SignInGoogleBase = (props) => {
  const [error, setError] = useState(null);
  const [saveUser] = useMutation(SAVE_USER, {
    onCompleted () {}
  });
  const history = useHistory();

  const onSubmit = event => {
    props.firebase
      .doSignInWithGoogle()
      .then(async result => {
          // Create a user in your Firebase Realtime Database too
          props.firebase.user(result.user.uid).set({
            username: result.user.displayName,
            email: result.user.email,
            roles: {},
          });
          const token = await result.user.getIdToken(true);
          localStorage.setItem('authUser', JSON.stringify({...result.user, token}));

          const formData = new FormData();
          formData.append('action', 'CREATE');
          formData.append('authToken', token);
          formData.append('username', result.user.displayName);
          fetch(process.env.REACT_APP_GRAPHQL_URL, {
              method: 'POST',
              body: formData
          }).then(async response => {
              const result = await response.json();
              localStorage.setItem('sessionKey', result.token);

              if (result.accountCreated) {
                  window.location.href = ROUTES.ACCOUNT;
              } else {
                  window.location.href = ROUTES.USER_CHARACTERS;
              }
          })
          .catch(error => {
              console.log(error);
              alert('Sign in failure Type 2. Contact Haskalah!');
          });
      });

    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit} className="inline">
      <Button color="google plus" type="submit">
        <Icon name="google" /> Google
      </Button>
      {error && (
        <Message negative>
          <p>{error.message}</p>
        </Message>
      )}
    </form>
  );
};

const SignInFacebookBase = props => {
  const [error, setError] = useState(null);
  const history = useHistory();

  const onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(async result => {
          const token = GoogleAuthProvider.credentialFromResult(result)
          localStorage.setItem('authUser', JSON.stringify({...result.user, token}));

          // Create a user in your Firebase Realtime Database too
          return this.props.firebase.user(result.user.uid).set({
            username: result.additionalUserInfo.profile.name,
            email: result.additionalUserInfo.profile.email,
            roles: {},
          });
      })
      .then(() => {
        setError(null);
        history.push(ROUTES.HOME);
      })
      .catch(error => {
          console.log(error);
          if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
              error.message = ERROR_MSG_ACCOUNT_EXISTS;
          }
          setError(error);
      });

    event.preventDefault();
  };

  return (
      <form onSubmit={onSubmit} className="inline">
        <Button color="facebook" type="submit">
          <Icon name="facebook" /> Facebook
        </Button>

        {error && (
          <Message negative>
            <p>{error.message}</p>
          </Message>
        )}
      </form>
  );
};

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithTwitter()
      .then(async socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit} className="inline">
        <Button color="twitter" type="submit">
          <Icon name="twitter" /> Twitter
        </Button>

        {error && (
          <Message negative>
            <p>{error.message}</p>
          </Message>
        )}
      </form>
    );
  }
};

const SignInForm = compose(
  withFirebase,
)(SignInFormBase);

const SignInGoogle = compose(
  withFirebase,
)(SignInGoogleBase);

const SignInFacebook = compose(
  withFirebase,
)(SignInFacebookBase);

const SignInTwitter = compose(
  withFirebase,
)(SignInTwitterBase);

export default SignInPage;

export { SignInForm, SignInGoogle, SignInFacebook, SignInTwitter };
