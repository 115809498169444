import React, {useState} from 'react';
import PropTypes from "prop-types";
import {Button, FormControl, Grid, Input, InputLabel} from "@mui/material";

const TWO_MEGABYTES_IN_BYTES = 2000000;
const REQUIRED_PARAMS = ['Name', 'Level'];

const BestiaryCardEditor = props => {
    const {values, onChange, onSubmit, editorBody: EditorBody} = props;
    const [errors, setErrors] = useState({});

    const handleSubmit = () => {
        let hasErrors = false;
        const newErrors = {};

        REQUIRED_PARAMS.forEach(param => {
            if (!values[param] || !values[param].length) {
                newErrors[param] = true;
                hasErrors = true
            }
        });

        if (hasErrors) {
            setErrors(newErrors);
            return;
        }

        onSubmit();
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div style={{textAlign: 'right'}}>
                    <Button variant="outlined" onClick={handleSubmit}>
                        Save Card
                    </Button>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <FormControl fullWidth variant="standard" required error={!!errors.Name}>
                            <InputLabel htmlFor="component-name">Name</InputLabel>
                            <Input id="component-name" name='Name' value={values.Name || ''} onChange={onChange} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="com ponent-traits">Traits</InputLabel>
                            <Input id="component-traits" name='Traits' value={values.Traits || ''} onChange={onChange} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth required error={!!errors.Name} variant="standard">
                            <InputLabel htmlFor="component-level">Level</InputLabel>
                            <Input id="component-level" name='Level' value={values.Level || ''} onChange={onChange} />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

BestiaryCardEditor.propTypes = {
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    editorBody: PropTypes.node.isRequired,
};

export default BestiaryCardEditor;