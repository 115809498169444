import React from 'react';
import {AppBar, Avatar, Box, Toolbar} from "@mui/material";

const EmptyPage = () => (
    <>
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
                <Toolbar>
                    <Avatar
                        alt="Primordia Living World"
                        src="/crystals-small.png"
                    />
                </Toolbar>
            </AppBar>
        </Box>
        <div />
    </>
);

export default EmptyPage;