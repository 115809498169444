export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';

export const ADMIN = '/admin/:tab';
export const ADMIN_DEFAULT = '/admin/default';
export const ADMIN_TEMPLATES = '/admin/templates';

export const EMPTY_CAHARACTER = '/characters/character/:characterID';
export const CHARACTER_SUBSECTION = '/characters/character/:characterID/:tab/:sheetTab';
export const CHARACTER_SUBSECTION_INDEX = '/characters/character/:characterID/:tab/:sheetTab/:index';
export const CHARACTER = '/characters/character/:characterID/:tab';
export const CHARACTER_BASE = '/characters/character/:characterID';
export const CHARACTERS = '/characters/:tab';
export const USER_CHARACTERS = '/characters/user';
export const APPROVED_CHARACTERS = '/characters/approved';
export const PENDING_CHARACTERS = '/characters/pending';
export const NEW_CHARACTERS = '/characters/new';
export const CHARACTER_CREATE = '/create/character';

export const PLAYERS = '/players';
export const PLAYER_PAGE = '/players/:userID';

export const APPLICATIONS_PAGE = '/applications';
export const APPLICATIONS_OPEN = '/applications/open';
export const APPLICATIONS_APPROVED = '/applications/approved';
export const APPLICATIONS_DENIED = '/applications/denied';

export const SESSION_LIST_PAGE = '/sessions/:tab';
export const ACTIVE_SESSIONS = '/sessions/active';
export const CONCLUDED_SESSIONS = '/sessions/concluded';
export const NEW_SESSIONS = '/sessions/new';
export const SESSION_PAGE = '/sessions/session/:sessionID';

export const ARTICLES = '/knowledgebase';
export const ARTICLE_LINK_PAGE = '/knowledgebase/link/:title';
export const ARTICLE_VIEW_PAGE = '/knowledgebase/article/:articleID';
export const ARTICLE_CREATE = '/knowledgebase/create';
export const ARTICLE_EDIT = '/knowledgebase/edit/:articleID';

export const MAP = '/map';

export const BESTIARY = '/bestiary';
export const BESTIARY_CREATE = '/bestiary/create/:cardID';
export const BESTIARY_CREATE_NULL = '/bestiary/create/null';