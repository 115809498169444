import React, {useState} from "react";
import {ICON_COUNT} from "./Phaser/hex_helper_functions";
import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, MenuItem, Select, TextField} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as ROUTES from "../../../constants/routes";

const AccordionEdit = ({iconDatum, index, onSubmit}) => {
    const [icon, setIcon] = useState(iconDatum ? iconDatum.icons[index] : null);
    const [name, setName] = useState(iconDatum ? iconDatum.name[index] : '');
    const [description, setDescription] = useState(iconDatum ? iconDatum.description[index] : '');
    const [articleID, setArticleID] = useState(iconDatum ? iconDatum.articleID[index] : null);
    const iconList = [];

    for(let i = 0; i < ICON_COUNT; i++) {
        iconList.push(i);
    }

    const getArticleLink = (articleID, name) => (
        <a target='_blank' href={ROUTES.ARTICLE_VIEW_PAGE.replace(':articleID', articleID)}>
            {name}
        </a>
    );

    const onSubmitIcon = () => {
        const names = [...iconDatum.name];
        if (index !== null) names[index] = name;
        else names.push(name);

        const descriptions = [...iconDatum.description];
        if (index !== null) descriptions[index] = description;
        else descriptions.push(description);

        const icons = [...iconDatum.icons];
        if (index !== null) icons[index] = icon;
        else icons.push(icon);

        const articleIDs = [...iconDatum.articleID];
        if (index !== null) articleIDs[index] = articleID;
        else articleIDs.push(articleID ? articleID : null);

        onSubmit(
            {
                ...iconDatum,
                name: names,
                description: descriptions,
                icons,
                articleID: articleIDs
            }
        );
    };

    const clear = () => {
        
    };

    const submitDisabled = icon === null || name === '' || description === '';

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {iconDatum && index !== null ?
                    <Grid container spacing={4}>
                        <Grid item xs={3}>
                            <div style={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center'
                            }}>
                                <img src={`objects/Icon${iconDatum.icons[index].toString().padStart(3, '0')}.png`}/>
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div style={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center'
                            }}>
                                {typeof iconDatum.name !== 'undefined' &&
                                    <p>
                                        {iconDatum.articleID[index] ?
                                            getArticleLink(
                                                iconDatum.articleID[index],
                                                iconDatum.name[index]
                                            )
                                            :
                                            <b>{iconDatum.name[index]}</b>

                                        }
                                        {typeof iconDatum.description !== 'undefined' &&
                                            ` - ${iconDatum.description[index]}`
                                        }
                                    </p>
                                }
                            </div>
                        </Grid>
                    </Grid>
                    :
                    <>New Point Of Interest</>
                }
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Select value={icon} onChange={(e) => setIcon(e.target.value)}>
                            {iconList.map(iconIndex => (
                                <MenuItem value={iconIndex}><img src={`objects/Icon${iconIndex.toString().padStart(3, '0')}.png`} /></MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            label='Name'
                            placeholder="Name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            onKeyUp={e => {
                                if (e.key === ' ') {
                                    setName(name + ' ');
                                }
                            }}
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            required
                            label='Description'
                            placeholder="Description"
                            maxRows={4}
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            onKeyUp={e => {
                                if (e.key === ' ') {
                                    setDescription(description + ' ');
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label='Article ID'
                            placeholder="Article ID"
                            value={articleID}
                            onChange={(e) => {
                                const val = e.target.value;
                                if (val === '' || !val) {
                                    setArticleID(null);
                                } else {
                                    setArticleID(val);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button disabled={submitDisabled} onClick={onSubmitIcon} variant="outlined" fullWidth>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

export default AccordionEdit;