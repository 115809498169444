import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Button, Form, Message, Modal} from 'semantic-ui-react';
import QuillTextEditor from "../../CustomComponents/Quill/QuillTextEditor";
import {useMutation} from "@apollo/client";
import {CLOSE_SESSION} from "../../../graphql/Mutations";

const REWARD_TYPES = [
    {key: 1, text: 'Average Party Level', value: 'apl'},
    {key: 2, text: 'Character Level', value: 'level'}
];

const SessionRewardsModal = props => {
    const {session, closeModal, isOpen} = props;
    const {SessionID, GameMasterCharacter} = session;
    const [values, setValues] = useState({Runtime: 3});
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState('');
    const onCancel = () => closeModal();

    const [saveRewards] = useMutation(CLOSE_SESSION, {
        onCompleted () {
            onCancel();
        },
        onError () {
            setError("Rewards weren't successfully saved.");
        }
    });

    const [quill, setQuill] = useState();
    const handleSetQuill = (quill) => {
        setQuill(quill);
    }

    const estimatedTimes = [];
    for (let i = 1; i < 17; i+= 0.5) {
        estimatedTimes.push({
            key: i, text: i, value: i
        });
    }

    const onChange = (e, { name, value }) => {
        setValues({...values, [name]: value});
    };

    const onSubmit = event => {
        if (isSaving) return;
        setIsSaving(true);

        console.log(JSON.stringify(values));

        const epilogue = quill.getText();
        if (epilogue.length < 50) {
            setError("An Epilogue with a paragraph of details is required to submit rewards.");
            event.preventDefault();
            setIsSaving(false);
            return;
        }

        setError("");
        const rewards = {};
        rewards.SessionID = SessionID;
        rewards.Runtime = values['Runtime'] * 60;
        rewards.Rewards = JSON.stringify({'type': values['Rewards']});
        rewards.Epilogue = epilogue;

        saveRewards({
            variables: {
                input: rewards
            }
        }).finally(() => setIsSaving(false));
    };

    return (
        <Modal open={isOpen}>
            <Modal.Header>Session Rewards</Modal.Header>
            <Modal.Content>
                {error.length > 0 &&
                    <Message error>
                        <p>{error}</p>
                    </Message>
                }
                {!GameMasterCharacter &&
                    <Message warning>
                        <p>
                            <b>You have not selected a GM PC to apply rewards to!</b> You will not receive Primordia Shards either
                            (in case this is a correction for "missed signups"). If you are not submitting a PC but need
                            rewards, please have an Inspector apply the appropriate amount.
                        </p>
                    </Message>
                }
                <Form onSubmit={onSubmit}>
                    <Form.Group>
                        <Form.Select
                            fluid
                            required
                            label='Session Duration (Hours)'
                            name='Runtime'
                            width={4}
                            defaultValue={3}
                            options={estimatedTimes}
                            onChange={onChange}
                        />
                        <Form.Select
                            fluid
                            required
                            label='Reward Type'
                            name='Rewards'
                            width={4}
                            options={REWARD_TYPES}
                            onChange={onChange}
                        />
                    </Form.Group>
                    <Form.Field>
                        <QuillTextEditor
                            setQuill={handleSetQuill}
                            name='Epilogue'
                            placeholder='Add the major details and cool moments of your session here!'
                        />
                    </Form.Field>
                    <Button positive loading={isSaving} type='submit'>Submit</Button>
                    <Button negative onClick={onCancel}>Cancel</Button>
                </Form>
                <Message info>
                    Once you submit rewards, the session will be concluded and no further changes can be made. Please
                    be sure everything is set correctly before submitting!
                </Message>
            </Modal.Content>
        </Modal>
    );
};

SessionRewardsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    session: PropTypes.object.isRequired,
};

export default SessionRewardsModal;
