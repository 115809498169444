import React from 'react';
import {Button, Grid} from "@mui/material";
import {useQuery} from "@apollo/client";
import {GET_ALL_BESTIARY_CARDS, GET_ALL_ITEMS, GET_CURRENT_USER} from "../../../graphql/Queries";
import BestiaryCard from "./BestiaryCard";
import {useHistory} from "react-router-dom";
import {BESTIARY_CREATE_NULL} from "../../../constants/routes";

const BestiaryList = () => {
    const {data: cards, loading, refetch} = useQuery(GET_ALL_BESTIARY_CARDS, {fetchPolicy: 'cache-and-network'});
    const {data: userData} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'cache-and-network'});
    const user = (userData && userData.CurrentUser) || {UserID: null};

    const history = useHistory();

    const bestiaryCards = (cards && cards.Bestiary && !!cards.Bestiary.Cards) ? cards.Bestiary.Cards : [];

    const handleClickCreateCard = () => {
        history.push(BESTIARY_CREATE_NULL);
    }

    return loading
        ?
            'Loading'
        :
        (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <div style={{textAlign: 'right'}}>
                            <Button variant='outlined' onClick={handleClickCreateCard}>
                                Create Card
                            </Button>
                        </div>
                    </Grid>
                    {bestiaryCards.map(card => (
                        <Grid item xs={12} sm={6} lg={4}>
                            <BestiaryCard
                                showToolbar
                                values={{...JSON.parse(card.Metadata), Content: card.Content}}
                                image={card.Image}
                                card={card}
                                user={user}
                            />
                        </Grid>
                    ))}
                </Grid>
            </>
        )
};

export default BestiaryList;