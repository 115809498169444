import gql from 'graphql-tag';
import {ITEM_FRAGMENT, PLAYER_CHARACTERS_COMPANION_FRAGMENT, POST_FRAGMENT, SESSION_FRAGMENT} from './Fragments';

export const SAVE_USER = gql`
    mutation SaveUser($name: String!, $avatar: String, $theme: Int) {
        CurrentUser {
            Save(name: $name, avatar:$avatar, theme: $theme) {
                __typename
                id: UserID
                Name 
                Avatar
                Theme
            }
        }
    }
`;

export const GENERATE_AUTH_KEY = gql`
    mutation GenerateAuthKey {
        CurrentUser {
            CreateAuthKey {
                __typename
                id: UserID
                UserID
                AuthKey
                AuthCreateDate
                DateCreated
                DateUpdated
            }
        }
    }
`;

export const UPLOAD_IMAGE = gql`
    mutation UploadImage($type: String!, $data: String!) {
        Image {
            Create(Type: $type, Data: $data) {
                __typename
                id
                User {
                  __typename
                  Name
                }
                Extension
                Filename
                Type
                Size
                Status
                DateCreated
                DateUpdated
            }
        }
    }
`;

export const SAVE_PERMISSIONS = gql`
    mutation SaveUser($userID: Int!, $permissions: Int!) {
        SetPermissions(input: {userID: $userID, permissions: $permissions}) {
            __typename
            id: UserID
            Permissions
            DateUpdated
        }
    }
`;

export const SAVE_CHARACTER = gql`
    mutation SaveCharacter($input: CharacterInput!) {
        Character {
            Save(input: $input) {
                __typename
                ...OwnerCharacterCompanionFragment
            }
        }
    }
    ${PLAYER_CHARACTERS_COMPANION_FRAGMENT}
`;

export const SET_FOR_INSPECTION = gql`
    mutation SetForInspection($characterID: Int!) {
        Character {
            MarkForInspection(characterID: $characterID) {
                __typename
                id: CharacterID
                Status
            }
        }
    }
`;

export const CANCEL_INSPECTION = gql`
    mutation CancelInspection($characterID: Int!) {
        Character {
            CancelInspection(characterID: $characterID) {
                __typename
                id: CharacterID
                Status
            }
        }
    }
`;

export const SAVE_SHEET = gql`
    mutation SaveSheet($characterID: Int!, $sheet: String!) {
        Character {
            SaveSheet(characterID: $characterID, sheet: $sheet) {
                Sheet
            }
        }
    }
`;

export const APPROVE_CHARACTER = gql`
    mutation ApproveCharacter($characterID: Int!) {
        Character {
            Approve(characterID: $characterID) {
                __typename
                id: CharacterID
                Status
                Approver {
                  __typename
                  id: UserID
                  Name
                }
            }
        }
    }
`;

export const SAVE_MAP_TILES = gql`
    mutation SaveMapTiles($data: String!) {
        Map(data: $data) {
            SaveTiles
        }
    }
`;

export const SAVE_MAP_ICONS = gql`
    mutation SaveMapIcons($data: String!) {
        Map(data: $data) {
            SaveIcons
        }
    }
`;

export const DELETE_CHARACTER = gql`
    mutation DeleteCharacter($characterID: Int!) {
        Character {
            Delete(characterID: $characterID) {
                __typename
                id: CharacterID
                Status
            }
        }
    }
`;

export const SAVE_SESSION = gql`
    mutation CreateSession($input: SaveSessionInput!) {
        Session {
            Save(input: $input) {
                ...SessionFragment
            }
        }
    }
    ${SESSION_FRAGMENT}
`;

export const SESSION_SIGNUP = gql`
    mutation SignupCharacterForSession($sessionID: Int!, $characterIDs: [Int!]!) {
        Session {
            SubmitCharacters(SessionID: $sessionID, CharacterIDs: $characterIDs) {
               __typename
               ...SessionFragment
            }
        }
    }
    ${SESSION_FRAGMENT}
`;

export const SESSION_WITHDRAW = gql`
    mutation WithdrawCharacterFromSession($sessionID: Int!, $characterID: Int!) {
        Session {
            WithdrawCharacter(SessionID: $sessionID, CharacterID: $characterID) {
              __typename
              ...SessionFragment
            }
        }
    }
    ${SESSION_FRAGMENT}
`;

export const SESSION_PUBLISH = gql`
    mutation SignupCharacterForSession($sessionID: Int!, $publish: Boolean!) {
        Session {
            TogglePublish(SessionID: $sessionID, Publish: $publish) {
               __typename
               ...SessionFragment
            }
        }
    }
    ${SESSION_FRAGMENT}
`;

export const SELECT_CHARACTERS = gql`
    mutation SelectCharacters($sessionID: Int!, $pcIDs: [Int!], $gmcID: Int) {
        Session {
            SelectCharacters(SessionID: $sessionID, PlayerCharacterIDs: $pcIDs, GameMasterCharacterID: $gmcID) {
                __typename
                ...SessionFragment
            }
        }
    }
    ${SESSION_FRAGMENT}
`;

export const CLOSE_SESSION = gql`
    mutation CloseSession($input: SessionCloseInput!) {
        Session {
            CloseSession(input: $input) {
                ...SessionFragment
            }
        }
    }
    ${SESSION_FRAGMENT}
`;

export const CREATE_TEMPLATE = gql`
    mutation CreateTemplate($input: TemplateInput!) {
        Application {
            CreateTemplate(input: $input) {
                __typename
                id
                Name
                Creator {
                  UserID
                  Name
                }
                Voters
                Data
                Status
                DateCreated
            }
        }
    }
`;

export const CREATE_ARTICLE  =gql`
    mutation CreateArticle($input: ArticleInput) {
        Article {
            CreateArticle(input: $input) {
                __typename
                id
                ArticleType
                Author {
                    __typename
                    id: UserID
                    UserID
                    Name
                }
                Title
                Summary
                Categories
                Location
                DateTime
                Post {
                    __typename
                    id
                }
                Approver {
                    __typename
                    id: UserID
                    Name
                }
                ApprovalDate
                Status
                DateCreated
                DateUpdated
            }
        }
    }
`;

export const UPDATE_ARTICLE  =gql`
    mutation UpdateArticle($input: ArticleInput, $articleID: Int!) {
        Article {
            UpdateArticle(input: $input, ArticleID: $articleID) {
                __typename
                id
                ArticleType
                Author {
                    __typename
                    id: UserID
                    UserID
                    Name
                }
                Title
                Summary
                Categories
                Location
                DateTime
                Post {
                    __typename
                    id
                }
                Approver {
                    __typename
                    id: UserID
                    Name
                }
                ApprovalDate
                Status
                DateCreated
                DateUpdated
            }
        }
    }
`;

export const TOGGLE_ARTICLE_PUBLISH = gql`
    mutation TogglePublishArticle($articleID: Int!) {
        Article {
            TogglePublish(ArticleID: $articleID) {
                __typename
                id
                Approver {
                    __typename
                    id: UserID
                    Name
                }
                ApprovalDate
            }
        }
    }
`;

export const ADD_INFO_CARD = gql`
    mutation AddInfoCard($input: InfoCardInput!) {
        InfoCard {
            Create(input: $input) {
                __typename
                id
                Metadata
                Type
                Content
                Status
                DateCreated
                DateUpdated
            } 
        }
    }
`;

export const UPDATE_INFO_CARD = gql`
    mutation UpdateInfoCard($input: InfoCardInput!, $id: Int!) {
        InfoCard {
            Update(input: $input, InfoCardID: $id) {
                __typename
                id
                Metadata
                Type
                Content
                Status
                DateCreated
                DateUpdated
                UpdaterUser {
                    __typename
                    id: UserID
                    Name
                }
            }
        }
    }
`;

export const SET_LANDING_PAGE = gql`
    mutation SetLandingPage($content: String!) {
        Post {
            UpdateLandingPage(Content: $content) {
                __typename
                ...PostFragment
            }
        }
    }
    ${POST_FRAGMENT}
`;

export const UPDATE_INFOCARD_VISIBILITY = gql`
    mutation UpdateInfoCardVisibility($id: Int!, $visibility: Int!) {
        InfoCard {
            ChangeVisibility(InfoCardID: $id, Visibility: $visibility) {
                Visibility
            }
        }
    }
`;

export const CREATE_ITEM = gql`
    mutation CreateItem($input: ItemInput!) {
        Item {
            Create(input: $input) {
                ...ItemFragment
            }
        }
    }    
    ${ITEM_FRAGMENT}
`;

export const TOGGLE_ITEM_EQUIP = gql`
    mutation ToggleItem($ItemID: Int!, $Slot: Int) {
        Item {
            ToggleEquip(ItemID: $ItemID, Slot: $Slot) {
                ...ItemFragment
            }
        }
    }    
    ${ITEM_FRAGMENT}
`;