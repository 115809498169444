import React from "react";
import {Route, Switch} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import LandingPage from "../../components/Pages/LandingPage";
import SignUpPage from "../Pages/Authentication/SignUpPage";
import SignInPage from "../Pages/Authentication/SignInPage";
import PasswordForgetPage from "../Pages/Authentication/PasswordForgetPage";

const LoggedOutRoutes = () => {
    return (
        <Switch>
            <Route exact path={ROUTES.LANDING} component={LandingPage} />
            <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route
                path={ROUTES.PASSWORD_FORGET}
                component={PasswordForgetPage}
            />
            <Route path={ROUTES.LANDING} component={LandingPage} />
        </Switch>
    )
}

export default LoggedOutRoutes;