import React from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {Grid, Item, Image, List, Placeholder, Segment} from 'semantic-ui-react';
import {GET_ALL_USERS} from "../../../graphql/Queries";
import {getPermissionTags} from "../../../utilities/PermissionUtils";
import {compose} from "recompose";
import {withAuthorization, withEmailVerification} from "../../Features/Session";

const PlayerListPage = () => {
    const {data: playerData, loading} = useQuery(GET_ALL_USERS, {fetchPolicy: 'cache-and-network'});
    const history = useHistory();
    const players = (playerData && playerData.Users) || [];
    console.log('Hmm');

    const ListPlaceholder = () => (
        <List.Item>
            <Item.Content>
                <Placeholder>
                    <Placeholder.Header image>
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder.Header>
                </Placeholder>
            </Item.Content>
        </List.Item>
    );

    const ListPlaceholders = () => (
        <>
            <ListPlaceholder />
            <ListPlaceholder />
            <ListPlaceholder />
        </>
    );

    const handleClickPlayer = player => () => {
        history.push('/players/' + player.id)
    }

    return (
        <>
            <h2>Players</h2>
            <br />
            {(!loading || players.length > 0)
                ?
                        <Grid>
                            {players.map(player => player.Permissions > 0
                                ?
                                    <Grid.Column mobile={16} tablet={8} computer={5}>
                                        <a style={{cursor: 'pointer'}} onClick={handleClickPlayer(player)}>
                                            <Grid columns={2} verticalAlign='middle'>
                                                <Grid.Row>
                                                    <Grid.Column mobile={2} tablet={4} computer={4}>
                                                        <div style={{width: '48px', height: '48px', display: 'flex'}}>
                                                            <Image style={{marginTop: '3px'}} circular size='tiny' src={player.Avatar || '/blank_profile_image.jpg'} />
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column mobile={14} tablet={12} computer={12}>
                                                        <div>
                                                            {player.Name}
                                                        </div>
                                                        <div>
                                                            {getPermissionTags(player.Permissions, 'mini')}
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </a>
                                    </Grid.Column>
                                :
                                    null
                            )}
                        </Grid>
                :
                    <List divided relaxed>
                        <ListPlaceholders />
                    </List>
            }
        </>
    );
};

export default PlayerListPage;
