import React from 'react';
import './App.scss';
import SiteContainer from "../new/SiteContainer";
import {BrowserRouter} from "react-router-dom";
import {ApolloClient, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {createHttpLink} from "@apollo/client/link/http";
import {ApolloProvider} from "@apollo/react-hooks";

const cache = new InMemoryCache();

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL || 'https://primordia.online/graphql/'
});

const authLink = setContext(async (_, {headers}) => {
    const token = localStorage.getItem('sessionKey') || null;
    // Return the headers to context.
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const client = new ApolloClient({
    cache,
    link: authLink.concat(httpLink),
});

const App = () => {
    console.log('App Load');
    return (
        <ApolloProvider client={client}>
            <BrowserRouter>
                <SiteContainer />
            </BrowserRouter>
        </ApolloProvider>
    );
};

export default App;
