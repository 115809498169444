import React, {Fragment, useContext} from 'react';
import PropTypes from 'prop-types';
import '../Components/AbilityScoreBox.scss';
import {Button, Grid, Header, Input} from "semantic-ui-react";
import EditableTitle from "../Components/EditableTitle";
import Feature from "../Components/Feature";
import {SheetContext} from "../Components/SheetContext";

const RaceSection = props => {
    const {data, index, isEditable, headingSize, paddingWidth, onClickDelete} = props;
    const {title, deletable, traits} = data;
    const mainColumnWidth = 16 - paddingWidth - (deletable ? 1 : 0);
    const {sheetData, updateSheetData, mode} = useContext(SheetContext);

    const handleChangeTitle = e => {
        updateSheetData(['Race', 'Sections', index], 'title', e.target.value);
    }

    const handleChange = traitIndex => ({target:{name, value}}) => {
        const location = sheetData.Race.Sections[index].traits[traitIndex];
        location[name] = value;
        updateSheetData(['Race', 'Sections', index, 'traits'], traitIndex, location);
    }

    const handleClickDeleteTrait = deleteIndex => () => {
        const traits = sheetData.Race.Sections[index].traits;
        const newTraits = traits.filter((trait, traitIndex) => traitIndex !== deleteIndex);
        updateSheetData(['Race', 'Sections', index], 'traits', newTraits);
    }

    const handleClickAddTrait = () => {
        const traits = sheetData.Race.Sections[index].traits;
        traits.push({name: '', replacement: '', deletable: true, description: ''});
        updateSheetData(['Race', 'Sections', index], 'traits', traits);
    }

    const Tag = isEditable ? Fragment : 'ul';

    const renderNode = ({name, replacement, description}) => () => (
        <li className='race'>
            {paddingWidth > 0 &&
                <Grid.Column width={paddingWidth} />
            }
            <Grid.Column width={16}>
                <b>{`${name}${replacement ? ` (replaces ${replacement})` : ''}:`}</b> {`${description || 'Not Provided'}`}
            </Grid.Column>
        </li>
    );

    return (
        <>
            <Grid.Column width={16}>
                <div>
                    <hr />
                </div>
            </Grid.Column>
            <EditableTitle titleEditable={deletable} className='race' isEditable={isEditable} deletable={deletable} onChange={handleChangeTitle} onClickDelete={onClickDelete(index)} name='title' value={title} paddingWidth={1} />
            <Tag className='race'>
                {traits.map((trait, index) => (
                    <Feature
                        paddingWidth={2}
                        isEditable={isEditable}
                        isNameEditable={deletable}
                        isReplacementEditable={deletable}
                        index={index}
                        data={trait}
                        onChange={handleChange(index)}
                        onClickDelete={handleClickDeleteTrait(index)}
                        renderNode={renderNode(trait)}
                    />
                ))}
            </Tag>
            {isEditable && deletable &&
                <Grid.Column width={16}>
                    <Button style={{marginLeft: 'auto', width: '100px'}} positive onClick={handleClickAddTrait}>Add Trait</Button>
                </Grid.Column>
            }
        </>
    )
};

RaceSection.propTypes = {
    deletable: PropTypes.bool,
    headingSize: PropTypes.string,
    paddingWidth: PropTypes.number,
    isEditable: PropTypes.bool,
    index: PropTypes.string.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

RaceSection.defaultProps = {
    deletable: false,
    path: null,
    headingSize: 'h3',
    paddingWidth: 0,
    isEditable: false,
};

export default RaceSection;