import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Menu} from "semantic-ui-react";
import {isMobile} from "react-device-detect";
import {
    CHARACTER_BASE,
} from "../../../constants/routes";
import {useHistory, useParams} from "react-router-dom";
import CharacterLogTable from "../Character/CharacterLogTable";
import VitalsTab from "./Vitals/VitalsTab";
import SheetContextProvider, {SheetContext} from "./Components/SheetContext";
import {PERMISSIONS} from "../../../utilities/PermissionUtils";
import EditMenu from "./Components/EditMenu";
import {defaultSheetData} from "./Components/SheetData";
import {RemovableCharacterList} from "../Character/CharacterCardPage";
import RaceTab from "./Race/RaceTab";
import ClassesTab from "./Components/Classes/ClassesTab";
import {Box, Paper, Tab, Tabs} from "@mui/material";
import InventoryTab from "../../../new/Inventory/InventoryTab";

//const TABS = [{name: 'Vitals', value: 'vitals'}, {name: 'Race', value: 'race'}];
const TABS = [{name: 'Vitals', value: 'vitals'},
    {name: 'Race', value: 'race'},
    {name: 'Classes', value: 'classes'},
    {name: 'Sheet', value: 'sheet'},
    {name: 'Inventory', value: 'inventory'}
];

const PLAYER_TABS = [];

const SheetTabs = props => {
    const {character, refetchCharacter, loading, refetchUser, user} = props;

    let {tab, sheetTab, index} = useParams();
    const history = useHistory();
    const getURL = tab => {
        switch (tab) {
            case 'sheet':
            case 'base':
                return `${CHARACTER_BASE.replace(':characterID', character.CharacterID)}/sheet/base`;
            case 'race':
                return `${CHARACTER_BASE.replace(':characterID', character.CharacterID)}/sheet/race`;
            case 'classes':
                return `${CHARACTER_BASE.replace(':characterID', character.CharacterID)}/sheet/classes/0`;
            default:
                return `${CHARACTER_BASE.replace(':characterID', character.CharacterID)}/${tab}`;
        }
    }

    const isStaff = user.Permissions > PERMISSIONS.PLAYER;
    const defaultTab = character.Type === 1 ? 'log' : null;
    tab = !isStaff && tab === 'vitals' ? defaultTab : tab;
    const tabs = isStaff ? [...TABS] : [...PLAYER_TABS];
    const [initialSheet, setInitialSheet] = useState(null);

    const companions = character && character.LinkedCharacters
        ? character.LinkedCharacters
        : [];

    if (character.Type === 1) {
        tabs.push({name: 'Log', value: 'log'});
    }

    if (companions.length === 1) {
        tabs.push({name: 'Companion', value: 'companions'});
    } else if (companions.length > 1) {
        tabs.push({name: 'Companions', value: 'companions'});
    }

    if (!tabs.find(tabData => tabData.value === tab) && tabs.length > 0) {
        tab = tabs[0].value;
    }

    useEffect(() => {
        if (!initialSheet && character.UserID !== null && !loading) {
            const localSheet = JSON.parse(localStorage.getItem('CharacterSheet_' + character.CharacterID));
            let savedSheet = null;
            try {
                if (typeof character.Sheet === 'string') {
                    savedSheet = JSON.parse(character.Sheet);
                }
            } catch (e) {
            }

            if (savedSheet !== null && (!localSheet || savedSheet.lastUpdatedDate >= localSheet.lastUpdatedDate)) {
                localStorage.removeItem('CharacterSheet_' + character.CharacterID);
                setInitialSheet({mode: 'view', sheetData: savedSheet});
            } else if (!localSheet || !localSheet.version || localSheet.version < "0.1.0") {
                setInitialSheet({mode: 'view', sheetData: defaultSheetData});
            } else {
                setInitialSheet({mode: 'edit', sheetData: localSheet});
            }
        }
    }, [initialSheet, character, loading]);

    const handleChangeTab = (e, newValue) => history.push(getURL(newValue));
    const handleChangeSheetTab = (e, newValue) => history.push(getURL(newValue));

    return character.UserID !== null && !loading && initialSheet ? (
        <SheetContextProvider characterID={character.CharacterID} isEditable={character.UserID === user.UserID} sheetData={initialSheet}>
            {character.UserID === user.UserID && isStaff &&
                <EditMenu character={character} loading={loading} />
            }
            <Paper sx={{borderRadius: '5px'}}>
                <Tabs value={tab} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto" aria-label="character option tabs">
                    <Tab value='log' label='Log' />
                    {isStaff && <Tab value='inventory' label='Inventory' />}
                    {isStaff && <Tab value='sheet' label='Sheet' />}
                    {companions.length && <Tab value='companions' label='Companions' />}
                </Tabs>
                {tab === 'sheet' &&
                    <Tabs value={sheetTab} onChange={handleChangeSheetTab} variant="scrollable" scrollButtons="auto" aria-label="sheet tabs">
                        <Tab value='base' label='Vitals' />
                        <Tab value='race' label='Race' />
                        {/**<Tab value='classes' label='Classes' />*/}
                    </Tabs>
                }
                {tab === "log" && character.Type === 1 && <CharacterLogTable character={character} user={user} />}
                {sheetTab === "base" && isStaff && <VitalsTab character={character} user={user} />}
                {sheetTab === "race" && isStaff && <RaceTab character={character} user={user} />}
                {sheetTab === "classes" && isStaff && <ClassesTab character={character} user={user} />}
                {tab === "companions" && <RemovableCharacterList showDelete characters={companions} refetch={refetchUser} user={user} />}
                {tab === 'inventory' && <InventoryTab character={character} refetchCharacter={refetchCharacter} user={user} />}
            </Paper>
        </SheetContextProvider>
    ) : (<div />);
};

SheetTabs.propTypes = {
    character: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    refetchUser: PropTypes.func.isRequired,
    refetchCharacter: PropTypes.func.isRequired,
};

export default SheetTabs;
