import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useMutation} from '@apollo/client';

import {Button, Form, Header, Message, Modal} from 'semantic-ui-react';
import {SAVE_SESSION} from "../../../graphql/Mutations";
import QuillTextEditor from "../../CustomComponents/Quill/QuillTextEditor";

const FORMATS = [
    {key: 1, text: 'Roll20', value: 1},
    {key: 2, text: 'Foundry', value: 2},
    {key: 3, text: 'Play-By-Post', value: 3}
];

const COLUMNS = [
    'SessionID',
    'Title',
    'StartDate',
    'Format',
    'MinLevel',
    'MaxLevel',
    'Prologue',
    'EstimatedLength',
    'Difficulty',
    'PlayerCount'
];

const SessionSetupModal = props => {
    const {closeModal, session, isOpen, refetch} = props;
    const [saveSession] = useMutation(SAVE_SESSION, {
        onCompleted () {
            onCancel();
        },
        onError (error) {
            console.log(error.message);
        }
    });

    const [values, setValues] = useState({});
    const [error, setError] = useState(false);
    const [quill, setQuill] = useState();
    const [isSaving, setIsSaving] = useState(false);

    const levels = [];

    for (let i = 3; i < 21; i++) {
        levels.push({
            key: i, text: i, value: i
        });
    }

    const estimatedTimes = [];

    for (let i = 1; i < 17; i++) {
        estimatedTimes.push({
            key: i, text: i, value: i
        });
    }

    const onChange = (e, { name, value }) => {
        setValues({...values, [name]: value});
    };

    const onSubmit = () => {
        if (isSaving) return;
        setIsSaving(true);
        const sessionData = {};
        COLUMNS.forEach(column => {
            sessionData[column] = values[column] || session[column] || null;
        });

        if (sessionData.MinLevel > sessionData.MaxLevel) {
            setError(true);
            return false;
        } else {
            setError(false);
        }

        console.log(JSON.stringify(sessionData));

        const prologue = quill.getText();
        if (prologue.length) {
            sessionData.Prologue = prologue;
        }

        saveSession({
            variables: {
                input: sessionData
            }
        }).then(() => {
            refetch();
        }).finally(() => setIsSaving(false));
    };

    const onCancel = () => {
        closeModal();
    };

    const handleSetQuill = (quill) => {
        setQuill(quill);
    };

    return (
        <Modal open={isOpen}>
            <Modal.Header>{session ? 'Edit' : 'Create'} Session</Modal.Header>
            <Modal.Content>
                <Header>Session Details</Header>
                {error &&
                    <Message warning>
                        <p>The Maximum Level must be equal to or higher than the Minimum Level.</p>
                    </Message>
                }
                <Form onSubmit={onSubmit}>
                    <Form.Group>
                        <Form.Input
                            fluid
                            required
                            label='Title'
                            name="Title"
                            width={12}
                            placeholder='Title Here'
                            defaultValue={session.Title}
                            onChange={onChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input
                            fluid
                            required
                            label='Start Date/Time'
                            name="StartDate"
                            width={6}
                            placeholder='e.g. Monday, May 5th at 10:00 AM EST'
                            defaultValue={session.StartDate}
                            onChange={onChange}
                        />
                        <Form.Select
                            fluid
                            required
                            width={4}
                            label='Format'
                            name='Format'
                            defaultValue={session.Format}
                            options={FORMATS}
                            onChange={onChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Select
                            fluid
                            required
                            label='Estimated Duration (h)'
                            name='EstimatedLength'
                            width={3}
                            defaultValue={session.EstimatedLength}
                            options={estimatedTimes}
                            onChange={onChange}
                        />
                        <Form.Input
                            fluid
                            required
                            label='Difficulty'
                            name="Difficulty"
                            width={4}
                            defaultValue={session.Difficulty}
                            onChange={onChange}
                        />
                        <Form.Input
                            fluid
                            required
                            label='# of Players'
                            name="PlayerCount"
                            width={4}
                            defaultValue={session.PlayerCount}
                            onChange={onChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Select
                            fluid
                            required
                            label='Minimum Level'
                            name='MinLevel'
                            defaultValue={session.MinLevel}
                            options={levels}
                            onChange={onChange}
                        />
                        <Form.Select
                            fluid
                            required
                            label='Maximum Level'
                            name='MaxLevel'
                            defaultValue={session.MaxLevel}
                            options={levels}
                            onChange={onChange}
                        />
                    </Form.Group>
                    <Form.Field>
                        <QuillTextEditor defaultValue={session.Prologue || ''} setQuill={handleSetQuill} name='Prologue' />
                    </Form.Field>
                    <Button positive loading={isSaving} type='submit'>Submit</Button>
                    <Button negative onClick={onCancel}>Cancel</Button>
                </Form>
            </Modal.Content>
        </Modal>
    );
};

SessionSetupModal.propTypes = {
    session: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    refetch: PropTypes.func.isRequired,
};

SessionSetupModal.defaultProps = {
    session: {},
}

export default SessionSetupModal;
