import React, {useState} from "react";
import PropTypes from 'prop-types';
import Phaser from 'phaser';
import {Button, Header, Menu} from "semantic-ui-react";
import {useEventListener, useEventEmitter} from "phaser-react-tools";
import WorldMap from "./Phaser/scenes/WorldMap";
import {EVENT} from "./MapEvents";
import {useMutation} from "@apollo/client";
import {SAVE_MAP_TILES} from "../../../graphql/Mutations";

const AdminMapPanel = () => {
    const [revealTileMode, setRevealTileMode] = useState(false);
    const emitRevealTile = useEventEmitter(EVENT.TOGGLE_DEFOG_TILE);

    const [tiles, setTiles] = useState({});
    const [tileImage, setTileImage] = useState(null);
    const [saveMap] = useMutation(SAVE_MAP_TILES, {
        onError (error) {
            console.log(error.message);
        }
    });

    const handleClickRevealTiles = () => {
        const mode = !revealTileMode;
        emitRevealTile();
        setRevealTileMode(mode);
    }

    return (
        <>
            <Button onClick={handleClickRevealTiles}>
                {revealTileMode ? 'Close Reveal Mode' : 'Open Reveal Mode'}
            </Button>
        </>
    );
};

export default AdminMapPanel;