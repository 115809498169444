import React, {useState} from 'react';
import {Card, CardContent} from "@mui/material";
import PropTypes from "prop-types";
import DOMPurify from 'dompurify';
import ReactCardFlip from "react-card-flip";
import BestiaryCardToolbar from "./BestiaryCardToolbar";

const getColor = text => {
    if (text.includes('COLOSSAL')) {
        return '#3a7a58';
    }

    switch(text) {
        case 'LG':
        case 'NG':
        case 'CG':
        case 'LN':
        case 'TN':
        case 'N':
        case 'CN':
        case 'LE':
        case 'NE':
        case 'CE':
            return '#566193';
        case 'FINE':
        case 'DIMINUTIVE':
        case 'TINY':
        case 'MEDIUM':
        case 'LARGE':
        case 'HUGE':
        case 'GARGANTUAN':
        case 'COLOSSAL':
            return '#3a7a58';
        default:
            return '#5d0000';
    }
}

const SectionBreak = ({isHeader}) => {
    return isHeader
        ?
            <div style={{backgroundColor: '#777777', height: '2px', marginBottom: '0.2rem'}} />
        :
            <div style={{
                backgroundColor: '#CCCCCC',
                height: '2px',
                margin: '1px 0',
                borderBottom: '1px solid #00000066'
            }} />;
};

SectionBreak.propTypes = {
    isHeader: PropTypes.bool,
};

SectionBreak.defaultProps = {
    isHeader: false,
};

const Label = props => {
    const {text} = props;
    const backgroundColor = getColor(text.toUpperCase());
    const fontFamily = "'FF Good Web Condensed', 'Oswald', 'sans-serif'";
    const style = {
        backgroundColor,
        fontFamily,
        minWidth: 'auto',
        textTransform: 'uppercase',
        display: 'inline-block',
        fontSize: '0.9rem',
        lineHeight: '1',
        padding: '0.4rem 0.5rem 0.25rem 0.5rem',
        color: 'white',
        fontWeight: 'bold',
        margin: '0 4px 2px 0',
        textAlign: 'center',
        letterSpacing: '0.05rem'
    };

    return text.length ? <div style={style}>{text.trim()}</div> : <div />;
}

const ABILITY_SU_PARENTHESIS = /^.*\(Su\)/ig;
const ABILITY_EX_PARENTHESIS = /^.*\(Ex\)/ig;
const ABILITY_EMPTY_PARENTHESIS = /^.*\(\)/ig;

const LEVEL_PARENTHESIS = /^[0-9]*.\(*.\)-$/ig;
const USES_TEXT = new RegExp('[0-9]+\/[a-zA-Z]+', 'ig');

const BOLD_MARKDOWN = /(\*\*|__)(.*?)\1/ig;
const ITALICS_MARKDOWN = /(\*|_)(.*?)\1/ig;

const BOLDED_TEXT = [
    ['Init', 'Senses', 'Perception', 'Aura'],
    ['AC', 'HP', 'Regeneration', 'Fort', 'Ref', 'Will', 'Defensive Abilities', 'SR', 'DR', 'Resist', 'Immune'],
    ['Speed', 'Melee', 'Ranged', 'Space', 'Reach', 'Special Attacks'],
    ['Spells Known', 'Spells Prepared', 'Spell-Like Abilities', 'Prohibited Schools', 'Constant', '0 (at will)-', '0-',
      'Cantrips (at will)-', 'Cantrips-', 'Orisons (at will)', 'Orisons', '1st-', '2nd-', '3rd-', '4th-', '5th-',
      '6th-', '7th-', '8th-', '9th-'],
    ['Str', 'Dex', 'Con', 'Int', 'Wis', 'Cha', 'Base Atk', 'CMB', 'CMD', 'Feats', 'Skills', 'Racial Modifiers', 'Languages',
      'Combat Gear', 'Other Gear', 'SQ'],
    ['Description']
];

const SECTION_BREAK = `<div style="background-color: #CCCCCC; height: 2px; margin: 1px 0; border-bottom: 1px solid #00000066;" />`;

const formatText = (text) => {
    const prepText = DOMPurify.sanitize(text, {ALLOWED_TAGS: ['b', 'i']});

    const lines = prepText.split('\n');
    let index = 0;
    let section = 0;
    let result = '';
    while(section < 5 && index < lines.length) {
        if (lines[index].indexOf('- ') === 0) {
            lines[index] = `<span style='padding-left: 8px'>• ${lines[index].substring(2)}</span>`;
        }

        if (section === 3) {
            lines[index] = lines[index].replaceAll(USES_TEXT, value => `<b>${value}</b>`);
            lines[index] = lines[index].replaceAll(LEVEL_PARENTHESIS, value => `<b>${value}</b>`);
        }

        if (lines[index] === '-') {
            result += SECTION_BREAK;
            section++;
        } else {
            BOLDED_TEXT[section].forEach(replacement => {
                lines[index] = lines[index].replaceAll(
                    replacement, `<b>${replacement}</b>`
                );
            });
            result += `<div style="text-indent: -6px; margin-left: 6px">${lines[index]}</div>`;
        }
        index++;
    }

    while (index < lines.length) {
        lines[index] = lines[index].replaceAll(ABILITY_SU_PARENTHESIS, value => `<b>${value}</b>`);
        lines[index] = lines[index].replaceAll(ABILITY_EX_PARENTHESIS, value => `<b>${value}</b>`);
        lines[index] = lines[index].replaceAll(ABILITY_EMPTY_PARENTHESIS, value => `<b>${value}</b>`);
        result += `${lines[index]}<br />`;
        index++;
    }

    result = result.replaceAll(BOLD_MARKDOWN, value => `<b>${value.substr(2, value.length - 4)}</b>`);
    result = result.replaceAll(ITALICS_MARKDOWN, value => `<i>${value.substr(1, value.length - 2)}</i>`);

    return result;
};

const BestiaryCard = props => {
    const {values, image: Image, showToolbar, card, user} = props;
    const [isFlipped, setIsFlipped] = useState(false);
    const {Name, Traits, Level, Content} = values;

    const fontFamily = "'FF Good Web Condensed', 'Oswald', 'sans-serif'";
    console.log(Traits);

    const traitsArray = Traits ? Traits.split(',') : null;

    const handleFlip = () => setIsFlipped(!isFlipped);

    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" containerStyle={{height: '100%'}}>
            <div style={{position: 'relative', paddingTop: '135%', height: '100%'}}>
                <div style={{position: 'absolute', height: '100%', width: '100%', left: 0, top: 0}}>
                    <Card sx={{
                        backgroundColor: '#F3F2EE',
                        color: '#212529',
                        width: '100%',
                        height: '100%',

                    }}>
                        <CardContent style={{height: '100%'}}>
                            <div style={{position: 'relative', height: '100%'}}>
                                <div style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    bottom: '0',
                                    right: '0',
                                    backgroundImage: `url(${Image})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                    opacity: '0.1',
                                    margin: '-24px'
                                }}/>
                                <div style={{position: 'relative', height: '100%'}}>
                                    <div style={{display: 'flex', fontFamily}}>
                                        <h2 style={{
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            margin: '0',
                                            textTransform: 'uppercase',
                                            color: '#212529',
                                            fontSize: '1.2rem',
                                            fontFamily
                                        }}>{Name}</h2>
                                        <h2 style={{
                                            minWidth: '81px',
                                            textAlign: 'right',
                                            margin: '0 0 0 auto',
                                            textTransform: 'uppercase',
                                            color: '#212529',
                                            fontSize: '1.2rem',
                                            fontFamily
                                        }}>
                                            Creature {Level}
                                        </h2>
                                    </div>
                                    <SectionBreak isHeader/>
                                    <div style={{height: '18px'}}>
                                        {traitsArray && traitsArray.map(trait => <Label text={trait}/>)}
                                    </div>
                                    <div style={{
                                        height: '63%',
                                        width: 'calc(100% - 10px)',
                                        lineHeight: '140%',
                                        fontSize: '1.2rem',
                                        fontFamily: 'FF Good Pro',
                                        letterSpacing: '-0.5px'
                                    }}>
                                        <div
                                            dangerouslySetInnerHTML={{__html: formatText(Content)}}
                                            style={{
                                                display: 'inline-block',
                                                height: 'calc(140.8% - 12px)',
                                                paddingRight: '8px',
                                                marginRight: '-12px',
                                                overflowY: 'auto',
                                                paddingTop: '4px',
                                                paddingBottom: '4px'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {showToolbar &&
                                <div style={{marginTop: '-16px'}}>
                                    <BestiaryCardToolbar card={card} user={user} onFlip={handleFlip}/>
                                </div>
                            }
                        </CardContent>
                    </Card>
                </div>
            </div>
            <div style={{position: 'relative', paddingTop: '135%', height: '100%'}}>
                <div style={{position: 'absolute', height: '100%', width: '100%', left: 0, top: 0}}>
                    <Card sx={{
                        backgroundColor: '#F3F2EE',
                        color: '#212529',
                        width: '100%',
                        height: '100%',

                    }}>
                        <CardContent style={{height: '100%'}}>
                            <div style={{position: 'relative', height: '100%'}}>
                                <div style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    bottom: '0',
                                    right: '0',
                                    backgroundImage: `url(${Image})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                    margin: '-24px',
                                    transform: 'scaleX(-1)',
                                    webkitTransform: 'scaleX(-1)'
                                }}/>
                                <div style={{position: 'relative', height: '100%'}}>
                                    <div style={{display: 'flex', fontFamily}}>
                                        <h2 style={{
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            margin: '0',
                                            textTransform: 'uppercase',
                                            color: '#212529',
                                            fontSize: '1.2rem',
                                            fontFamily
                                        }}>{Name}</h2>
                                        <h2 style={{
                                            minWidth: '81px',
                                            textAlign: 'right',
                                            margin: '0 0 0 auto',
                                            textTransform: 'uppercase',
                                            color: '#212529',
                                            fontSize: '1.2rem',
                                            fontFamily
                                        }}>
                                            Creature {Level}
                                        </h2>
                                    </div>
                                    <SectionBreak isHeader/>
                                    <div style={{height: '18px'}}>
                                        {traitsArray && traitsArray.map(trait => <Label text={trait}/>)}
                                    </div>
                                    <div style={{
                                        height: '63%',
                                        width: 'calc(100% - 10px)',
                                        lineHeight: '140%',
                                        fontSize: '1.2rem',
                                        fontFamily: 'FF Good Pro',
                                        letterSpacing: '-0.5px'
                                    }}>
                                        <div
                                            style={{
                                                display: 'inline-block',
                                                height: 'calc(140.8% - 12px)',
                                                paddingRight: '8px',
                                                marginRight: '-12px',
                                                overflowY: 'auto',
                                                paddingTop: '4px',
                                                paddingBottom: '4px'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {showToolbar &&
                                <div style={{marginTop: '-16px'}}>
                                    <BestiaryCardToolbar card={card} user={user} onFlip={handleFlip}/>
                                </div>
                            }
                        </CardContent>
                    </Card>
                </div>
            </div>
        </ReactCardFlip>
    );
};

BestiaryCard.propTypes = {
    values: PropTypes.object,
    image: PropTypes.string,
    showToolbar: PropTypes.bool,
};

BestiaryCard.defaultProps = {
    values: {},
    image: '',
    showToolBar: false,
};

export default BestiaryCard;