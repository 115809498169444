export const defaultSheetData = {
    version: "0.1.0",
    AbilityScores: {
        Strength: {
            sort: 0,
            prefix: "STR",
            enabled: true,
            modifiers: [
                {type: "Base", value: 10, source: "Base", sort: 0}
            ]
        },
        Dexterity: {
            sort: 1,
            prefix: "DEX",
            enabled: true,
            modifiers: [
                {type: "Base", value: 10, source: "Base", sort: 0}
            ]
        },
        Constitution: {
            sort: 2,
            prefix: "CON",
            enabled: true,
            modifiers: [
                {type: "Base", value: 10, source: "Base", sort: 0}
            ]
        },
        Intelligence: {
            sort: 3,
            prefix: "INT",
            enabled: true,
            modifiers: [
                {type: "Base", value: 10, source: "Base", sort: 0}
            ]
        },
        Wisdom: {
            sort: 4,
            prefix: "WIS",
            enabled: true,
            modifiers: [
                {type: "Base", value: 10, source: "Base", sort: 0}
            ]
        },
        Charisma: {
            sort: 5,
            prefix: "CHA",
            enabled: true,
            modifiers: [
                {type: "Base", value: 10, source: "Base", sort: 0}
            ]
        },
    },
    Vitals: {
        Background: {
            sort: 0,
            title: "Background",
            text: ""
        },
        Personality: {
            sort: 1,
            title: "Personality",
            text: ""
        },
        Goals: {
            sort: 2,
            title: "Goals",
            text: ""
        }
    }
}

export const getPathNode = (path, data) => {
    let result = data;
    path.forEach(index => {
        result = result[index];
    });
    return result;
}

export const updateSheetData = (sheetData, setSheetData, count, setCount, characterID) => (path, name, value) => {
    let location = sheetData;
    path.forEach(value => {
        location = location[value];
    });
    if (name === null) {
        location.splice(0);
        Object.keys(value).forEach(key => {
            location[key] = value[key];
            if (value[key] === null) {
                console.log("WE SAVED A NULL ONE!");
            }
        });
    } else {
        location[name] = value;
    }
    sheetData.lastUpdatedDate += 0.0001;
    setSheetData(sheetData);
    setCount(count + 1);
}