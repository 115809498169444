import InfoCardContainer, {INFOCARD_TYPES} from "../InfoCard/InfoCardContainer";
import ItemCardEditor from "./ItemCardEditor";
import InventoryCard from "./InventoryCard";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";

const InventoryEditContainer = ({creatorID, item, onSuccess, onClose}) => {
    const history = useHistory();
    const {id: itemID} = item;
    /*const {data, loading, refetch} = useQuery(GET_BESTIARY_CARD, {
        variables: {
            itemID
        },
        fetchPolicy: 'cache-and-network'
    });*/
    const metadata = item && item.Metadata ? JSON.parse(item.Metadata) : {};

    return itemID ? (
        <InfoCardContainer
            id={itemID}
            editorProps={{}}
            defaultValues={{
                ...metadata,
                MarketCost: item.MarketCost,
                CraftCost: item.CraftCost,
                Type: item.Type,
                Content: item.Content
            }}
            cardInputs={ItemCardEditor}
            cardPreview={InventoryCard}
            type={INFOCARD_TYPES.ITEM}
            onSuccess={onSuccess}
            onClose={onClose}
        />
    ) : (
        <InfoCardContainer
            editorProps={{CreatorID: creatorID}}
            defaultValues={{}}
            cardInputs={ItemCardEditor}
            cardPreview={InventoryCard}
            type={INFOCARD_TYPES.ITEM}
            onSuccess={onSuccess}
            onClose={onClose}
        />
    );
};

InventoryEditContainer.propTypes = {
    item: PropTypes.object,
};

InventoryEditContainer.defaultProps = {
    item: {},
};

export default InventoryEditContainer;