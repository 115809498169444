import React, {useMemo, useState} from "react";
import Phaser from 'phaser';
import {Button, Header, Menu} from "semantic-ui-react";
import {GameComponent, useEventListener} from "phaser-react-tools";
import WorldMap from "./Phaser/scenes/WorldMap";
import {EVENT} from "./MapEvents";
import MapUI from "./MapUI";
import {useQuery} from "@apollo/client";
import {GET_CURRENT_USER, GET_MAP} from "../../../graphql/Queries";

const config = {
    type: Phaser.CANVAS,
    width: 1125,
    height: '75%',
    physics: {
        default: 'arcade',
        arcade: {
            gravity: { y: 200 }
        }
    },
    scene: []
};

const MapPage = () => {
    const {data: userData} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'cache-first'});
    const user = userData && userData.CurrentUser ? userData.CurrentUser : null;
    const {data: mapData, loading} = useQuery(GET_MAP, {fetchPolicy: 'cache-first'});
    const mapJSON = useMemo( () => {
        if (mapData && mapData.Map && mapData.Map.Tiles) {
            return JSON.parse(mapData.Map.Tiles);
        } else {
            return {};
        }
    }, [mapData]);
    const iconJSON = useMemo( () => {
        if (mapData && mapData.Map && mapData.Map.Icons) {
            return JSON.parse(mapData.Map.Icons);
        } else {
            return {};
        }
    }, [mapData]);
    const map = useMemo(() => {
        if (mapData && mapData.Map && mapData.Map.Tiles && user) {
            return new WorldMap(user, mapJSON, iconJSON);
        } else {
            return null;
        }
    }, [mapData, mapJSON, iconJSON, user]);
    const gameConfig = useMemo(() => {
        if (map) {
            return {...config, scene: [map]};
        } else {
            return null;
        }
    }, [map]);

    return (
        <>
            <Header as="h2">Primordia Map</Header>
            {loading && <p>Loading Map, Please Wait...</p>}
            {!mapData && !loading && <p>Map Data Load Failure!</p>}
            {gameConfig &&
                <GameComponent config={gameConfig} test={{test: 5}}>
                    <MapUI mapData={mapJSON} iconData={iconJSON} />
                </GameComponent>
            }
        </>
    );
};

export default MapPage;