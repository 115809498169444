export const SLOTS = {
    1: 'Armor',
    2: 'Weapon (Left)',
    3: 'Weapon (Right)',
    4: 'Belt',
    5: 'Body',
    6: 'Chest',
    7: 'Eyes',
    8: 'Feet',
    9: 'Hands',
    10: 'Head',
    11: 'Headband',
    12: 'Neck',
    13: 'Ring (Left)',
    14: 'Ring (Right)',
    15: 'Shield',
    16: 'Shoulders',
    17: 'Wrists',
    18: 'Slotless',
    19: 'None',
};

export const ITEM_TYPE = {
    0: 'Other',
    1: 'Armor',
    2: 'Weapon',
    3: 'Ring',
    4: 'Staff',
    5: 'Rod',
    6: 'Potion',
    7: 'Scroll',
    8: 'Wand',
    9: 'Wondrous Item',
    10: 'Technological Item',
    11: 'Implement',
    12: 'Alchemy',
    13: 'Apparatus',
    14: 'Charm',
    15: 'Compound',
    16: 'Fabled Item',
    17: 'Marvelous Item',
    18: 'Radiance',
    19: 'Schematic',
    20: 'Spell Engine',
    21: 'Spellzone',
    22: 'Summoning Orb',
    23: 'Talent Crystal',
    24: 'Power Stone',
    25: 'Psicrown',
    26: 'Psionic Tattoo',
    27: 'Dorje',
    28: 'Artifact',
};