import React, {Fragment, useContext} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, Header, Popup} from "semantic-ui-react";
import EditableTitle from "../EditableTitle";
import Feature from "../Feature";
import {SheetContext} from "../SheetContext";

const MultipleChoiceFeatures = props => {
    const {features, classIndex, isEditable, paddingWidth} = props;
    const mainColumnWidth = 16 - paddingWidth;
    const {sheetData, updateSheetData, mode} = useContext(SheetContext);

    const handleChange = featureIndex => (e, {name, value}) => {
        const location = sheetData.Classes[classIndex].multilevel[featureIndex];
        if (name === 'levels') {
            if (value.length > location.levels.length) {
                const newLevel = value.find(level => !location.levels.includes(level));
                location.selections.push({
                    level: newLevel,
                    choice: {name: '', deletable: false, description: ''}
                })
            } else {
                const deletedLevel = location.levels.find(level => !value.includes(level));
                location.selections = location.selections.filter(selection => selection.level !== deletedLevel);
            }
        }
        location[name] = value;
        updateSheetData(['Classes', classIndex, 'multilevel'], featureIndex, location);
    }

    const handleClickDeleteMultilevel = deleteIndex => () => {
        const multilevels = sheetData.Classes[classIndex].multilevel;
        const newMultilevels = multilevels.filter((feature, featureIndex) => featureIndex !== deleteIndex);
        updateSheetData(['Classes', classIndex, 'multilevel'], null, newMultilevels);
    }

    const handleCLickAddMultilevel = () => {
        const classFeatures = sheetData.Classes[classIndex].multilevel;
        classFeatures.push({name: '', replacement: '', deletable: true, description: '', levels: [], selections: []});
        updateSheetData(['Classes', classIndex], 'multilevel', classFeatures);
    }

    const Tag = isEditable ? Fragment : 'div';

    const renderNode = ({name, replacement, description}) => () => {
        const descriptionReplace = description && description.replaceAll('\n\n', '\n');
        const paragraphs = descriptionReplace ? descriptionReplace.split('\n') : ['Not Provided'];
        return (
            <>
                <p>
                <span style={{fontSize: '18px'}}>
                    <b>{`${name}${replacement ? ` (replaces ${replacement})` : ''}: `}</b>
                </span>
                    {paragraphs[0]}
                </p>
                {paragraphs.map((paragraph, index) => index > 0
                    ? (
                        <p>
                            {paragraph}
                        </p>
                    )
                    : (<span/>)
                )}
            </>
        )
    }

    return (
        <>
            <Grid.Column width={16}>
                <h2>
                <Popup
                    trigger={<span>Multi-Level Features / Choices</span>}
                    content="Features that extend multiple levels with choices, such as Bonus Feats, Magus Arcana, Rogue Talents, etc."
                    position='top center'
                />
                </h2>
            </Grid.Column>
            <Tag>
                {features.map((feature, index) => (
                    <Feature
                        className='level'
                        paddingWidth={2}
                        isEditable={isEditable}
                        isNameEditable
                        isReplacementEditable
                        hasLevelSelector
                        index={index}
                        data={feature}
                        onChange={handleChange(index)}
                        onClickDelete={handleClickDeleteMultilevel(index)}
                        renderNode={renderNode(feature)}
                    />
                ))}
            </Tag>
            {isEditable &&
            <Grid.Column width={16}>
                <Button style={{width: '140px'}} positive onClick={handleCLickAddMultilevel}>Add Feature</Button>
            </Grid.Column>
            }
        </>
    )
};

MultipleChoiceFeatures.propTypes = {
    features: PropTypes.array,
    classIndex: PropTypes.number,
    headingSize: PropTypes.string,
    paddingWidth: PropTypes.number,
    isEditable: PropTypes.bool,
};

MultipleChoiceFeatures.defaultProps = {
    level: 0,
    features: [],
    headingSize: 'h3',
    paddingWidth: 0,
    isEditable: false,
};

export default MultipleChoiceFeatures;