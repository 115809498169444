import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Button, Card, Icon, Modal, Placeholder, PlaceholderParagraph, PlaceholderLine, Label} from 'semantic-ui-react';
import {useMutation} from '@apollo/client';
import {DELETE_CHARACTER} from "../../../graphql/Mutations";
import {getMythic} from "../../../utilities/CharacterUtils";
import {isMobile, isTablet} from "react-device-detect";

const CharacterCard = props => {
    const history = useHistory();
    const {
        character,
        loading,
        hideStatus,
        onClick,
        onClickDelete
    } = props;

    const {Status, Approver, Avatar: AvatarURL, User, Name, Race, Level, Description, CharacterID, Type} = character;
    const [hovering, setHovering] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteCharacter] = useMutation(DELETE_CHARACTER, {
        onCompleted() {
            props.refetchCharacters();
            setIsDeleting(false);
            setDeleteModalOpen(false);
        },
        onError() {
            alert("Character Delete Failed");
            setIsDeleting(false);
            setDeleteModalOpen(false);
        }
    });

    const mythic = getMythic(Level);

    const handleClick = () => {
        if (onClick) {
            onClick();
        } else {
            history.push(`/characters/character/${CharacterID}/log`);
        }
    }

    const handleClickDelete = () => {
        if (onClickDelete) {
            onClickDelete(character);
        } else {
            setDeleteModalOpen(true);
        }
    }

    const handleConfirmDelete = () => {
        setIsDeleting(true);
        deleteCharacter({variables: {characterID: CharacterID}});
    }

    const owner = character && character.ParentCharacter
        ? `of ${character.ParentCharacter.Name}`
        : '';

    const alwaysShowDelete = isMobile || isTablet;

    return (
        <>
            <div style={{position: 'relative'}} onMouseEnter={() => setHovering(true)}
                 onMouseLeave={() => setHovering(false)}>
                <Card style={{maxWidth: '400px', margin: '8px', height: '358px'}}
                      onClick={handleClick}>
                    {loading
                        ? (<Placeholder style={{
                            height: '200px',
                            width: '200px',
                            margin: '12px auto'
                        }}><Placeholder.Image/></Placeholder>)
                        : (
                            <div style={{display: 'flex', margin: 'auto'}}>
                                <img
                                    src={AvatarURL || '/blank_profile_image.jpg'}
                                    alt='Profile Image'
                                    style={{
                                        textAlign: 'center',
                                        marginTop: '12px',
                                        height: '200px',
                                        width: '200px',
                                        'objectFit': 'cover',
                                        backgroundColor: '#E6E6E6'
                                    }}
                                />
                                {Status === 2 && !hideStatus &&
                                <span style={{position: 'absolute', top: '184px', right: '46px'}}>
                                        <Icon title="Pending Approval" circular color='grey' name='question'
                                              size='small'/>
                                    </span>
                                }
                                {Status === 3 && !hideStatus &&
                                <span style={{position: 'absolute', top: '184px', right: '46px'}}>
                                        <Icon
                                            title={`Approved Character${Approver !== null && ` (Approved By ${Approver.Name})`}`}
                                            circular color='green' name='check' size='small'/>
                                    </span>
                                }
                            </div>
                        )
                    }
                    <Card.Content>
                        {loading
                            ? (
                                <Placeholder>
                                    <PlaceholderLine/>
                                    <PlaceholderLine/>
                                    <PlaceholderLine/>
                                </Placeholder>
                            ) : (
                                <>
                                    <Card.Header>
                                        <span style={{textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            display: 'block'}}
                                        >
                                            {Name}
                                        </span>
                                    </Card.Header>
                                    <Card.Meta>
                                        <span>{Race}</span>
                                        <br/>
                                        <span style={{
                                            display: '-webkit-box',
                                            webkitLineClamp: '3',
                                            webkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            height: '57px'
                                        }}>{Description}</span>
                                    </Card.Meta>
                                    <Card.Description>
                                        <p>
                                            {Type !== 3 &&
                                                <>
                                                    <b>Level</b> {Level || 2}
                                                </>
                                            }
                                            {Type === 1 &&
                                                <span style={{paddingLeft: '12px'}}>
                                                    {mythic > 0 && <><b>Mythic</b> {mythic || 0}</>}
                                                </span>
                                            }
                                            <span style={{
                                                float: 'right',
                                                textAlign: 'right',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                width: '130px'
                                            }}>
                                                {User && User.Name}
                                            </span>
                                        </p>
                                    </Card.Description>
                                </>
                            )
                        }
                    </Card.Content>
                </Card>
                <span style={{position: 'absolute', top: '20px', left: '22px', zIndex: '1000'}}>
                    {character.Type === 2 &&
                        <Label color='blue' ribbon>
                            Cohort {owner}
                        </Label>
                    }
                    {character.Type === 3 &&
                        <Label color='teal' ribbon>
                            {character.TypeName} {owner}
                        </Label>
                    }
                </span>
                <Button
                    circular
                    negative
                    icon='trash alternate'
                    onClick={handleClickDelete}
                    style={{
                        position: 'absolute',
                        zIndex: '1000',
                        top: '12px',
                        right: '12px',
                        visibility: ((hovering || alwaysShowDelete) && props.showDelete) ? 'visible' : 'hidden'
                    }}
                />
            </div>
            <Modal
                size='mini'
                open={deleteModalOpen}
            >
                <Modal.Header>
                    Delete Character
                </Modal.Header>
                <Modal.Content>
                    Are you sure you want to delete {props.name}?
                </Modal.Content>
                <Modal.Actions>
                    <Button negative disabled={isDeleting} loading={isDeleting} onClick={handleConfirmDelete}>
                        Yes
                    </Button>
                    <Button
                        content="Cancel"
                        labelPosition='right'
                        onClick={() => setDeleteModalOpen(false)}
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};

CharacterCard.propTypes = {
    character: PropTypes.object,
    loading: PropTypes.bool,
    showDelete: PropTypes.bool,
    refetchCharacters: PropTypes.func,
    hideStatus: PropTypes.bool,
    onClick: PropTypes.func,
    onClickDelete: PropTypes.func,
};

CharacterCard.defaultProps = {
    character: {},
    loading: false,
    refetchCharacters() {},
    showDelete: false,
    hideStatus: false,
    onClick: null,
    onClickDelete: null,
};

export default CharacterCard;
