import React from 'react';
import {Route, Switch} from "react-router-dom";
import LandingPage from "../../components/Pages/LandingPage";
import * as ROUTES from "../../constants/routes";
import CharacterPage from "../../components/Pages/Character/CharacterPage";
import CharacterCardPage from "../../components/Pages/Character/CharacterCardPage";
import PlayerPage from "../../components/Pages/Player/PlayerPage";
import PlayerListPage from "../../components/Pages/Player/PlayerListPage";
import ApplicationPage from "../../components/Pages/Application/ApplicationPage";
import SessionPage from "../../components/Pages/Session/SessionPage";
import SessionListPage from "../../components/Pages/Session/SessionListPage";
import SignUpPage from "../Pages/Authentication/SignUpPage";
import SignInPage from "../Pages/Authentication/SignInPage";
import PasswordForgetPage from "../Pages/Authentication/PasswordForgetPage";
import HomePage from "../../components/Pages/HomePage";
import AccountPage from "../../components/Pages/AccountPage";
import AdminPage from "../../components/Pages/AdminPage";
import ArticleHomePage from "../../components/Pages/Article/ArticleListPage";
import {hasAtLeastPermission, hasPermission, PERMISSIONS} from "../../utilities/PermissionUtils";
import MapPage from "../../components/Pages/Map/MapPage";
import ArticleEditPageWrapper from "../../components/Pages/Article/ArticleEditPageWrapper";
import ArticlePage from "../../components/Pages/Article/ArticlePage";
import ArticleEditorPage from "../../components/Pages/Article/ArticleEditorPage";
import ArticleLinkPage from "../../components/Pages/Article/ArticleLinkPage";
import BestiaryList from "../Pages/Bestiary/BestiaryList";
import BestiaryCreatePage from "../Pages/Bestiary/BestiaryCreatePage";
import BestiaryEditPage from "../Pages/Bestiary/BestiaryEditPage";
import {CHARACTER_SUBSECTION_INDEX} from "../../constants/routes";

const LoggedInRoutes = user => {
    const permissions = user && user.user && user.user.Permissions ? user.user.Permissions : 1;
    console.log(permissions, PERMISSIONS.ARCHIVIST, hasAtLeastPermission(permissions, PERMISSIONS.ARCHIVIST));
    return (
        <Switch>
            <Route exact path={ROUTES.LANDING} component={LandingPage} />
            {hasPermission(permissions, PERMISSIONS.ADMIN) &&
                <Route path={ROUTES.ADMIN} component={AdminPage}/>
            }
            {hasAtLeastPermission(permissions, PERMISSIONS.ARCHIVIST) &&
                <Route path={ROUTES.BESTIARY_CREATE} component={BestiaryEditPage}/>
            }
            {hasAtLeastPermission(permissions, PERMISSIONS.ARCHIVIST) &&
                <Route exact path={ROUTES.BESTIARY} component={BestiaryList} />
            }
            <Route exact path={ROUTES.CHARACTER_SUBSECTION_INDEX} component={CharacterPage} />
            <Route exact path={ROUTES.CHARACTER_SUBSECTION} component={CharacterPage} />
            <Route exact path={ROUTES.CHARACTER} component={CharacterPage} />
            <Route exact path={ROUTES.CHARACTERS} component={CharacterCardPage}/>
            <Route path={ROUTES.EMPTY_CAHARACTER} component={CharacterPage} />
            <Route path={ROUTES.PLAYER_PAGE} component={PlayerPage} />
            <Route path={ROUTES.PLAYERS}>
                <PlayerListPage />
            </Route>
            <Route path={ROUTES.APPLICATIONS_PAGE} component={ApplicationPage} />
            <Route path={ROUTES.SESSION_PAGE} component={SessionPage} />
            <Route path={ROUTES.SESSION_LIST_PAGE} component={SessionListPage} />
            <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route
                path={ROUTES.PASSWORD_FORGET}
                component={PasswordForgetPage}
            />
            <Route path={ROUTES.HOME} component={HomePage} />
            <Route path={ROUTES.ACCOUNT} component={AccountPage} />
            <Route path={ROUTES.ARTICLE_LINK_PAGE} component={ArticleLinkPage} />
            <Route path={ROUTES.ARTICLE_VIEW_PAGE} component={ArticlePage} />
            <Route path={ROUTES.ARTICLE_EDIT} component={ArticleEditPageWrapper} />
            <Route path={ROUTES.ARTICLE_CREATE} component={ArticleEditorPage} />
            <Route path={ROUTES.ARTICLES} component={ArticleHomePage} />
            <Route path={ROUTES.MAP} component={MapPage} />
            <Route path="*" component={LandingPage} />
        </Switch>
    )
};

export default LoggedInRoutes;