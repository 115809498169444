import React from 'react';
import {useQuery} from "@apollo/client";
import {GET_CURRENT_USER} from "../../graphql/Queries";
import {Divider, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import PetsIcon from '@mui/icons-material/Pets';
import * as ROUTES from "../../constants/routes";
import {useHistory} from "react-router-dom";
import {hasAtLeastPermission, PERMISSIONS} from "../../utilities/PermissionUtils";

const SiteMenu = ({onClose}) => {
    const {data} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'cache-and-network'});
    const user = data && data.CurrentUser ? data.CurrentUser : {};
    const history = useHistory();

    const sessionURL = ROUTES.SESSION_LIST_PAGE.replace(':tab', 'active');

    const reroute = path => () => {
        onClose();
        history.push(path);
    }

    return (
        <List>
            {user.Permissions &&
                <>
                    <ListItem button key='home' onClick={reroute(ROUTES.HOME)}>
                        <ListItemIcon>
                            <HomeIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Home"/>
                    </ListItem>
                    <Divider/>
                    <ListItem button key='players' onClick={reroute(ROUTES.PLAYERS)}>
                        <ListItemIcon>
                            <PeopleAltIcon/>
                        </ListItemIcon>
                        <ListItemText primary='Players'/>
                    </ListItem>
                    <ListItem button key='logout' onClick={reroute(ROUTES.USER_CHARACTERS)}>
                        <ListItemIcon>
                            <AssignmentIndIcon/>
                        </ListItemIcon>
                        <ListItemText primary='Characters'/>
                    </ListItem>
                    <Divider/>
                    <ListItem button key='sessions' onClick={reroute(sessionURL)}>
                        <ListItemIcon>
                            <FlashOnIcon/>
                        </ListItemIcon>
                        <ListItemText primary='Sessions'/>
                    </ListItem>
                    <Divider/>
                    <ListItem button key='knowledgebase' onClick={reroute(ROUTES.ARTICLES)}>
                        <ListItemIcon>
                            <AutoStoriesIcon/>
                        </ListItemIcon>
                        <ListItemText primary='Akashic Records'/>
                    </ListItem>
                    {hasAtLeastPermission(user.Permissions || 0, PERMISSIONS.ARCHIVIST) &&
                        <ListItem button key='map' onClick={reroute(ROUTES.MAP)} sx={{pl: 4}}>
                            <ListItemIcon>
                                <MapTwoToneIcon/>
                            </ListItemIcon>
                            <ListItemText primary='Map'/>
                        </ListItem>
                    }
                    {hasAtLeastPermission(user.Permissions || 0, PERMISSIONS.ARCHIVIST) &&
                        <ListItem button key='bestiary' onClick={reroute(ROUTES.BESTIARY)} sx={{pl: 4}}>
                            <ListItemIcon>
                                <PetsIcon />
                            </ListItemIcon>
                            <ListItemText primary='Bestiary' />
                        </ListItem>
                    }
                </>
            }
        </List>
    )
};

export default SiteMenu;