import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, Input, Modal, Table} from "semantic-ui-react";
import QuillTextEditor from "../../../CustomComponents/Quill/QuillTextEditor";

const TextAreaModal = props => {
    const {isOpen, closeModal, title, onSave, defaultValue} = props;

    const [editable, setEditable] = useState(false);
    const [quill, setQuill] = useState(null);

    const handleClickSave = () => {
        const content = quill.getText();
        onSave(content);
    };

    return (
        <Modal size='small' open={isOpen}>
            <Modal.Content>
                <div style={{maxHeight: '500px', overflow: 'hidden'}}>
                    <QuillTextEditor
                        setQuill={setQuill}
                        defaultValue={defaultValue}
                        placeholder='Start typing here...'
                        maxHeight='500px'
                        type='minimal'
                        name={title}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={closeModal}>
                    Cancel
                </Button>
                <Button positive onClick={handleClickSave}>
                    Set
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

TextAreaModal.propTypes = {
    defaultValue: PropTypes.string,
    isOpen: PropTypes.array,
    closeModal: PropTypes.string,
    onSave: PropTypes.func,
    title: PropTypes.string,
};

export default TextAreaModal;