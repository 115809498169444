import React, {useEffect, useState} from 'react';
import {useQuill} from 'react-quilljs';
import {Button} from 'semantic-ui-react';

const ApplicationPage = () => {
    const [model, setModel] = useState("");
    const [contents, setContents] = useState(null);
    const theme = 'snow';
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{align: []}],

            [{list: 'ordered'}, {list: 'bullet'}],
            [{indent: '-1'}, {indent: '+1'}],

            [{size: ['small', false, 'large', 'huge']}],
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            ['link', 'image'],
            [{color: []}, {background: []}],

            ['clean'],
        ],
        clipboard: {
            matchVisual: false,
        }
    };
    // const theme = 'bubble';

    const placeholder = 'Compose an epic...';

    const formats = [
        'bold', 'italic', 'underline', 'strike',
        'align', 'list', 'indent',
        'size', 'header',
        'link', 'image', 'video',
        'color', 'background',
        'clean',
    ];
    const { quill, quillRef } = useQuill({ theme, modules, formats, placeholder });

    useEffect(() => {
        if (typeof quill !== 'undefined' && contents === null) {
            setContents(true);
            const storedContents = JSON.parse(localStorage.getItem('quill'));
            if (storedContents) {
                quill.setContents(storedContents);
            }
        }
    }, [quill, contents])

    const onClickSaveButton = () => {
        const contents = quill.getContents();
        localStorage.setItem('quill', JSON.stringify(contents));
    }

    return (
        <div style={{border: '1px solid lightgray'}}>
            <div ref={quillRef}/>
            <Button onClick={onClickSaveButton}>Save</Button>
        </div>
    );
};

export default ApplicationPage;
