import React, {Fragment, useContext} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, Header, Input} from "semantic-ui-react";
import EditableTitle from "../EditableTitle";
import Feature from "../Feature";
import {SheetContext} from "../SheetContext";

const ClassLevelChoice = props => {
    const {level, multilevel, classIndex, isEditable, paddingWidth} = props;
    const mainColumnWidth = 16 - paddingWidth;
    const {sheetData, updateSheetData, mode} = useContext(SheetContext);

    const getChoices = () => {
        const choices = [];

        for (let multilevelIndex = 0; multilevelIndex < multilevel.length; multilevelIndex++) {
            const item = multilevel[multilevelIndex];
            for (let selectionIndex = 0; selectionIndex < item.selections.length; selectionIndex++) {
                if (item.selections[selectionIndex].level === level) {
                    choices.push({
                        name: multilevel[multilevelIndex].name,
                        multilevelIndex,
                        selectionIndex,
                        selection: item.selections[selectionIndex].choice
                    });
                    break;
                }
            }
        }

        return choices;
    }

    const handleChange = multilevelIndex => featureIndex => ({target:{name, value}}) => {
        const location = sheetData.Classes[classIndex].multilevel[multilevelIndex].selections[featureIndex];
        location.choice[name] = value;
        updateSheetData(['Classes', classIndex, 'multilevel', multilevelIndex, 'selections'], featureIndex, location);
    }

    const Tag = isEditable ? Fragment : 'div';

    const renderNode = ({name, description, title}) => () => {
        return (
            <p>
                <span style={{fontSize: '16px'}}>
                    <b>{`[${title}] ${name}: `}</b>
                </span> {`${description || 'Not Provided'}`}
            </p>
        )
    }

    const getRenderNodeData = (choice) => {
        return {
            title: choice.name,
            ...choice.selection
        }
    }

    return (
        <Tag>
            {getChoices().map((choice) => (
                <>
                    <Feature
                        className='level'
                        isNameEditable
                        isReplacementEditable
                        title={choice.name}
                        paddingWidth={2}
                        isEditable={isEditable}
                        data={choice.selection}
                        onChange={handleChange(choice.multilevelIndex)(choice.selectionIndex)}
                        renderNode={renderNode(getRenderNodeData(choice))}
                    />
                    <Grid.Column width={16}>
                        <div>
                            <hr />
                        </div>
                    </Grid.Column>
                </>
            ))}
        </Tag>
    )
};

ClassLevelChoice.propTypes = {
    level: PropTypes.number,
    multilevel: PropTypes.array,
    classIndex: PropTypes.number,
    headingSize: PropTypes.string,
    paddingWidth: PropTypes.number,
    isEditable: PropTypes.bool,
};

ClassLevelChoice.defaultProps = {
    level: 0,
    headingSize: 'h3',
    paddingWidth: 0,
    isEditable: false,
};

export default ClassLevelChoice;