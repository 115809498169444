import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {useMutation} from '@apollo/client';
import {Button, Form, Icon} from 'semantic-ui-react';
import {PERMISSIONS} from "../../../utilities/PermissionUtils";
import TemplateField from "../../CustomComponents/Application/TemplateField";
import {CREATE_TEMPLATE} from "../../../graphql/Mutations";

const permissionOptions = [
    {key: PERMISSIONS.ADMIN, text: "Admin", value: PERMISSIONS.ADMIN, color: 'yellow'},
    {key: PERMISSIONS.MODERATOR, text: "Moderator", value: PERMISSIONS.MODERATOR, color: 'green'},
    {key: PERMISSIONS.GM, text: "Game Master", value: PERMISSIONS.GM, color: 'blue'},
    {key: PERMISSIONS.TRIAL_GM, text: "Trial GM", value: PERMISSIONS.TRIAL_GM, color: 'blue'},
    {key: PERMISSIONS.ARCHIVIST, text: "Archivist", value: PERMISSIONS.ARCHIVIST, color: 'teal'},
    {key: PERMISSIONS.PLAYER, text: "Player", value: PERMISSIONS.PLAYER, color: 'purple'},
];

const ApplicationTemplateCreatePage = props => {
    const [values, setValues] = useState(props.values);
    const [fields, setFields] = useState(props.fields);
    const addFieldButton = useRef(null);

    const [createTemplate] = useMutation(CREATE_TEMPLATE, {
        onCompleted() {
        }
    });

    const handleChange = (e, {name, value}) => {
        if (name === 'Voters') {
            const sortedRoles = [...value].sort((a, b) => b - a);
            setValues({...values, [name]: sortedRoles});
            return;
        }
        setValues({...values, [name]: value});
    };

    const setField = (index, field) => {
        const fieldList = [...fields];
        fieldList[index] = field;
        setFields(fieldList);
    }

    const addField = () => {
        setFields([...fields, {fieldName: '', type: null, values: null}]);
        addFieldButton.current.focus();
    }

    const deleteField = (index) => {
        setFields(fields.filter((value, fieldIndex) => fieldIndex !== index));
    }

    const permissionsRenderLabel = (label) => ({
        color: label.color,
        content: label.text
    });

    const onSubmit = () => {
        if (fields.length === 0) {
            return;
        }

        if (props.templateID === null) {
            createTemplate({
                variables: {
                    input: {
                        Name: values.Name,
                        Voters: values.Voters.reduce( (acc, val) => acc + val, 0),
                        Data: JSON.stringify(fields)
                    }
                }
            });
        }

    };

    const onCancel = () => {
        setValues({Level: 2, Mythic: 0});
    };

    return (
        <>
            <h2>{props.templateID ? 'Update' : 'Create'} Template</h2>
            <Form>
                <Form.Group>
                    <Form.Input required width={6} name="Name" placeholder="Template Name" value={values.Name}
                                onChange={handleChange}/>
                    <Form.Dropdown
                        multiple
                        selection
                        fluid
                        required
                        width={8}
                        name="Voters"
                        options={permissionOptions}
                        placeholder="Vote Roles"
                        renderLabel={permissionsRenderLabel}
                        onChange={handleChange}
                        value={values.Voters}
                    />
                </Form.Group>
                {fields.map((field, index) => (
                    <TemplateField key={index} field={field} index={index} onChange={setField} onDelete={deleteField}/>
                ))}
                <Form.Group>
                    <Button icon onClick={addField} ref={addFieldButton} style={{marginLeft: '0.5em'}}>
                        <Icon name='plus'/>
                    </Button>
                </Form.Group>
                <Form.Group>
                    <Form.Button positive onClick={onSubmit}>
                        Submit
                    </Form.Button>
                    <Form.Button>
                        Cancel
                    </Form.Button>
                </Form.Group>
            </Form>
        </>
    )
}

ApplicationTemplateCreatePage.propTypes = {
    values: PropTypes.object,
    fields: PropTypes.array,
    templateID: PropTypes.number,
};

ApplicationTemplateCreatePage.defaultProps = {
    values: {Name: '', Voters: null},
    fields: [{fieldName: '', type: null, values: null}],
    templateID: null,
};

export default ApplicationTemplateCreatePage;