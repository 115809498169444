import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Header, Table} from 'semantic-ui-react';

const PlayerLogTable = (props) => {
    const {logs} = props;

    const getType = type => {
        switch (type) {
            case 1:
                return 'Session';
            case 50:
                return 'Character Approval';
            case 100:
                return 'Daily Claim';
            case 200:
                return 'Spend Shards';
            case 1000:
                return 'Reward (All Players)';
            case 1001:
                return 'Modify Shards (GM)';
            default:
                return '';
        }
    }

    const getMetadataText = (type, metadata) => {
        try {
            JSON.parse(metadata);
        } catch {
            return '';
        }

        const data = JSON.parse(metadata);

        switch (type) {
            case 1:
                return `Runtime (h): ${data.Runtime / 60}`;
            case 100:
                return `Claim Time: ${data.claimDays} Day${data.claimDays !== 1 ? 's' : ''}`;
            case 200:
            case 1001:
                return `Reason: ${data.reason}`;
            default:
                return '';
        }
    }

    const getTime = timestamp => {
        const date = new Date();
        date.setTime(timestamp * 1000);
        return date.toLocaleString();
    }

    const addSpace = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    const getSummary = () => {
        let shards = 0;

        logs.forEach(log => {
            shards += log.PrimordiaShards;
        });

        let summary = `Log Summary - Shards: ${addSpace(shards)}`;

        return summary;
    }

    const [column, setColumn] = useState('DateUpdated');
    const [direction, setDirection] = useState('descending');

    const setSort = (sortColumn) => () => {
        if (column === sortColumn) {
            setDirection(direction === 'ascending' ? 'descending' : 'ascending');
        } else {
            setDirection('descending');
            setColumn(sortColumn);
        }
    }

    const getSortedData = () => {
        if (column === null) return logs;
        switch (column) {
            case 'Type':
                const typeSortLogs = [...logs];
                return typeSortLogs.sort( (a, b) => {
                    return direction === 'ascending'
                        ? getType(a.Type).localeCompare(getType(b.Type))
                        : getType(b.Type).localeCompare(getType(a.Type));
                });
            case 'PrimordiaShards':
            case 'DateUpdated':
                const dateShardSortLogs = [...logs];
                return dateShardSortLogs.sort( (a, b) => {
                    return direction === 'ascending'
                        ? a[column] - b[column]
                        : b[column] - a[column];
                });
            default:
                return logs;
        }
    }

    return (
        <>
            <Header>Player Log</Header>
            <br />
            {getSummary()}
            <br />
            <Table striped sortable compact='very'>
                <Table.Header>
                    <Table.HeaderCell
                        sorted={column === 'Type' ? direction : null}
                        onClick={setSort('Type')}
                    >
                        Type
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'Mana' ? direction : null}
                        onClick={setSort('PrimordiaShards')}
                    >
                        Primordia Shards
                    </Table.HeaderCell>
                    <Table.HeaderCell>Data</Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'DateUpdated' ? direction : null}
                        onClick={setSort('DateUpdated')}
                    >
                        Date
                    </Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {getSortedData().map(log =>
                        <Table.Row>
                            <Table.Cell>{getType(log.Type)}</Table.Cell>
                            <Table.Cell>{addSpace(log.PrimordiaShards)}</Table.Cell>
                            <Table.Cell>{getMetadataText(log.Type, log.Metadata)}</Table.Cell>
                            <Table.Cell>{getTime(log.DateUpdated)}</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </>
    );
};

PlayerLogTable.propTypes = {
    logs: PropTypes.array,
};

PlayerLogTable.defaultProps = {
    logs: [],
};

export default PlayerLogTable;
