import React, {useState, useEffect} from 'react';
import {useQuery, useMutation} from '@apollo/client';

import {Button, Card, Form, Image, Message} from 'semantic-ui-react';
import {GET_CURRENT_USER} from "../../../graphql/Queries";
import {SAVE_USER} from "../../../graphql/Mutations";
import {compose} from "recompose";
import {withAuthorization, withEmailVerification} from "../../Features/Session";

const TWO_MEGABYTES_IN_BYTES = 2000000;

const options = [
    { key: '1', text: 'Light', value: 1 },
    { key: '2', text: 'Dark', value: 2 },
];

const PlayerUpdatePage = () => {
    const {data: user, loading} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'cache-and-network'});
    const [saveUser] = useMutation(SAVE_USER, {
        onCompleted () {
            setModified(false);
            setValues({...values, avatarUpdated: false});
            setSubmitError(false);
            localStorage.setItem('theme', values.theme || 2);
            window.location.reload(false);
        },
        onError () {
            setSubmitError(true);
        }
    });
    const [values, setValues] = useState({});
    const [modified, setModified] = useState(false);
    const [fileError, setFileError] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const onChange = (e, { name, value }) => {
        setValues({...values, [name]: value});
        setModified(true);
    };

    useEffect(() => {
        if (user && user.CurrentUser) {
            setValues({name: user.CurrentUser.Name, avatarData: user.CurrentUser.Avatar, theme: user.CurrentUser.Theme});
        }
    }, user, setValues);

    const onUploadAvatar = (e) => {
        if (e.target.files[0].size > TWO_MEGABYTES_IN_BYTES || !e.target.files[0].type.includes('image')) {
            setFileError(true);
            return;
        }

        setFileError(false);
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            console.log(reader.result);
            setValues({...values, ['avatarData']: reader.result, ['avatarUpdated']: true});
            setModified(true);
        };
        reader.onerror = () => setFileError(true);
    };

    const onSubmit = () => {
        console.log(JSON.stringify(values));
        saveUser({
            variables: {
                name: values['name'],
                avatar: values.avatarUpdated ? values.avatarData : null,
                theme: values['theme']
            }
        });
    };

    return (
        <Card fluid={true}>
            <Card.Content>
                <Card.Header>Update Player Info</Card.Header>
                <Card.Description>
                    <Form onSubmit={onSubmit} loading={loading} error={fileError || submitError}>
                        <Form.Group>
                            <Form.Input fluid label='Name' required name="name" value={values.name} placeholder='Player Name' width={6} onChange={onChange} />
                            <Form.Select
                                fluid
                                required
                                label='Theme'
                                name='theme'
                                options={options}
                                placeholder='Theme'
                                value={values.theme}
                                onChange={onChange}
                            />
                        </Form.Group>
                        {fileError && (
                            <Message negative>
                                <Message.Header>File Error</Message.Header>
                                <p>The selected file is likely too big (over 2 MB) or isn't an image! </p>
                            </Message>
                        )}
                        <Form.Group>
                            <Form.Input fluid type="file" label='Avatar URL' name='avatarData' width={6} onChange={onUploadAvatar} />
                        </Form.Group>
                        <Form.Group>
                            <span style={{"border": "1px solid black", "border-radius": "3px", "margin": "5px"}}>
                                <Image style={{"object-fit": "cover"}} width={200} height={200} src={values.avatarData || 'https://react.semantic-ui.com/images/wireframe/white-image.png'} />
                            </span>
                        </Form.Group>
                        <Button type='submit' disabled={!modified}>Submit</Button>
                        {submitError && (
                            <Message negative>
                                <Message.Header>Submit Error</Message.Header>
                                <p>An error occurred trying to submit profile updates. Check your internet connection and try again, or check back later.</p>
                            </Message>
                        )}
                    </Form>
                </Card.Description>
            </Card.Content>
        </Card>
    );
};

export default PlayerUpdatePage;
