import React from 'react';
import {useQuery, useApolloClient} from "@apollo/client";
import {withFirebase} from '../../components/Context/FirebaseContext';
import {GET_CURRENT_USER} from "../../graphql/Queries";
import {Avatar, Divider, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText} from "@mui/material";
import {AccountCircle} from "@mui/icons-material";
import LogoutIcon from '@mui/icons-material/Logout';
import * as ROUTES from "../../constants/routes";
import {useHistory} from "react-router-dom";

const AccountMenu = ({onClose}) => {
    const {data} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'cache-and-network'});
    const user = data && data.CurrentUser ? data.CurrentUser : {};
    const client = useApolloClient();
    const avatarURL = user?.Avatar || '/blank_profile_image.jpg';
    const history = useHistory();

    const playerPageURL = ROUTES.PLAYER_PAGE.replace(':userID', user.UserID);
    const handleClickPlayerProfile = () => {
        onClose();
        history.push(playerPageURL);
    }

    const handleClickAccount = () => {
        onClose();
        window.location.href = ROUTES.ACCOUNT;
    }

    const handleClickSignOut = async () => {
        const sessionKey = localStorage.getItem('sessionKey');
        const formData = new FormData();
        formData.append('action', 'LOGOUT');
        formData.append('authToken', sessionKey);

        fetch(process.env.REACT_APP_GRAPHQL_URL, {
            method: 'POST',
            body: formData,
        }).then(
            async response => {
                const json = await response.json();
                if (json.result === 'SUCCESS') {
                    onClose();
                    localStorage.removeItem('sessionKey');
                    client.clearStore();
                    window.location.assign('/');
                } else {
                    alert('Sign out failure Type 1. Let Haskalah know!');
                }
            }
        )
            .catch(
                error => {
                    console.log(error);
                    alert('Sign out failure Type 2. Let Haskalah know!');
                }
            );
    };

    return (
        <List>
            {user.Permissions &&
                <>
                    <ListItem button key='profile' onClick={handleClickPlayerProfile}>
                        <ListItemAvatar>
                            <Avatar
                                alt="My Profile"
                                src={avatarURL}
                                sx={{width: 32, height: 32}}
                            />
                        </ListItemAvatar>
                        <ListItemText primary="Profile"/>
                    </ListItem>
                    <ListItem button key='account' onClick={handleClickAccount}>
                        <ListItemIcon>
                            <AccountCircle/>
                        </ListItemIcon>
                        <ListItemText primary='My Account'/>
                    </ListItem>
                    <Divider/>
                    <ListItem button key='logout' onClick={handleClickSignOut}>
                        <ListItemIcon>
                            <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText primary='Log Out'/>
                    </ListItem>
                </>
            }
        </List>
    )
};

export default withFirebase(AccountMenu);