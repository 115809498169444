import React, {useEffect, useState} from 'react';
import {useApolloClient, useQuery, useMutation} from '@apollo/client';
import {GET_DISCORD_USER} from "../../../graphql/Queries";
import {GENERATE_AUTH_KEY} from "../../../graphql/Mutations";
import {Button, Icon, Item, Placeholder, Segment} from "semantic-ui-react";

export const PlayerAuthSection = () => {
    const [generatingKey, setGeneratingKey] = useState(false);
    const {data, loading} = useQuery(GET_DISCORD_USER, {fetchPolicy: 'cache-and-network'});
    const [generateAuthKey] = useMutation(GENERATE_AUTH_KEY, {
        onCompleted() {
            setGeneratingKey(false);
        },
        onError() {
            setGeneratingKey(false);
        }
    });

    const generateKey = () => {
        setGeneratingKey(true);
        generateAuthKey();
    }

    const discordUser = data && data.CurrentUser && data.CurrentUser.DiscordUser ? data.CurrentUser.DiscordUser : {};
    // Key is valid if less than 1 hour has passed since generation time.
    const keyValid = ((new Date()).getTime() / 1000) < discordUser.AuthCreateDate + 3600;
    const botText = loading
        ? (
            <Placeholder>
                <Placeholder.Line/>
            </Placeholder>
        )
        : discordUser.AuthKey && keyValid
            ? `!pb authenticate userID=[${discordUser.UserID}] key=[${discordUser.AuthKey}]`
            : `Click 'Generate Key' to generate an auth key.`;

    const copyCommand = () => navigator.clipboard.writeText(botText);

    return (
        <>
            {discordUser.Name && (
                <>
                    <br />
                    <p>
                        <b>Discord User: </b> {discordUser.Name}#{discordUser.Discriminator}
                    </p>
                    <br />
                </>
            )}
            <p style={{display: 'flex'}}>
                <span>
                    <Button loading={generatingKey} disabled={generatingKey} onClick={generateKey}>
                        Generate Key
                    </Button>
                </span>
                    <span style={{width: '70%', marginTop: '-8px', marginLeft: '8px'}}>
                    <Segment raised>{botText}</Segment>
                </span>
                    <span
                        title='Copy Command To Clipboard'
                        style={{marginLeft: '8px'}}
                    >
                    <Button disabled={loading || !discordUser.AuthKey || !keyValid} icon onClick={copyCommand}>
                        <Icon name='copy outline'/>
                    </Button>
                </span>
            </p>
            <br/>
            <p>
                To link your web account to Discord, click <b>Generate Key</b> to generate a key and create the
                PrimordiaBot command needed to authenticate. Then, copy the command (either by highlighting the text and copying it,
                or by clicking the <b>Copy</b> button), paste it into Discord in the #construct channel, and send. The bot
                should respond with a success or failure message. If the command fails, please let Haskalah know.
            </p>
        </>
    );
}