import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Button, Card, Icon, Menu} from 'semantic-ui-react';
import CharacterCard from "./CharacterCard";

const LoadingCards = () => (
    <Card.Group>
        <CharacterCard loading />
        <CharacterCard loading />
        <CharacterCard loading />
    </Card.Group>
);

const CharacterSelect = (props) => {
    const {characters, isConfirming, loading, multiselect, onSelectCharacter, onConfirmSelection, onClickCancel, showButtons} = props;
    const [selectedCharacters, setSelectedCharacters] = useState([]);

    const handleCharacterClick = (characterID) => () => {
        if (selectedCharacters.includes(characterID)) {
            setSelectedCharacters(selectedCharacters.filter(id => id !== characterID));
        } else {
            if (multiselect) {
                setSelectedCharacters([...selectedCharacters, characterID]);
            } else {
                setSelectedCharacters([characterID]);
            }
        }
    };

    if (loading) {
        return <LoadingCards/>
    }

    return (
        <>
            <span style={{paddingBottom: '12px'}}>
                <Card.Group>
                    {
                        !loading && characters && characters.filter(character => character.Status).map(character => (
                            <span
                                style={selectedCharacters.includes(character.CharacterID) ? {borderRadius: '4px', backgroundColor: 'rgb(0, 211, 211)'} : {}}>
                                <CharacterCard
                                    hideStatus
                                    character={character}
                                    key={character.id}
                                    onClick={handleCharacterClick(character.CharacterID)}
                                />
                        </span>
                        ))
                    }
                </Card.Group>
            </span>
            {showButtons && (
                <div style={{height: '28px', marginTop: '16px'}}>
                    <Button
                        negative
                        onClick={onClickCancel}
                        style={{float: 'right'}}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={!selectedCharacters.length}
                        positive
                        loading={isConfirming}
                        onClick={onConfirmSelection(multiselect ? selectedCharacters : selectedCharacters[0])}
                        style={{float: 'right'}}
                    >
                        Confirm
                    </Button>
                </div>
            )}
        </>
    );
}

CharacterSelect.propTypes = {
    characters: PropTypes.array.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    multiselect: PropTypes.bool,
    showButtons: PropTypes.bool,
    isConfirming: PropTypes.bool,
    onSelectCharacter: PropTypes.func,
    onConfirmSelection: PropTypes.func,
};

CharacterSelect.defaultProps = {
    loading: false,
    multiselect: false,
    showButtons: false,
    isConfirming: false,
    onSelectCharacter() {},
    onConfirmSelection() {},
};

export default CharacterSelect
