import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Table} from 'semantic-ui-react';
import {hasAtLeastPermission, hasPermission, PERMISSIONS} from "../../../utilities/PermissionUtils";

const CharacterLogTable = (props) => {
    const {character, user} = props;
    const showSummary = hasAtLeastPermission(user.Permissions, PERMISSIONS.ADMIN);
    const logs = character.Logs || [];
    const getType = type => {
        switch (type) {
            case 1:
                return 'Session';
            case 10:
                return 'Sheet Change';
            case 20:
                return 'Character Creation';
            case 100:
                return 'Daily Claim';
            case 200:
                return 'Spend Mana';
            case 210:
                return 'Modify Mana (GM)';
            case 300:
                return 'Spend Mana (Pay PC)';
            case 301:
                return 'Receive Mana (Payment)';
            case 1000:
                return 'Reward (All Characters)';
            default:
                return '';
        }
    }

    const getMetadataText = (type, metadata) => {
        try {
            JSON.parse(metadata);
        } catch {
            return '';
        }

        const data = JSON.parse(metadata);

        switch (type) {
            case 1:
                return `Reward Level: ${data.APL}, Runtime (h): ${data.Runtime / 60}`;
            case 10:
                return `Old Sheet: ${data.oldSheetURL}, New Sheet: ${data.newSheetURL}`;
            case 100:
                return `Claim Time: ${data.claimDays} Day${data.claimDays !== 1 ? 's' : ''}`;
            case 200:
            case 210:
                return `Reason: ${data.reason}`;
            case 300:
                return `Paid To: ${data.to}, Reason: ${data.reason}`;
            case 301:
                return `Paid By: ${data.from}, Reason: ${data.reason}`;
            default:
                return '';
        }
    }

    const getTime = timestamp => {
        const date = new Date();
        date.setTime(timestamp * 1000);
        return date.toLocaleString();
    }

    const addSpace = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    const getSummary = () => {
        let mana = 0;
        let experience = 0;
        let attunement = 0;

        logs.forEach(log => {
            mana += log.Mana;
            experience += log.Experience;
            attunement += log.Attunement;
        });

        let summary = `Log Summary - Experience: ${addSpace(experience)}, Mana: ${addSpace(mana)}, Attunement: ${addSpace(attunement)}`;

        if (hasPermission(user.Permissions, PERMISSIONS.ADMIN)) {
            summary = `(${character.Experience - experience}) ${summary}`;
        }

        return summary;
    }

    const [column, setColumn] = useState('DateUpdated');
    const [direction, setDirection] = useState('descending');

    const setSort = (sortColumn) => () => {
        if (column === sortColumn) {
            setDirection(direction === 'ascending' ? 'descending' : 'ascending');
        } else {
            setDirection('descending');
            setColumn(sortColumn);
        }
    }

    const getSortedData = () => {
        if (column === null) return logs;
        switch (column) {
            case 'Type':
                const typeSort = [...logs];
                return typeSort.sort((a, b) => {
                    return direction === 'ascending'
                        ? getType(a.Type).localeCompare(getType(b.Type))
                        : getType(b.Type).localeCompare(getType(a.Type));
                });
            case 'Mana':
            case 'Attunement':
            case 'Experience':
            case 'DateUpdated':
                const otherSort = [...logs];
                return otherSort.sort((a, b) => {
                    return direction === 'ascending'
                        ? a[column] - b[column]
                        : b[column] - a[column];
                });
            default:
                return logs;
        }
    }

    const totalMana = useMemo(() => logs.reduce((acc, log) => acc + log.Mana, 0), [logs]);
    const totalExperience = useMemo(() => logs.reduce((acc, log) => acc + log.Experience, 0), [logs]);
    const totalAttunement = useMemo(() => logs.reduce((acc, log) => acc + log.Attunement, 0), [logs]);

    return (
        <Table striped sortable compact='very'>
            <Table.Header>
                <Table.HeaderCell
                    sorted={column === 'Type' ? direction : null}
                    onClick={setSort('Type')}
                >
                    Type
                </Table.HeaderCell>
                <Table.HeaderCell
                    sorted={column === 'Mana' ? direction : null}
                    onClick={setSort('Mana')}
                    width={2}
                >
                    Mana
                </Table.HeaderCell>
                <Table.HeaderCell
                    sorted={column === 'Experience' ? direction : null}
                    onClick={setSort('Experience')}
                    width={2}
                >
                    Experience
                </Table.HeaderCell>
                <Table.HeaderCell
                    sorted={column === 'Attunement' ? direction : null}
                    onClick={setSort('Attunement')}
                >
                    Attunement
                </Table.HeaderCell>
                <Table.HeaderCell>Data</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={column === 'DateUpdated' ? direction : null}
                    onClick={setSort('DateUpdated')}
                >
                    Date
                </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell><b>Total</b></Table.Cell>
                    <Table.Cell><b>{addSpace(totalMana)}</b></Table.Cell>
                    <Table.Cell><b>{addSpace(totalExperience)}</b></Table.Cell>
                    <Table.Cell><b>{addSpace(totalAttunement)}</b></Table.Cell>
                    <Table.Cell/>
                    <Table.Cell/>
                </Table.Row>
                {getSortedData().map(log =>
                    <Table.Row>
                        <Table.Cell>{getType(log.Type)}</Table.Cell>
                        <Table.Cell>{addSpace(log.Mana)}</Table.Cell>
                        <Table.Cell>{addSpace(log.Experience)}</Table.Cell>
                        <Table.Cell>{addSpace(log.Attunement)}</Table.Cell>
                        <Table.Cell>{getMetadataText(log.Type, log.Metadata)}</Table.Cell>
                        <Table.Cell>{getTime(log.DateUpdated)}</Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );
};

CharacterLogTable.propTypes = {
    logs: PropTypes.array,
    user: PropTypes.object,
};

CharacterLogTable.defaultProps = {
    logs: [],
    user: {},
};

export default CharacterLogTable;
