import React, {useState} from 'react';
import PropTypes from 'prop-types'
import {
    Button,
    Header,
    Image,
    Item,
    Menu,
    Placeholder,
    PlaceholderLine,
} from 'semantic-ui-react';
import {useQuery} from "@apollo/client";
import {GET_CURRENT_USER, GET_SESSIONS} from "../../../graphql/Queries";
import {PERMISSIONS, hasPermission} from "../../../utilities/PermissionUtils";
import SessionSetupModal from "./SessionSetupModal";
import {useHistory, useParams} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {ACTIVE_SESSIONS, NEW_SESSIONS, CONCLUDED_SESSIONS} from "../../../constants/routes";

const FOUNDRY_URL = '/Foundry.png';
const ROLL20_URL = '/Roll20.png';
const PLAY_BY_POST_URL = '/PlayByPost.png';

const getStatusText = status => {
    switch (status) {
        case 1:
            return 'New (WIP)';
        case 2:
            return 'Unpublished (Making Adjustments)'
        case 3:
            return 'Published (Ready For Signups)';
        case 4:
            return 'Characters Chosen';
        case 6:
            return 'Session Concluded';
        default:
            return 'Unknown';
    }
}

const getEmptyImage = tab => {
    switch (tab) {
        case "active":
            return '/NoActiveSessions.png';
        case "new":
            return '/NoNewSessions.png';
    }
}

const ItemPlaceholder = () => (
    <Item style={{padding: '8px', borderRadius: '4px'}}>
        <Item.Image size='tiny' src='/android-chrome-512x512.png'/>
        <Item.Content>
            <Placeholder>
                <PlaceholderLine />
                <br />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
            </Placeholder>
        </Item.Content>
    </Item>
);

const SessionList = props => {
    const {loading, sessions, tab} = props;
    const history = useHistory();

    const getFormat = (format) => {
        switch (format) {
            case 1:
                return 'Roll20';
            case 2:
                return 'Foundry';
            case 3:
                return 'Play-By-Post';
            default:
                return 'Unknown';
        }
    }

    const getFormatImage = format => {
        switch (format) {
            case 1:
                return ROLL20_URL;
            case 2:
                return FOUNDRY_URL;
            case 3:
                return PLAY_BY_POST_URL;
            default:
                return '/android-chrome-512x512.png';
        }
    }

    const handleClick = sessionID => () => {
        history.push(`/sessions/session/${sessionID}`);
    }

    const getHeader = () => {
        switch (tab) {
            case 'active':
                return "No Active Sessions";
            case 'new':
                return "No New Sessions";
            default:
                return "No Concluded Sessions";
        }
    }

    const getStartDateString = (startDate) => {
        if (startDate.includes("<t:")) {
            const unixTime = parseInt(startDate.substring(3, startDate.length - 1).trim());
            const date = new Date(unixTime * 1000);
            return date.toLocaleString(undefined, {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                weekday:"long",
                hour: 'numeric',
                hour12: true,
                minute:'2-digit',
                timeZoneName: 'short'
            });
        }

        return startDate;
    }

    return (
        <Item.Group unstackable divided link={!loading || sessions}>
            {(loading && !sessions) &&
                <>
                    <ItemPlaceholder/>
                    <ItemPlaceholder/>
                    <ItemPlaceholder/>
                </>
            }
            {(sessions && sessions.length === 0) &&
                <div>
                    <Header as='h2' icon textAlign='center'>
                        <Header.Content>{getHeader()}</Header.Content>
                    </Header>
                    <div style={{marginTop: '48px'}} />
                    <Image
                        centered
                        size='medium'
                        src={getEmptyImage(tab)}
                    />
                </div>
            }
            {sessions && sessions.map(session => (
                <Item style={{padding: '8px', borderRadius: '4px'}} onClick={handleClick(session.id)}>
                    <Item.Image size='tiny' src={getFormatImage(session.Format)}/>
                    <Item.Content>
                        <Item.Header>{session.Title}</Item.Header>
                        <Item.Meta>{session.Prologue}</Item.Meta>
                        <Item.Content>
                            {session.GameMaster && session.GameMaster.Name && (<><b>Game
                                Master</b> {session.GameMaster.Name}<br/></>)}
                            {session.MinLevel && (<><b>Level Range</b> {session.MinLevel}</>)}
                            {session.MaxLevel && <>&nbsp;- {session.MaxLevel}</>}
                            {session.Difficulty && (<><br/><b>Difficulty</b> {session.Difficulty}</>)}
                            {session.PlayerCount && (<><br/><b>Number of Players</b> {session.PlayerCount}</>)}
                            {session.StartDate && (<><br/><b>Start Date</b> {getStartDateString(session.StartDate)}</>)}
                            {session.EstimatedLength && (<><br/><b>Estimated
                                Duration</b> {session.EstimatedLength} Hours</>)}
                            {session.Format && (<><br/><b>Format</b> {getFormat(session.Format)}</>)}
                            {session.Status && (<><br/><b>Status</b> {getStatusText(session.Status)}</>)}
                        </Item.Content>
                    </Item.Content>
                </Item>
            ))}
        </Item.Group>
    );
};

SessionList.defaultProps = {
    loading: PropTypes.bool.isRequired,
    sessions: PropTypes.array,
    tab: PropTypes.string.isRequired,
};

SessionList.defaultProps = {
    sessions: [],
};

const SessionListPage = () => {
    const {loading, data, refetch} = useQuery(GET_SESSIONS, {fetchPolicy: 'cache-and-network'});
    const {data: userData} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'cache-and-network'});
    const history = useHistory();
    const {tab} = useParams();

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const handleOpenModal = () => setCreateModalOpen(true);

    let activeSessions = data && data.ActiveSessions ? data.ActiveSessions : null;
    let newSessions = data && data.NewSessions ? data.NewSessions : null;
    let concludedSessions = data && data.ConcludedSessions ? data.ConcludedSessions : null;

    activeSessions = activeSessions ? [...activeSessions].sort((a, b) => {
        const dateA = a.DateUpdated || a.DateCreated;
        const dateB = b.DateUpdated || b.DateCreated;

        return dateB - dateA;
    }) : null;

    newSessions = newSessions ? [...newSessions].sort((a, b) => {
        const dateA = a.DateUpdated || a.DateCreated;
        const dateB = b.DateUpdated || b.DateCreated;

        return dateB - dateA;
    }) : null;

    const user = userData && userData.CurrentUser ? userData.CurrentUser : {};

    return (
        <>
            <Header as="h2">Sessions{hasPermission(user.Permissions, PERMISSIONS.GM) && (
                <Button basic color='black' onClick={handleOpenModal} style={{float: 'right'}}>+ Create Session</Button>
            )}</Header>
            <br />
            <Menu tabular={!isMobile} stackable={isMobile} style={{marginBottom: '8px'}}>
                <Menu.Item
                    name='Active Quests'
                    active={tab === "active"}
                    onClick={() => history.push(ACTIVE_SESSIONS)}
                />
                <Menu.Item
                    name='New Quests'
                    active={tab === "new"}
                    onClick={() => history.push(NEW_SESSIONS)}
                />
                <Menu.Item
                    name='Concluded Quests'
                    active={tab === "concluded"}
                    onClick={() => history.push(CONCLUDED_SESSIONS)}
                />
            </Menu>
            {tab === "active" && <SessionList tab={tab} sessions={activeSessions} loading={loading} />}
            {tab === "concluded" && <SessionList tab={tab} sessions={concludedSessions} loading={loading} />}
            {tab === "new" && <SessionList tab={tab} sessions={newSessions} loading={loading} />}
            <SessionSetupModal isOpen={createModalOpen} closeModal={() => setCreateModalOpen(false)} refetch={refetch} />
        </>
    );
};

export default SessionListPage;