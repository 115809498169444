import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {withFirebase} from '../../../components/Context/FirebaseContext';
import * as ROUTES from '../../../constants/routes';
import {Button, Form, Grid, Header, Message,} from 'semantic-ui-react';
import {InMemoryCache, useMutation} from "@apollo/client";
import {SAVE_USER} from "../../../graphql/Mutations";
import {createHttpLink} from "@apollo/client/link/http";

const SignUpPage = () => (
    <Grid centered columns={2}>
      <Grid.Column>
        <Header as="h2" textAlign="center">
          Sign Up
        </Header>
        <SignUpForm />
      </Grid.Column>
    </Grid>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: {},
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL || 'https://primordia.online/graphql/'
});

const cache = new InMemoryCache();

const SignUpFormBase = props => {
  const history = useHistory();
  const [values, setValues] = useState(INITIAL_STATE);
  const [saveUser] = useMutation(SAVE_USER, {
    onCompleted () {}
  });
  const { username, email, passwordOne, passwordTwo, error } = values;

  const onSubmit = event => {
    const roles = {};

    props.firebase
        .doCreateUserWithEmailAndPassword(email, passwordOne)
        .then(async authUser => {
            const token = await authUser.user.getIdToken(true);
            localStorage.setItem('authUser', JSON.stringify({...authUser.user, token}));

            const formData = new FormData();
            formData.append('action', 'CREATE');
            formData.append('authToken', token);
            formData.append('username', username);

            fetch(process.env.REACT_APP_GRAPHQL_URL, {
                method: 'POST',
                body: formData,
            }).then(
                async response => {
                    const result = await response.json();
                    localStorage.setItem('sessionKey', result.token);
                    if (result.accountCreated) {
                        props.firebase.doSendEmailVerification();
                        window.location.href = ROUTES.ACCOUNT;
                    } else {
                        window.location.href = ROUTES.USER_CHARACTERS;
                    }
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            );
        })
        .catch(error => {
          console.log(error);
        });
    event.preventDefault();
  };

  const onChange = event => {
    setValues({...values, [event.target.name]: event.target.value});
  };

  const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

  return (
      <div>
        {error && error.message && (
            <Message negative>
              <p>{error.message}</p>
            </Message>
        )}
        <Form onSubmit={onSubmit}>
          <Form.Field>
            <label>Username</label>
            <input
                name="username"
                value={username}
                onChange={onChange}
                type="text"
                placeholder="Username"
            />
          </Form.Field>
          <Form.Field>
            <label>Email</label>
            <input
                name="email"
                value={email}
                onChange={onChange}
                type="text"
                placeholder="Email Address"
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Password</label>
              <input
                  name="passwordOne"
                  value={passwordOne}
                  onChange={onChange}
                  type="password"
                  placeholder="Password"
              />
            </Form.Field>
            <Form.Field>
              <label>Confirm Password</label>
              <input
                  name="passwordTwo"
                  value={passwordTwo}
                  onChange={onChange}
                  type="password"
                  placeholder="Confirm Password"
              />
            </Form.Field>
          </Form.Group>
          <Button primary disabled={isInvalid} type="submit">
            Sign Up
          </Button>
        </Form>
      </div>
  );
}

const SignUpLink = () => (
    <p>
      Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
    </p>
);

const SignUpForm = withFirebase(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
