import React, {useEffect, useState} from 'react';
import {useQuill} from 'react-quilljs';
import {
    Button, Card,
    Header,
    Icon,
    Item,
    Placeholder,
    PlaceholderLine,
} from 'semantic-ui-react';
import {useApolloClient, useMutation, useQuery} from "@apollo/client";
import {GET_SESSION, GET_CURRENT_USER} from "../../../graphql/Queries";
import SessionSetupModal from "./SessionSetupModal";
import {useParams} from "react-router-dom";
import {SESSION_FRAGMENT} from "../../../graphql/Fragments";
import SessionSignupSection from "./SessionSignupSection";
import {SESSION_PUBLISH, SESSION_SIGNUP} from "../../../graphql/Mutations";
import CharacterCard from "../Character/CharacterCard";
import SessionPlayerSelectSection from "./SessionPlayerSelectSection";
import SessionRewardsModal from "./SessionRewardsModal";
import SessionEpilogue from "./SessionEpilogue";

const FOUNDRY_URL = '/Foundry.png';
const ROLL20_URL = '/Roll20.png';
const PLAY_BY_POST_URL = '/PlayByPost.png';

const getStatusText = status => {
    switch (status) {
        case 1:
            return 'New (WIP)';
        case 2:
            return 'Unpublished (Making Adjustments)'
        case 3:
            return 'Published (Ready For Signups)';
        case 4:
            return 'Characters Chosen';
        case 6:
            return 'Session Concluded';
        default:
            return 'Unknown';
    }
}

const ItemPlaceholder = () => (
    <>
        <Item style={{padding: '8px', borderRadius: '4px'}}>
            <Item.Image size='tiny' src='/android-chrome-512x512.png'/>
            <Item.Content>
                <Placeholder>
                    <PlaceholderLine />
                    <PlaceholderLine />
                    <PlaceholderLine />
                    <PlaceholderLine />
                </Placeholder>
            </Item.Content>
        </Item>
        <Item style={{padding: '8px', borderRadius: '4px'}}>
            <Item.Content>
                <Placeholder>
                    <PlaceholderLine />
                    <br />
                    <PlaceholderLine />
                    <PlaceholderLine />
                    <PlaceholderLine />
                </Placeholder>
            </Item.Content>
        </Item>
        <Item style={{padding: '8px', borderRadius: '4px'}}>
            <Item.Content>
                <span style={{marginLeft: '-8px'}}>
                    <Card.Group>
                        <CharacterCard loading />
                        <CharacterCard loading />
                        <CharacterCard loading />
                    </Card.Group>
                </span>
            </Item.Content>
        </Item>
    </>
);

const SessionPage = () => {
    const {sessionID} = useParams();
    const {loading: loadingSession, data: dataSession, refetch: refetchSession} = useQuery(GET_SESSION, {
        variables: {sessionID},
        fetchPolicy: 'cache-and-network'
    });
    const {loading: userLoading, data: userData} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'cache-and-network'});

    const [setupModalOpen, setSetupModalOpen] = useState(false);
    const handleOpenSetupModal = () => setSetupModalOpen(true);
    const handleCloseSetupModal = () => setSetupModalOpen(false);

    const user = userData && userData.CurrentUser ? userData.CurrentUser : {};

    const client = useApolloClient();
    const sessionFragment = client.readFragment({
        id: 'SessionType:' + sessionID,
        fragmentName: 'SessionFragment',
        fragment: SESSION_FRAGMENT,
    });

    let session = dataSession && dataSession.Session ? dataSession.Session : sessionFragment || {};

    const getFormat = (format) => {
        switch (format) {
            case 1:
                return 'Roll20';
            case 2:
                return 'Foundry';
            case 3:
                return 'Play-By-Post';
            default:
                return 'Unknown';
        }
    }

    const getFormatImage = format => {
        switch (format) {
            case 1:
                return ROLL20_URL;
            case 2:
                return FOUNDRY_URL;
            case 3:
                return PLAY_BY_POST_URL;
            default:
                return '/android-chrome-512x512.png';
        }
    }

    const handleClick = sessionID => () => {
        const test = sessionID;
    }

    const sessionTitle = session.Title ? `Session - ${session.Title}` : <Placeholder><PlaceholderLine /></Placeholder>;

    const [publishSession] = useMutation(SESSION_PUBLISH, {
        onCompleted() {},
        onError() {}
    });
    const togglePublish = () => {
        const variables = {sessionID: session.SessionID};
        if (session.Status === 1 || session.Status === 2) {
            variables.publish = true;
        } else {
            variables.publish = false;
        }
        publishSession({variables});
    }

    const [rewardsModalOpen, setRewardsModalOpen] = useState(false);
    const [setRewards] = useMutation(SESSION_PUBLISH, {
        onCompleted() {},
        onError() {}
    });

    const handleOpenRewardsModal = () => setRewardsModalOpen(true);
    const handleCloseRewardsModal = () => setRewardsModalOpen(false);

    const processPrologue = (prologue) => {
        let result = prologue.replaceAll(/[^a-z0-9*_#\s\n\-.!?%&@"'()]/gi, '');
        result = result.replaceAll('>', '');
        result = result.replaceAll('\n', '<br />');
        const doubleAsteriskRegex = /\*\*(.*?)\*\*/g;
        result = result.replaceAll(doubleAsteriskRegex, (substr) => `<b>${substr.substring(1, substr.length - 1)}</b>`);
        const asteriskRegex = /\*(.*?)\*/g;
        result = result.replaceAll(asteriskRegex, (substr) => `<i>${substr.substring(1, substr.length - 1)}</i>`);
        const underlineRegex = /__(.+)__/g;
        result = result.replaceAll(underlineRegex, (substr) => `<u>${substr.substring(1, substr.length - 1)}</u>`);
        const underscoreRegex = /_(.+)_/g;
        result = result.replaceAll(underscoreRegex, (substr) => `<i>${substr.substring(1, substr.length - 1)}</i>`);
        const regex = /#[1-9]\d*\b/g;
        result = result.replaceAll(regex, (substring => {
            if (substring.length > 17 && substring.length < 21) {
                const channel = substring.substring(1);
                return `<a href="https://discord.com/channels/811689449913843773/${channel}">https://discord.com/channels/811689449913843773/${channel}</a>`;
            }
            return substring;
        }));

        return result;
    }

    return (
        <>
            <Header as="h2"> {sessionTitle}
            {session.GameMaster && user.UserID && (session.GameMaster.UserID === user.UserID) && (
                <>
                    {session.Status < 6 &&
                        <Icon link name='setting' size='tiny' style={{float: 'right'}} onClick={handleOpenSetupModal}/>
                    }
                    {(session.Status < 4) &&
                        <Button
                            positive={session.Status === 1 || session.Status === 2}
                            negative={session.Status === 3}
                            onClick={togglePublish}
                            style={{float: 'right', marginRight: '8px'}}
                        >
                            {session.Status === 1 || session.Status === 2 ? 'Publish' : 'Unpublish'}
                        </Button>
                    }
                    {(session.Status === 4) &&
                        <Button
                            positive
                            onClick={handleOpenRewardsModal}
                            style={{float: 'right', marginRight: '8px'}}
                        >
                            <Icon name='file alternate outline' /> Distribute Rewards
                        </Button>
                    }
                </>
            )}
            </Header>

            <Item.Group unstackable divided>
                {(loadingSession && !session.SessionID)
                    ? <ItemPlaceholder/>
                    :
                    <>
                        <Item style={{padding: '8px', borderRadius: '4px'}}>
                            <Item.Image size='tiny' src={getFormatImage(session.Format)}/>
                            <Item.Content>
                                    {session.GameMaster && session.GameMaster.Name && (<><b>Game
                                        Master</b> {session.GameMaster.Name}<br/></>)}
                                    {session.MinLevel && (<><b>Level Range</b> {session.MinLevel}</>)}
                                    {session.MaxLevel && <>&nbsp;- {session.MaxLevel}</>}
                                    {session.Difficulty && (<><br/><b>Difficulty</b> {session.Difficulty}</>)}
                                    {session.PlayerCount && (<><br/><b>Number of Players</b> {session.PlayerCount}</>)}
                                    {session.StartDate && (<><br/><b>Start Date</b> {session.StartDate}</>)}
                                    {session.EstimatedLength && (<><br/><b>Estimated Duration</b> {session.EstimatedLength} Hours</>)}
                                    {session.Runtime && (<><br/><b>Actual Duration</b> {session.Runtime / 60} Hours</>)}
                                    {session.Format && (<><br/><b>Format</b> {getFormat(session.Format)}</>)}
                                    {session.Status && (<><br/><b>Status</b> {getStatusText(session.Status)}</>)}
                            </Item.Content>
                        </Item>
                        <Item style={{padding: '8px', borderRadius: '4px'}}>
                            <Item.Content>
                                <Item.Header>Prologue</Item.Header>
                                <Item.Description>
                                    <div dangerouslySetInnerHTML={{__html: processPrologue(session.Prologue)}} />
                                </Item.Description>
                            </Item.Content>
                        </Item>
                        {session.Status === 6 && <SessionEpilogue session={session} />}
                        {session.Status > 1 && <SessionSignupSection session={session} user={user} refetchSession={refetchSession} />}
                        {session.Status > 1 && <SessionPlayerSelectSection session={session} refetchSession={refetchSession} user={user} />}
                    </>
                }
            </Item.Group>
            <SessionSetupModal
                session={session}
                refetch={refetchSession}
                isOpen={setupModalOpen}
                closeModal={handleCloseSetupModal}
            />
            <SessionRewardsModal
                isOpen={rewardsModalOpen}
                closeModal={handleCloseRewardsModal}
                session={session}
            />
        </>
    );
};

export default SessionPage;

// <SessionEditModal isOpen={updateModalOpen} closeModal={() => setEditModalOpen(false)} refetchSession={refetchSession} />