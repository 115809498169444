import React, {useState} from 'react';
import {compose} from 'recompose';

import {withAuthorization, withEmailVerification} from '../Features/Session';

const HomePage = () => {
    const [model, setModel] = useState("");
    return (
        <div>
            <h1>Home Page</h1>
            <p>The Home Page is accessible by every signed in user.</p>
        </div>
    );
};

const condition = authUser => !!authUser;

export default HomePage;
