import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    Button, Card,
    Header,
    Item,
    Label,
    Table,
    PlaceholderLine,
} from 'semantic-ui-react';

const getTypeText = rewards => {
    switch (rewards.type) {
        case 'apl':
            return `Rewards By Average Party Level (${rewards.results.APL})`;
        case 'level':
            return 'Rewards By Character Level';
        default:
            return 'Unknown Rewards';
    }
}

const CharacterRewardTable = props => {
    const {rewards, session} = props;

    const getNameComponent = (rewardCharacter, session) => {
        if (rewardCharacter.OperationStatus !== "Success") {
            return <Label ribbon title='Reward Allocation Error' color='red'>{rewardCharacter.Name}</Label>;
        }

        if (rewardCharacter.UserID === session.GameMaster.UserID) {
            return <Label ribbon title='GM Character' color='blue'>{rewardCharacter.Name}</Label>;
        }

        return rewardCharacter.Name;
    }

    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Exp.</Table.HeaderCell>
                    <Table.HeaderCell>Mana</Table.HeaderCell>
                    <Table.HeaderCell>Attune</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {rewards.map(reward => (
                    <Table.Row>
                        <Table.Cell>
                            {getNameComponent(reward, session)}
                        </Table.Cell>
                        <Table.Cell>+{reward.GainedXP}</Table.Cell>
                        <Table.Cell>+{reward.GainedMana}</Table.Cell>
                        <Table.Cell>+{reward.GainedAttunement}</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    )
};

CharacterRewardTable.propTypes = {
    rewards: PropTypes.array.isRequired,
    session: PropTypes.object.isRequired,
};

const PlayerRewardTable = props => {
    const {rewards, session} = props;

    const getNameComponent = (rewardPlayer, session) => {
        if (rewardPlayer.OperationStatus !== "Success") {
            return <Label ribbon title='Reward Allocation Error' color='red'>{rewardPlayer.Name}</Label>;
        }

        if (rewardPlayer.UserID === session.GameMaster.UserID) {
            return <Label ribbon title='GM Player' color='blue'>{rewardPlayer.Name}</Label>;
        }

        return rewardPlayer.Name;
    }

    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Primordia Shards</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {rewards.map(reward => (
                    <Table.Row>
                        <Table.Cell>
                            {getNameComponent(reward, session)}
                        </Table.Cell>
                        <Table.Cell>+{reward.GainedPS}</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};

PlayerRewardTable.propTypes = {
    rewards: PropTypes.array.isRequired,
    session: PropTypes.object.isRequired,
};

const SessionEpilogue = params => {
    const {session} = params;

    const rewards = JSON.parse(session.Rewards);

    return (
        <Item style={{padding: '8px', borderRadius: '4px'}}>
            <Item.Content>
                <Item.Header>Epilogue</Item.Header>
                <Item.Description>
                    {session.Epilogue}
                    <br />
                    <Header as='h4'>Rewards</Header>
                    {getTypeText(rewards)}
                    <Header as='h4'>Character Rewards</Header>
                    <CharacterRewardTable rewards={rewards.results.CharacterRewards} session={session} />
                    <Header as='h4'>Player Rewards</Header>
                    <PlayerRewardTable rewards={rewards.results.PlayerRewards} session={session} />
                </Item.Description>
            </Item.Content>
        </Item>
    );
};

SessionEpilogue.propTypes = {
    session: PropTypes.object.isRequired,
};

export default SessionEpilogue;