import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, Header, Menu} from "semantic-ui-react";
import {isMobile} from "react-device-detect";
import AbilityScoreBox from "../AbilityScoreBox";
import SheetContextProvider, {SheetContext} from "../SheetContext";
import ParagraphTextArea from "../ParagraphTextArea";
import EditableTitle from "../EditableTitle";
import RaceSection from "../../Race/RaceSection";
import "../../Race/RaceTab.scss";
import {useHistory, useParams} from "react-router-dom";
import {CHARACTER_SUBSECTION, CHARACTER_SUBSECTION_INDEX} from "../../../../../constants/routes";
import ClassBasics from "./ClassBasics";
import ClassLevel from "./ClassLevel";
import MultipleChoiceFeatures from "./MultipleChoiceFeatures";

const DEFAULT_CLASS = {
    name: 'New Class',
    source: false,
    BAB: 0.5,
    Fortitude: 0.334,
    Reflex: 0.334,
    Will: 0.334,
    skillsPerLevel: 2,
    levels: [],
    multilevel: []
};

const DEFAULT_CLASS_DATA = [DEFAULT_CLASS];

const ClassesTab = props => {
    const {sheetData, mode, updateSheetData} = useContext(SheetContext);
    const [resetTab, setResetTab] = useState(false);
    const isEditable = mode === "edit";
    let {characterID, tab, sheetTab, index} = useParams();
    const classIndex = parseInt(index);
    const history = useHistory();

    const createNewClass = () => {
        const newDefault = {...DEFAULT_CLASS, levels: []};
        for (let level = 1; level <= 20; level++) {
            newDefault.levels.push({
                level,
                classFeatures: []
            })
        }
        return newDefault;
    }

    let {Classes} = sheetData;
    if (!Classes || !Classes.length || resetTab) {
        setResetTab(false);
        const defaultClass = createNewClass();
        updateSheetData([], 'Classes', [defaultClass]);
        Classes = [defaultClass];
    }
    const [modified, setModified] = useState(false);
    const currentClass = Classes[index];

    const handleChangeName = ({target: {name, value}}) => {
        updateSheetData(['Classes', index], 'name', value);
    }

    const handleClickDelete = deleteIndex => () => {
        const sections = sheetData.Race.Sections;
        const newSections = sections.filter((section, sectionIndex) => sectionIndex !== deleteIndex);
        updateSheetData(['Race', 'Sections'], null, newSections);
    }

    const handleClickAddClass = () => {
        Classes.push(createNewClass());
        updateSheetData([], 'Classes', Classes);
    }

    const getURL = index => {
        return `${CHARACTER_SUBSECTION_INDEX.replace(':characterID', characterID)
            .replace(':tab', tab)
            .replace(':sheetTab', sheetTab)
            .replace(':index', index)}`;
    }

    return (
        <>
            <Menu stackable={isMobile} style={{marginBottom: '8px', marginTop: '-8px', borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}>
                {Classes.map((classData, classIndex) => (
                    <Menu.Item
                        name={classData.name}
                        active={index == classIndex}
                        color={index == classIndex && 'purple'}
                        onClick={() => history.push(getURL(classIndex))}
                    />
                ))}
                <Menu.Item position='right'>
                    <Button color='teal' onClick={handleClickAddClass}>+ Create Class</Button>
                </Menu.Item>
            </Menu>
            <div className='silver-border'>
                <Grid stretched divided='vertically' className='race'>
                    <EditableTitle titleEditable isEditable={isEditable} headingSize='h1' onChange={handleChangeName} value={currentClass.name} name='name' onClickDelete={() => {}} />
                    <ClassBasics characterClass={Classes[classIndex]} isEditable={isEditable} />
                    <MultipleChoiceFeatures classIndex={classIndex} features={currentClass.multilevel} isEditable={isEditable} />
                    {currentClass.levels.map((level, index) => (
                        <ClassLevel classIndex={classIndex} features={level.classFeatures} isEditable={isEditable} level={level.level} />
                    ))}
                </Grid>
            </div>
        </>
    );
};

ClassesTab.propTypes = {
    character: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

export default ClassesTab;
