import React, {useRef, useState} from "react";
import {Alert, Button, Grid} from "@mui/material";
import {useMutation} from "@apollo/client";
import {ADD_INFO_CARD, UPDATE_INFO_CARD, UPLOAD_IMAGE} from "../../../graphql/Mutations";
import PropTypes from "prop-types";

const TWO_MEGABYTES_IN_BYTES = 2000000;

export const INFOCARD_TYPES = {
    CREATURE: 'Creature',
    ITEM: 'Item',
};

const InfoCardContainer = props => {
    const {id, cardInputs, cardPreview, defaultValues, editorProps, allowImageUpload, type, onSuccess, onClose} = props;
    const [values, setValues] = useState(defaultValues);
    const [imageData, setImageData] = useState(null);
    const [fileError, setFileError] = useState(false);
    const [contentError, setContentError] = useState(false);
    const [saveError, setSaveError] = useState(false);
    const inputFileRef = useRef();
    const contentRef = useRef();

    const [uploadImageMutation] = useMutation(UPLOAD_IMAGE, {
        onCompleted() {
        },
        onError(error) {
            console.log(error.message);
        }
    });

    const [addInfoCard] = useMutation(ADD_INFO_CARD, {
        onCompleted() {
        },
        onError(error) {
            console.log(error.message);
        }
    });

    const [updateInfoCard] = useMutation(UPDATE_INFO_CARD, {
        onCompleted() {},
        onError(error) {
            console.log(error.message);
        }
    })

    const onChange = (e, deleteName = null) => {
        if (deleteName) {
            let newValues = {...values, [e.target.name]: e.target.value};
            delete newValues[deleteName];
            setValues(newValues);
        } else {
            setValues({...values, [e.target.name]: e.target.value});
        }
    };

    const handleSelectImage = e => {
        const file = e.target.files[0];
        if (file.size > TWO_MEGABYTES_IN_BYTES || !file.type.includes('image')) {
            setFileError(true);
            return;
        }

        setFileError(false);
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            setImageData(reader.result);
        };
        reader.onerror = () => setFileError(true);
    };

    const saveCard = ({Metadata, ImageID, Content}) => {
        const jsonMetadata = JSON.stringify(Metadata);
        const input = {Metadata: jsonMetadata, Content, Type: type};
        if (ImageID) {
            input.ImageID = ImageID;
        }
        if (!id) {
            return addInfoCard({
                variables: {input}
            });
        } else {
            return updateInfoCard({
                variables: {
                    input,
                    id
                }
            });
        }
    }

    const handleCardSaveSuccess = result => {
        setSaveError(false);
        onSuccess(result.data.InfoCard.Create);
    };

    const handleCardSaveFailure = error => {
        console.log(error);
        setSaveError(true);
    };

    const handleSubmit = () => {
        const {id, Content, ...Metadata} = values;

        if (!Content || !Content.length) {
            setContentError(true);
            return;
        } else {
            setContentError(false);
        }

        if (imageData) {
            uploadImageMutation({
                variables: {
                    type,
                    data: imageData
                }
            }).then((result) => {
                let ImageID = null;
                try {
                    ImageID = !!result.data.Image.Create
                        ? result.data.Image.Create.id
                        : null;
                } catch (e) {
                }

                saveCard({Metadata, ImageID, Content})
                    .then(handleCardSaveSuccess)
                    .catch(handleCardSaveFailure);
            });
        } else {
            saveCard({Metadata, Content})
                .then(handleCardSaveSuccess)
                .catch(handleCardSaveFailure);
        }
    }

    const CardInputs = cardInputs;
    const CardPreview = cardPreview;

    return (
        <>
            {saveError &&
                <Alert severity="error">Save Failed</Alert>
            }
            <CardInputs
                values={values}
                editorProps={editorProps}
                onChange={onChange}
                onSubmit={handleSubmit}
                onSuccess={onSuccess}
                onClose={onClose}
            />
            <Grid container pt={2}>
                {contentError &&
                    <Grid item xs={12}>
                        <Alert severity="error">Content is required.</Alert>
                    </Grid>
                }
                <Grid item xs={6}>
                    <textarea
                        required
                        ref={contentRef}
                        name='Content'
                        value={values.Content || ''}
                        onChange={onChange}
                        style={{minHeight: '100%', minWidth: '100%', maxWidth: '100%'}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CardPreview values={values} image={imageData} />
                </Grid>
                {fileError &&
                    <Grid item xs={12}>
                        <Alert severity="error">File was too large or not an image!</Alert>
                    </Grid>
                }
                {allowImageUpload &&
                    <Grid item xs={12}>
                        <input
                            ref={inputFileRef}
                            style={{display: 'none'}}
                            type="file"
                            onChange={handleSelectImage}
                        />
                        <Button style={{marginTop: '4px'}} variant="outlined" onClick={() => inputFileRef.current.click()}>
                            New Image
                        </Button>
                    </Grid>
                }
            </Grid>
        </>
    );
};

InfoCardContainer.propTypes = {
    allowImageUpload: PropTypes.bool,
    id: PropTypes.number,
    cardInputs: PropTypes.node.isRequired,
    cardPreview: PropTypes.node.isRequired,
    type: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func.isRequired,
    defaultValues: PropTypes.object,
    editorProps: PropTypes.object,
};

InfoCardContainer.defaultProps = {
    allowImageUpload: false,
    id: null,
    defaultValues: {},
    editorProps: {},
    onClose(){},
};

export default InfoCardContainer;