import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {isMobile} from "react-device-detect";
import {Button, Dropdown, Form, Grid, Input, TextArea} from "semantic-ui-react";
import {useParams} from "react-router-dom";
import {SheetContext} from "../SheetContext";

const BASE_ATTACK_BONUS = [
    {key: 'half', text: '+1/2 Per Level', value: 0.5},
    {key: 'three-fourths', text: '+3/4 Per Level', value: 0.75},
    {key: 'one', text: '+1 Per Level', value: 1}
];

const SAVE = [
    {key: 'poor', text: 'Poor', value: 0.334},
    {key: 'good', text: 'Good', value: 0.5},
];

const ClassBasics = props => {
    const {characterClass, isEditable, className} = props;
    const {sheetData, updateSheetData} = useContext(SheetContext);
    const {subTab} = useParams();
    const classIndex = parseInt(subTab);
    const saveWidth = isMobile ? 16 : 5;
    const babWidth = isMobile ? 16 : 7;
    const skillWidth = isMobile ? 16 : 7;
    const basics = {...characterClass};

    const onChange = (e, {name, value}) => {
        const test = sheetData;
        if (name === 'skillsPerLevel') {
            value = parseInt(value);
        }
        updateSheetData(['Classes'], classIndex, {...characterClass, ...basics, [name]: value});
    }

    const getBAB = value => {
        const option = BASE_ATTACK_BONUS.find(opt => opt.value === value) || {};
        return 'BAB: ' + option.text || '';
    }

    const getBABType = value => {
        const option = BASE_ATTACK_BONUS.find(opt => opt.value === value) || {};
        return option.text || 'N/A';
    }

    const getSave = (save, value) => {
        const option = SAVE.find(opt => opt.value === value) || {};
        return save + ': ' + option.text || save;
    }

    const getSaveType = value => {
        const option = SAVE.find(opt => opt.value === value) || {};
        return option.text || 'N/A';
    }

    return isEditable ? (
        <Grid>
            <Grid.Column width={babWidth}>
                <Dropdown fluid value={basics.BAB} name='BAB' selection text={getBAB(basics.BAB)} options={BASE_ATTACK_BONUS} onChange={onChange} />
            </Grid.Column>
            <Grid.Column width={skillWidth}>
                <Input style={{width: '50px'}} name="skillsPerLevel" value={basics.skillsPerLevel} onChange={onChange} label={{basic: true, content: '+ Int Skills/Level'}} labelPosition='right' placeholder='Skills Per Level' />
            </Grid.Column>
            {!isMobile &&
                <Grid.Column width={2} />
            }
            <Grid.Column width={saveWidth}>
                <Dropdown fluid value={basics.Fortitude} name='Fortitude' labeled selection text={getSave('Fortitude', basics.Fortitude)} options={SAVE} onChange={onChange} />
            </Grid.Column>
            <Grid.Column width={saveWidth}>
                <Dropdown fluid labeled value={basics.Reflex} name='Reflex' selection text={getSave('Reflex', basics.Reflex)} options={SAVE} onChange={onChange} />
            </Grid.Column>
            <Grid.Column width={saveWidth}>
                <Dropdown fluid name='Will' selection value={basics.Will} text={getSave('Will', basics.Will)} options={SAVE} onChange={onChange} />
            </Grid.Column>
        </Grid>
    ) : (
        <ul className={className} style={{marginLeft: '18px'}}>
            <li><b>Base Attack Bonus:</b> {getBABType(basics.BAB)}</li>
            <li><b>Fortitude:</b> {getSaveType(basics.Fortitude)}</li>
            <li><b>Reflex:</b> {getSaveType(basics.Reflex)}</li>
            <li><b>Will:</b> {getSaveType(basics.Will)}</li>
            <li><b>Skills Per Level:</b> {basics.skillsPerLevel} + Intelligence Modifier</li>
        </ul>
    );
};

ClassBasics.propTypes = {
    data: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    className: PropTypes.string,
    isEditable: PropTypes.bool,
    paddingWidth: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    renderNode: PropTypes.func.isRequired,
};

ClassBasics.defaultProps = {
    className: '',
    paddingWidth: 0,
    isEditable: false,
};

export default ClassBasics;