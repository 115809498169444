import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Header,
    Item,
    Placeholder,
    PlaceholderLine,
} from 'semantic-ui-react';
import CharacterSelectModal from "./CharacterSelectModal";
import {RemovableCharacterList} from "../Character/CharacterCardPage";
import CharacterCard from "../Character/CharacterCard";
import {useMutation} from "@apollo/client";
import {SELECT_CHARACTERS} from "../../../graphql/Mutations";

const SessionPlayerSelectSection = params => {
    const {refetchSession, session, user} = params;
    const {SessionID: sessionID} = session;

    const signupCharacters = session.SignupCharacters;

    const handleClick = sessionID => () => {
        const test = sessionID;
    }

    const loading = !session || !user;
    const gmUserID = session && session.GameMaster && session.GameMaster.UserID
        ? session.GameMaster.UserID
        : null;

    const userID = user && user.UserID
        ? user.UserID
        : null;

    const userData = user || {Characters: []};

    const canSelectCharacters = () =>
    {
        if (session.Status > 4) {
            return false;
        }

        return gmUserID && userID && gmUserID === userID;
    }

    const [selectPlayerCharacters] = useMutation(SELECT_CHARACTERS, {
        onCompleted() {
            setPlayerCharacterSelectModalOpen(false);
        },
        onError() {
            setPlayerCharacterSelectModalOpen(false);
        }
    });

    const handlePCSelectConfirmation = characterIDs => () => {
        selectPlayerCharacters({
            variables: {
                sessionID,
                pcIDs: characterIDs,
            }
        }).then(() =>setPlayerCharacterSelectModalOpen(false));
    };

    const [selectGMCharacter] = useMutation(SELECT_CHARACTERS, {
        onCompleted() {
            setGMCharacterSelectModalOpen(false);
        },
        onError() {
            setGMCharacterSelectModalOpen(false);
        }
    });

    const handleGMCharacterConfirmation = characterID => () => {
        selectGMCharacter({
            variables: {
                sessionID,
                gmcID: characterID,
            }
        }).then(() =>setGMCharacterSelectModalOpen(false));
    };

    const [playerCharacterSelectModalOpen, setPlayerCharacterSelectModalOpen] = useState(false);
    const handleOpenPCSelectModal = () => setPlayerCharacterSelectModalOpen(true);
    const handleClosePCSelectModal = () => setPlayerCharacterSelectModalOpen(false);

    const [gmCharacterSelectModalOpen, setGMCharacterSelectModalOpen] = useState(false);
    const handleOpenGMSelectModal = () => setGMCharacterSelectModalOpen(true);
    const handleCloseGMSelectModal = () => setGMCharacterSelectModalOpen(false);

    const rewardEligibleGMPCs = user.Characters ? user.Characters.filter(character => character.Approver): [];

    return (
        <>
            <Item style={{padding: '8px', borderRadius: '4px'}}>
                <Item.Content>
                    <div style={{display: 'block'}}><Header>Character Selection</Header></div>
                    <br />
                    <Item.Description>
                        <Header as='h4'>Player Characters{
                            canSelectCharacters() &&
                            <Button basic style={{float: 'right'}} onClick={handleOpenPCSelectModal}>Select PCs</Button>
                        }</Header>
                        {session.PlayerCharacters && session.PlayerCharacters.length
                            ?
                            <>
                                <RemovableCharacterList
                                    hideStatus
                                    characters={session.PlayerCharacters}
                                    loading={loading}
                                    refetch={refetchSession}
                                    user={user}
                                />
                            </>
                            :
                            <p>No PCs selected yet!</p>
                        }
                        <Header as='h4'>GM Reward Character {
                            canSelectCharacters() &&
                                <Button basic style={{float: 'right'}} onClick={handleOpenGMSelectModal}>Select GM Reward Character</Button>
                            }
                        </Header>
                        {session.GameMasterCharacter
                            ?
                                <div style={{marginLeft: '-8px', paddingTop: '8px'}}>
                                    <CharacterCard hideStatus character={session.GameMasterCharacter} loading={loading} />
                                </div>
                            :
                                <p>GM Reward Character not selected yet!</p>
                        }
                    </Item.Description>
                </Item.Content>
            </Item>
            <CharacterSelectModal
                multiselect
                sessionID={session.SessionID}
                isOpen={playerCharacterSelectModalOpen}
                characters={session.SignupCharacters}
                refetchSession={refetchSession}
                closeModal={handleClosePCSelectModal}
                onConfirmSelection={handlePCSelectConfirmation}
            />
            <CharacterSelectModal
                sessionID={session.SessionID}
                isOpen={gmCharacterSelectModalOpen}
                characters={rewardEligibleGMPCs}
                refetchSession={refetchSession}
                closeModal={handleCloseGMSelectModal}
                onConfirmSelection={handleGMCharacterConfirmation}
            />
        </>
    );
};

SessionPlayerSelectSection.propTypes = {
    session: PropTypes.object.isRequired,
    refetchSession: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
}

export default SessionPlayerSelectSection;