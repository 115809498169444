import React from 'react';
import {Label} from "semantic-ui-react";

export const PERMISSIONS = {
    NONE:                 0,
    PLAYER:               1,
    ARTIST:               2,
    ARCHIVIST:           64,
    INSPECTOR:         2048,
    GM:               65536,
    ADMIN:       1073741824,
};

export const getPermissionsArray = userPermissions =>
    Object.values(PERMISSIONS).reduce((acc, permission) => {
        if ((userPermissions & permission) === permission) {
            acc.push(permission);
        }

        return acc;
    }, []);

export const getHighestPermission = userPermissions =>
    Object.keys(PERMISSIONS).reduce((acc, key) => {
        const val = (userPermissions & PERMISSIONS[key]);
        return val === PERMISSIONS[key] && PERMISSIONS[key] > acc ? PERMISSIONS[key] : acc;
    }, PERMISSIONS.NONE);

export const getPermissionTags = (userPermissions, size = 'medium') => {
    const permissions = getPermissionsArray(userPermissions);
    permissions.sort((a, b) => b - a);
    return permissions.map(permission => (
        <span style={{marginRight: '8px'}}>
            {(permission & PERMISSIONS.PLAYER) === PERMISSIONS.PLAYER && <Label size={size} color='purple'>Player</Label>}
            {(permission & PERMISSIONS.ARTIST) === PERMISSIONS.ARTIST && <Label size={size} color='pink'>Artist</Label>}
            {(permission & PERMISSIONS.ARCHIVIST) === PERMISSIONS.ARCHIVIST && <Label size={size} color='green'>Archivist</Label>}
            {(permission & PERMISSIONS.INSPECTOR) === PERMISSIONS.INSPECTOR && <Label size={size} color='teal'>Inspector</Label>}
            {(permission & PERMISSIONS.GM) === PERMISSIONS.GM && <Label size={size} color='blue'>Game Master</Label>}
            {(permission & PERMISSIONS.ADMIN) === PERMISSIONS.ADMIN && <Label size={size} color='yellow'>Admin</Label>}
        </span>
    ));
};

export const getHighestPermissionTag = (userPermissions) => getPermissionTags(getHighestPermission(userPermissions));

export const getPermissionsInteger = permissionsArray =>
    permissionsArray.reduce((permission, acc) => acc += permission, 0);

export const hasPermission = (userPermissions, checkPermission) =>
    (userPermissions & checkPermission) === checkPermission;

export const hasAtLeastPermission = (userPermissions, checkPermission) =>
    (userPermissions >= checkPermission);