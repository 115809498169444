import React, {useMemo, useState} from 'react';
import {Card, CardContent, Grid} from "@mui/material";
import PropTypes from "prop-types";
import DOMPurify from 'dompurify';
import ReactCardFlip from "react-card-flip";
import BestiaryCardToolbar from "./BestiaryCardToolbar";
import {ITEM_TYPE, SLOTS} from "../../../constants/item";

const getColor = text => {
    if (text.includes('COLOSSAL')) {
        return '#3a7a58';
    }

    switch(text) {
        case 'LG':
        case 'NG':
        case 'CG':
        case 'LN':
        case 'TN':
        case 'N':
        case 'CN':
        case 'LE':
        case 'NE':
        case 'CE':
            return '#566193';
        case 'FINE':
        case 'DIMINUTIVE':
        case 'TINY':
        case 'MEDIUM':
        case 'LARGE':
        case 'HUGE':
        case 'GARGANTUAN':
        case 'COLOSSAL':
            return '#3a7a58';
        default:
            return '#5d0000';
    }
}

const SectionBreak = ({isHeader}) => {
    return isHeader
        ?
            <div style={{backgroundColor: '#777777', height: '2px', marginBottom: '0.2rem'}} />
        :
            <div style={{
                backgroundColor: '#CCCCCC',
                height: '2px',
                margin: '1px 0',
                borderBottom: '1px solid #00000066'
            }} />;
};

SectionBreak.propTypes = {
    isHeader: PropTypes.bool,
};

SectionBreak.defaultProps = {
    isHeader: false,
};

const Label = props => {
    const {text} = props;
    const backgroundColor = getColor(text.toUpperCase());
    const fontFamily = "'FF Good Web Condensed', 'Oswald', 'sans-serif'";
    const style = {
        backgroundColor,
        fontFamily,
        minWidth: 'auto',
        textTransform: 'uppercase',
        display: 'inline-block',
        fontSize: '0.9rem',
        lineHeight: '1',
        padding: '0.4rem 0.5rem 0.25rem 0.5rem',
        color: 'white',
        fontWeight: 'bold',
        margin: '0 4px 2px 0',
        textAlign: 'center',
        letterSpacing: '0.05rem'
    };

    return text.length ? <div style={style}>{text.trim()}</div> : <div />;
}

const ABILITY_SU_PARENTHESIS = /^.*\(Su\)/ig;
const ABILITY_EX_PARENTHESIS = /^.*\(Ex\)/ig;
const ABILITY_EMPTY_PARENTHESIS = /^.*\(\)/ig;

const LEVEL_PARENTHESIS = /^[0-9]*.\(*.\)-$/ig;
const USES_TEXT = new RegExp('[0-9]+\/[a-zA-Z]+', 'ig');

const BOLD_MARKDOWN = /(\*\*|__)(.*?)\1/ig;
const ITALICS_MARKDOWN = /(\*|_)(.*?)\1/ig;

const BOLDED_TEXT = [
    ['Init', 'Senses', 'Perception', 'Aura'],
    ['AC', 'HP', 'Regeneration', 'Fort', 'Ref', 'Will', 'Defensive Abilities', 'SR', 'DR', 'Resist', 'Immune'],
    ['Speed', 'Melee', 'Ranged', 'Space', 'Reach', 'Special Attacks'],
    ['Spells Known', 'Spells Prepared', 'Spell-Like Abilities', 'Prohibited Schools', 'Constant', '0 (at will)-', '0-',
      'Cantrips (at will)-', 'Cantrips-', 'Orisons (at will)', 'Orisons', '1st-', '2nd-', '3rd-', '4th-', '5th-',
      '6th-', '7th-', '8th-', '9th-'],
    ['Str', 'Dex', 'Con', 'Int', 'Wis', 'Cha', 'Base Atk', 'CMB', 'CMD', 'Feats', 'Skills', 'Racial Modifiers', 'Languages',
      'Combat Gear', 'Other Gear', 'SQ'],
    ['Description']
];

const SECTION_BREAK = `<div style="background-color: #CCCCCC; height: 2px; margin: 1px 0; border-bottom: 1px solid #00000066;" />`;

const formatText = (text) => {
    let prepText = DOMPurify.sanitize(text, {ALLOWED_TAGS: ['b', 'i']});
    prepText = prepText.replaceAll('\n', "<br />");
    return prepText;
};

const numberWithSpaces = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

const InventoryCard = props => {
    const {values} = props;
    const [isFlipped, setIsFlipped] = useState(false);
    const {Name, CasterLevel, Image, Slot, ItemType, Content, MarketCost, CraftCost} = values;

    const slotFolder = useMemo(() => {
        if (Slot === 18 || Slot === 19) {
            return SLOTS[18];
        } else if (Slot === 2 || Slot === 3) {
            return 'Weapons';
        } else if (Slot === 13 || Slot === 14) {
            return 'Ring';
        }

        return SLOTS[Slot];
    }, Slot);
    const iconImage = Slot && Image && `/items/${slotFolder.toLowerCase()}/${Image}`;

    const getAura = () => {
        if (ITEM_TYPE[ItemType] === 'Artifact') return 'rgb(255 181 56) 0px 0px 25px inset';
        if (typeof MarketCost === 'undefined') return 'white 0px 0px 20px inset';
        if (MarketCost === 0) return 'black 0px 0px 20px inset'
        if (MarketCost < 1000) return 'white 0px 0px 20px inset'
        if (MarketCost < 10000) return 'lightgreen 0px 0px 20px inset'
        if (MarketCost < 100000) return '#3298f7 0px 0px 40px inset'
        return '#8e00b7c9 0px 0px 40px inset';
    }

    return (
        <div style={{
            width: '100%',
            maxWidth: '600px',
            maxHeight: '600px',
            border: '2px solid black',
            borderRadius: '8px',
            padding: '4px',
            fontSize: '14px'
        }}>
            <Grid container justifyContent='center' alignItems='center'>
                <Grid item xs={8}>
                    <h3>{Name}</h3>
                    <div><b>Slot</b> {SLOTS[Slot] || "—"}; <b>CL</b> {CasterLevel || "—"}; <b>Price</b> {MarketCost && MarketCost > 0 ? numberWithSpaces(MarketCost) : "—"}</div>
                    <div><b>Type</b> {ITEM_TYPE[ItemType] || "—"}; <b>Craft Cost</b> {CraftCost && CraftCost > 0 ? numberWithSpaces(CraftCost) : "—"}</div>
                </Grid>
                <Grid item xs={4}>
                    {iconImage &&
                        <div style={{float: 'right', height: '64px'}}>
                            <img style={{borderRadius: '8px', border: '1px solid slategrey', boxShadow: getAura()}} width={64} height={64}
                                 src={iconImage}/>
                        </div>
                    }
                </Grid>
            </Grid>
            <hr />
            <div style={{overflow: 'auto', height: '420px'}} dangerouslySetInnerHTML={{__html: formatText(Content)}}/>
        </div>
    );
};

InventoryCard.propTypes = {
    values: PropTypes.object,
};

InventoryCard.defaultProps = {
    values: {},
};

export default InventoryCard;