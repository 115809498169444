import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Icon, Label} from "semantic-ui-react";
import {hasAtLeastPermission, hasPermission, PERMISSIONS} from "../../../utilities/PermissionUtils";
import {useHistory} from "react-router-dom";
import {ARTICLE_VIEW_PAGE} from "../../../constants/routes";
import {Grid} from "@mui/material";

const ARTICLE_TYPE = {
    0: 'General',
    1: 'Person',
    2: 'Location',
    4: 'Object',
};

const ArticleCard = ({article, user, onClickEdit, onClickPublish}) => {
    const canPublish = hasPermission(user.Permissions, PERMISSIONS.ADMIN) || (hasAtLeastPermission(user.Permissions, PERMISSIONS.ARCHIVIST) && article.Author.id !== user.id);
    const isPublished = !!article.Approver && !!article.Approver.id;
    const canEdit = hasAtLeastPermission(user.Permissions, PERMISSIONS.ARCHIVIST);
    const history = useHistory();

    const handleClickCard = () => {
        history.push(ARTICLE_VIEW_PAGE.replace(':articleID', article.id));
    };

    return (
        <div style={{position: 'relative', minWidth: '100%', margin: '4px 0'}}>
            <Card fluid link onClick={handleClickCard}>
                <Card.Content>
                    <Card.Header>{article.Title}</Card.Header>
                    <Card.Meta>{ARTICLE_TYPE[article.ArticleType]}</Card.Meta>
                    <Card.Description>{article.Summary}</Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <span style={{display: 'flex'}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div style={{margin: 'auto 0 auto 0'}}>
                                    <Icon name='user'/>{article.Author.Name}
                                </div>
                                <div style={{marginLeft: 'auto'}}>
                                    {article.Categories.map(category => (
                                        <Label key={`${article.id}_${category}`}>
                                            {category}
                                        </Label>
                                    ))}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div>
                                    {canPublish && !isPublished &&
                                        <Button icon onClick={onClickPublish}><Icon name="check"/>&nbsp;Publish</Button>
                                    }
                                    {canPublish && isPublished &&
                                        <Button icon onClick={onClickPublish}><Icon
                                            name="times"/>&nbsp;Unpublish</Button>
                                    }
                                    {canEdit &&
                                        <span style={{marginLeft: '8px'}}>
                                            <Button icon onClick={onClickEdit}><Icon name="pencil"/>&nbsp;Edit</Button>
                                        </span>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </span>
                </Card.Content>
            </Card>
        </div>
    )
};

ArticleCard.propTypes = {
    article: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickPublish: PropTypes.func.isRequired,
};

export default ArticleCard;