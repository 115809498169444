import React from 'react';
import PropTypes from 'prop-types';
import './AbilityScoreBox.scss';
import {Button, Form, Grid, Header, Input, TextArea} from "semantic-ui-react";

const Feature = props => {
    const {
        data,
        hasLevelSelector,
        isNameEditable,
        isReplacementEditable,
        isEditable,
        paddingWidth,
        onChange,
        onClickDelete,
        className,
        renderNode,
        title,
    } = props;
    const {name, replacement, description, levels, deletable} = data;
    const mainColumnWidth = 16 - paddingWidth - (deletable ? 1 : 0);
    const textAreaWidth = deletable ? 10 - paddingWidth : 12 - paddingWidth;

    const LEVELS = [];
    for (let level = 1; level <= 20; level++) {
        LEVELS.push({key: level, text: level, value: level});
    }

    const levelRenderLabel = (label) => ({
        color: 'grey',
        content: label.text,
    });

    return isEditable ? (
        <>
            {hasLevelSelector &&
            <Grid.Column width={mainColumnWidth}>
                <Form.Dropdown
                    multiple
                    selection
                    fluid
                    required
                    label='Feature Levels'
                    name='levels'
                    placeholder='Select Level(s) Choices Can Be Made'
                    width={16}
                    options={LEVELS}
                    renderLabel={levelRenderLabel}
                    onChange={onChange}
                    value={levels || null}
                />
            </Grid.Column>
            }
            <Grid.Row stretched className={className}>
                {title !== null &&
                    <>
                        <Grid.Column width={paddingWidth}/>
                        <Grid.Column width={mainColumnWidth}>
                            <Header as='h3'>
                                {title}
                            </Header>
                        </Grid.Column>
                    </>
                }
                {paddingWidth > 0 &&
                    <Grid.Column width={paddingWidth}/>
                }
                <Grid.Column width={4} className={className}>
                    <Input fluid style={{marginBottom: '4px'}} disabled={!isNameEditable} name='name' placeholder='Name'
                           value={name} onChange={onChange}/>
                    <Input fluid disabled={!isReplacementEditable} name='replacement' placeholder='Replaces...'
                           value={replacement} onChange={onChange}/>
                </Grid.Column>
                <Grid.Column width={textAreaWidth} className={className}>
                    <Form>
                        <TextArea fluid rows={3} name='description' placeholder='Description' value={description}
                                  onChange={onChange}/>
                    </Form>
                </Grid.Column>
                {deletable &&
                    <Grid.Column width={2} className={className}>
                        <div>
                            <Button icon="minus" onClick={onClickDelete}/>
                        </div>
                    </Grid.Column>
                }
            </Grid.Row>
        </>
    ) : (
        <>
            {renderNode()}
        </>
    );
};

Feature.propTypes = {
    data: PropTypes.object.isRequired,
    className: PropTypes.string,
    isEditable: PropTypes.bool,
    paddingWidth: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    renderNode: PropTypes.func.isRequired,
    hasLevelSelector: PropTypes.bool,
    title: PropTypes.string,
};

Feature.defaultProps = {
    className: '',
    paddingWidth: 0,
    isEditable: false,
    hasLevelSelector: false,
    title: null,
};

export default Feature;