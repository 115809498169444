import React, {useEffect, useMemo, useState} from 'react';
import QuillTextEditor from "../../CustomComponents/Quill/QuillTextEditor";
import {Header, Label} from "semantic-ui-react";
import {useHistory, useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_ARTICLE, GET_ARTICLE_BY_LINK} from "../../../graphql/Queries";
import {QuillDeltaToHtmlConverter} from "quill-delta-to-html";
import * as ROUTES from "../../../constants/routes";

const ArticleLinkPage = () => {
    const [isQuillLoaded, setIsQuillLoaded] = useState(false);
    const [articleHTML, setArticleHTML] = useState('<div/>');
    const {title} = useParams();
    const history = useHistory();
    const {data, loading: articleLoading} = useQuery(GET_ARTICLE_BY_LINK, {
        variables: {
            title
        },
        fetchPolicy: 'network-only'
    });

    const article = data && data.ArticleLink ? data.ArticleLink : null;
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const lastUpdated = useMemo(() => {
        if (article) {
            const date = new Date(0);
            date.setUTCSeconds(article.DateUpdated);
            return date.toLocaleString(undefined, options);
        } else {
            return null;
        }
    }, [article])

    useEffect(() => {
        if (article && !isQuillLoaded) {
            setIsQuillLoaded(true);
            const converter = new QuillDeltaToHtmlConverter(JSON.parse(article.Post.Content).ops, {});
            const conversion = converter.convert();

            const re = /\[[^\]]*\]]/ig;
            const linkedConversion = conversion.replaceAll(re, match => {
                const matchString = match.substring(2, match.length - 2);
                if (matchString.includes('|')) {
                    const bar = matchString.indexOf('|');
                    const link = matchString.substring(0, bar);
                    const text = matchString.substring(bar + 1);
                    return `<a data-link='${link}'>${text}</a>`;
                }
                return `<a data-link='${matchString}'>${matchString}</a>`;
            });
            setArticleHTML(linkedConversion);
        }
    }, [article]);

    const handleClickArticle = e => {
        const link = e.target.getAttribute('data-link');
        console.log(e.target.tagName);
        if(e.target.tagName === 'A' && link) {
            history.push(ROUTES.ARTICLE_LINK_PAGE.replace(':title', link));
        }
    };

    return article ? (
        <>
            <div style={{paddingBottom: '16px', cursor: 'pointer'}}>
                <a onClick={() => history.goBack()}>← Go Back</a>
            </div>
            <div>
                <Header as='h1'>{article.Title}</Header>
                <div>
                    <b>By:</b> {article.Author.Name}{lastUpdated && (
                    <span><br /><b>Last Updated:</b> {lastUpdated}</span>
                )}<br /><br />
                    {article.Categories.map(category => (
                        <Label>{category}</Label>
                    ))}
                </div>
            </div>
            <div style={{paddingTop: '16px'}}>
                <div onClick={handleClickArticle} dangerouslySetInnerHTML={{__html: articleHTML}} />
            </div>
        </>
    ) : (
        <div style={{paddingLeft: '15px'}}>
            <Header as='h1'>Loading Article...</Header>
        </div>
    );
}

export default ArticleLinkPage;