import React, {useState} from 'react';
import gql from 'graphql-tag';
import {useParams} from 'react-router-dom';
import {useApolloClient, useQuery} from '@apollo/client';
import CharacterUpdateModal from "./CharacterUpdateModal";
import {GET_CHARACTER, GET_CURRENT_USER} from "../../../graphql/Queries";
import {PLAYER_CHARACTERS_COMPANION_FRAGMENT} from "../../../graphql/Fragments";
import {compose} from "recompose";
import {withAuthorization, withEmailVerification} from "../../Features/Session";
import CharacterPageActionButtons from "./CharacterPageActionButtons";
import CharacterPageHeader from "./CharacterPageHeader";
import SheetTabs from "../Sheet/SheetTabs";

const CharacterPage = () => {
    const {characterID} = useParams();
    const {data: userData, refetch: refetchUser} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'cache-and-network'});
    const {data: characterData, refetch: refetchCharacter, loading} = useQuery(GET_CHARACTER, {
        variables: {characterID},
        fetchPolicy: 'cache-and-network'
    });
    const client = useApolloClient();
    const [editCharacterOpen, setEditCharacterOpen] = useState(false);

    const openModal = () => setEditCharacterOpen(true);
    const closeModal = () => setEditCharacterOpen(false);
    const updateCharacter = (values, characterID) => {
        client.writeFragment({
            id: 'CharacterType:' + characterID,
            fragment: gql`
                fragment MyCharacter on Character {
                    Name
                    Race
                    Level
                    Avatar
                    Roles
                    Sheet
                    SheetURL
                    Description
                    DateCreated
                }
              `,
            data: {
                ...values,
            },
        });
    };

    const user = (userData && userData.CurrentUser) || {};
    const character = (characterData && characterData.Character) || {UserID: null, Experience: 0, Logs: []};

    return (
        <>
            <div className="silver-border" style={{paddingBottom: '12px', marginBottom: '12px'}}>
                <CharacterPageHeader character={character} user={user} loading={loading} />
                <CharacterPageActionButtons loading={loading} character={character} openModal={openModal} refetchCharacter={refetchCharacter} user={user} />
            </div>
            {characterData && characterData.Character &&
                <SheetTabs character={character} refetchCharacter={refetchCharacter} user={user} loading={loading} refetchUser={refetchUser} />
            }
            {character.UserID !== null && !loading &&
                <CharacterUpdateModal
                    character={character || {}}
                    isOpen={editCharacterOpen}
                    closeModal={closeModal}
                    updateCharacter={updateCharacter}
                />
            }

        </>
    );
};

const condition = authUser => !!authUser;

export default CharacterPage;
