import React, {useMemo, useState} from "react";
import * as ROUTES from "../../../constants/routes";
import PropTypes from 'prop-types';
import {useEventListener} from "phaser-react-tools";
import {EVENT} from "./MapEvents";
import {Accordion, AccordionDetails, AccordionSummary, Box, Drawer, Grid, MenuItem, Modal, Select} from "@mui/material";
import {useMutation, useQuery} from "@apollo/client";
import {SAVE_MAP_ICONS, SAVE_MAP_TILES} from "../../../graphql/Mutations";
import AdminMapPanel from "./AdminMapPanel";
import {GET_CURRENT_USER} from "../../../graphql/Queries";
import {hasPermission, PERMISSIONS} from "../../../utilities/PermissionUtils";
import AccordionEdit from "./POIAccordion";

const MapUI = props => {
    const {data: userData} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'cache-and-network'});
    const user = userData && userData.CurrentUser ? userData.CurrentUser : {};
    const isAdmin = hasPermission(user.Permissions || 0, PERMISSIONS.ADMIN);

    const {mapData, iconData} = props;
    const [editedIcons, setEditedIcons] = useState(null);
    const icons = editedIcons || iconData;
    const [mapState, setMapState] = useState(mapData);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [tiles, setTiles] = useState(null);
    const [biomeTileImage, setBiomeTileImage] = useState(null);
    const [biomeType, setBiomeType] = useState(null);
    const [coordinates, setCoordinates] = useState(null);
    const [locationTileImage, setLocationTileImage] = useState(null);
    const [locationType, setLocationType] = useState(null);
    const [saveMapTiles] = useMutation(SAVE_MAP_TILES, {
        onError (error) {
            console.log(error.message);
        }
    });
    const [saveMapIcons] = useMutation(SAVE_MAP_ICONS, {
        onError (error) {
            console.log(error.message);
        }
    });

    const iconMap = useMemo(() =>
            icons.icons.reduce((acc, iconDatum) => {
            acc[iconDatum.x + ',' + iconDatum.y] = iconDatum;
            return acc;
        }, [])
    , [icons]);

    const iconDatum = useMemo(() => {
        if (!coordinates) return null;
        return iconMap[coordinates.x + ',' + coordinates.y] || null;
    }, [iconMap, coordinates]);

    useEventListener(EVENT.CLICK_TILE, data => {
        const {x, y, tiles} = data;
        setCoordinates({x, y});
        setDrawerOpen(true);
        setTiles(tiles);
        if (tiles[0]) {
            const imageData = {
                style: {position: 'absolute', top: 0, left: (tiles[0].index - 1) * -128},
                src: 'tilesets/BasicTerrain.png',
            }
            setBiomeTileImage(imageData);
            try {
                setBiomeType(tiles[0].layer.tilemapLayer.tileset[0].tileData[tiles[0].index - 1].type);
            } catch(ex) {}
        }
        if (tiles[2]) {
            const imageData = {
                style: {position: 'absolute', top: 0, left: (tiles[2].index - 1) * -128},
                src: 'tilesets/BasicTerrain.png',
            }
            setLocationTileImage(imageData);
            try {
                setLocationType(tiles[2].layer.tilemapLayer.tileset[0].tileData[tiles[2].index - 1].type);
            } catch(ex) {}
        }
    });

    useEventListener(EVENT.DEFOG_TILE, coordinates => {
        const revealed = mapState.revealed ? mapState.revealed : [];
        const newMapData = {
            ...mapState,
            revealed: [
                ...revealed,
                coordinates,
            ]
        };
        setMapState(newMapData);
        const data = JSON.stringify(newMapData);

        saveMapTiles({
            variables: {
                data
            }
        });
    });

    const handleCloseDrawer = () => {
        setBiomeTileImage(null);
        setLocationTileImage(null);
        setBiomeType(null);
        setLocationType(null);
        setDrawerOpen(false);
    }

    const columnWidth = locationTileImage ? 2 : 1;

    const getArticleLink = (articleID, name) => (
        <a target='_blank' href={ROUTES.ARTICLE_VIEW_PAGE.replace(':articleID', articleID)}>
            {name}
        </a>
    );

    const handleSubmitChange = iconDatum => {
        const otherIcons = icons.icons.filter(element => element.x !== iconDatum.x || element.y !== iconDatum.y);
        const data = {icons: [...otherIcons, iconDatum]};
        saveMapIcons({variables: {data: JSON.stringify(data)}})
            .then(() => {
                setEditedIcons(data);
            });
    }

    return (
        <>
            {isAdmin &&
                <AdminMapPanel/>
            }
            <Drawer open={drawerOpen} onClose={handleCloseDrawer}>
                {tiles &&
                    <div style={{width: '300px', padding: '4px'}}>
                        <hr />
                        <h2 style={{textAlign: 'center', margin: '12px 0'}}>Tile Information</h2>
                        <hr />
                        <Grid container>
                            <Grid item xs={12 / columnWidth}>
                                {biomeTileImage &&
                                    <>
                                        <div
                                            style={{
                                                width: 128,
                                                height: 192,
                                                overflow: 'hidden',
                                                position: 'relative',
                                                margin: '-60px auto 0'
                                            }}
                                        >
                                            <img {...biomeTileImage} />
                                        </div>
                                    </>
                                }
                            </Grid>
                            {locationTileImage &&
                                <Grid item xs={6}>
                                    <div
                                        style={{
                                            width: 128,
                                            height: 192,
                                            overflow: 'hidden',
                                            position: 'relative',
                                            margin: '-60px auto 0',
                                        }}
                                    >
                                        <img {...locationTileImage} />
                                    </div>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Box mt={2}>
                                    <b>Biome: </b> {biomeType}
                                </Box>
                            </Grid>
                            {locationType &&
                                <Grid item xs={12}>
                                    <Box>
                                        <b>Location: </b> {locationType}
                                        {coordinates.x === 0 && coordinates.y === 0 &&
                                            <> (Main Settlement)</>
                                        }
                                    </Box>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Box>
                                    <b>Coordinates:</b> ({coordinates.x}, {coordinates.y})
                                </Box>
                            </Grid>
                            {!iconDatum && isAdmin &&
                                <Grid item xs={12}>
                                    <AccordionEdit
                                        iconDatum={{
                                            ...coordinates,
                                            name: [],
                                            icons: [],
                                            description: [],
                                            articleID: []
                                        }}
                                        index={null}
                                        onSubmit={handleSubmitChange}
                                    />
                                </Grid>
                            }
                            {iconDatum &&
                                <>
                                    <Grid item xs={12}>
                                        <hr />
                                        <h3 style={{textAlign: 'center', margin: '12px 0'}}>Points Of Interest</h3>
                                        <hr />
                                    </Grid>
                                    {isAdmin && (iconDatum.icons.length < 8) &&
                                        <Grid item xs={12}>
                                            <AccordionEdit iconDatum={iconDatum} index={null} onSubmit={handleSubmitChange} />
                                        </Grid>
                                    }
                                    {iconDatum.icons.map((icon, index) =>
                                        isAdmin ? <AccordionEdit iconDatum={iconDatum} index={index} onSubmit={handleSubmitChange} /> :
                                        <>
                                            <Grid item xs={3}>
                                                <div style={{
                                                    display: 'flex',
                                                    height: '100%',
                                                    alignItems: 'center',
                                                    paddingBottom: '4px'
                                                }}>
                                                    <img src={`objects/Icon${icon.toString().padStart(3, '0')}.png`}/>
                                                </div>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <div style={{
                                                    display: 'flex',
                                                    height: '100%',
                                                    alignItems: 'center',
                                                    paddingBottom: '4px'
                                                }}>
                                                    {typeof iconDatum.name !== 'undefined' &&
                                                        <p>
                                                            {typeof iconDatum.articleID !== 'undefined' ?
                                                                    getArticleLink(
                                                                        iconDatum.articleID[index],
                                                                        iconDatum.name[index]
                                                                    )
                                                                :
                                                                    <b>{iconDatum.name[index]}</b>

                                                            }
                                                            {typeof iconDatum.description !== 'undefined' &&
                                                                ` - ${iconDatum.description[index]}`
                                                            }
                                                        </p>
                                                    }
                                                </div>
                                            </Grid>
                                        </>
                                    )}
                                </>
                            }
                        </Grid>
                    </div>
                }
            </Drawer>
        </>
    );
};

MapUI.propTypes = {
    mapData: PropTypes.object.isRequired,
};

export default MapUI;