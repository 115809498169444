const tan60 = 1.732050807568877;

export const getHex = (worldX, worldY, pHexSize = 256) => {
    //const s = pHexSize / 2 / tan60;

    const s = 147;
    const b = (worldY * (2/3)) / s;
    const r = ((tan60 / 3 * worldX) - (worldY/3)) / s;
    const g = -1 * (tan60 / 3 * worldX + (worldY / 3)) / s;
    const sum = r + g + b;
    return {r, g, b, sum};
}

export const ICON_COUNT = 28;