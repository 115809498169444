import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Icon, Modal} from 'semantic-ui-react';
import {PERMISSIONS} from "../../../utilities/PermissionUtils";

const templateFieldTypes = [
    {key: 1, text: "TextField", value: 1},
    {key: 2, text: "Link", value: 2},
    {key: 3, text: "TextBox", value: 3},
    {key: 4, text: "CheckBox", value: 4}
];

const TemplateField = props => {
    const handleChange = (e, { name, value }) => {
        props.onChange(props.index, {...props.field, [name]: value});
    };

    const handleDelete = () => {
        props.onDelete(props.index);
    }

    return (
        <Form.Group>
            <Form.Input width={5} name="fieldName" placeholder="Field Name" value={props.field.fieldName} onChange={handleChange}/>
            <Form.Dropdown
                fluid
                selection
                required
                width={4}
                name="type"
                options={templateFieldTypes}
                placeholder="Type"
                onChange={handleChange}
                value={props.field.type}
            />
            <Form.Input disabled={props.field.type !== 4} width={4} name="values" placeholder="Checkbox Values" value={props.field.values} onChange={handleChange}/>
            <Form.Button icon onClick={handleDelete}>
                <Icon name='minus' />
            </Form.Button>
        </Form.Group>
    );
};

TemplateField.propTypes = {
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    field: PropTypes.object.isRequired,
};

export default TemplateField;