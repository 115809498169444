import React, {Fragment, useContext} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, Header, Input} from "semantic-ui-react";
import EditableTitle from "../EditableTitle";
import Feature from "../Feature";
import {SheetContext} from "../SheetContext";
import ClassLevelChoice from "./ClassLevelChoice";

const ClassLevel = props => {
    const {level, features, classIndex, isEditable, headingSize, paddingWidth} = props;
    const mainColumnWidth = 16 - paddingWidth;
    const {sheetData, updateSheetData, mode} = useContext(SheetContext);
    const multilevels = sheetData.Classes[classIndex].multilevel;
    const handleChange = featureIndex => ({target:{name, value}}) => {
        const location = sheetData.Classes[classIndex].levels[level-1].classFeatures[featureIndex];
        location[name] = value;
        updateSheetData(['Classes', classIndex, 'levels', level-1, 'classFeatures'], featureIndex, location);
    }

    const handleClickDeleteFeature = deleteIndex => () => {
        const classFeatures = sheetData.Classes[classIndex].levels[level-1].classFeatures;
        const newFeatures = classFeatures.filter((feature, featureIndex) => featureIndex !== deleteIndex);
        updateSheetData(['Classes', classIndex, 'levels', level-1], 'classFeatures', newFeatures);
    }

    const handleClickAddFeature = () => {
        const classFeatures = sheetData.Classes[classIndex].levels[level-1].classFeatures;
        classFeatures.push({name: '', replacement: '', deletable: true, description: ''});
        updateSheetData(['Classes', classIndex, 'levels', level-1], 'classFeatures', classFeatures);
    }

    const Tag = isEditable ? Fragment : 'div';

    const renderNode = ({name, replacement, description}) => () => {
        return (
            <p>
                <span style={{fontSize: '16px'}}>
                    <b>{`${name}${replacement ? ` (replaces ${replacement})` : ''}:`}</b>
                </span> {`${description || 'Not Provided'}`}
            </p>
        )
    }

    return (
        <>
            <Grid.Column width={16}>
                <h2>Level {level}</h2>
            </Grid.Column>
            <ClassLevelChoice
                classIndex={classIndex}
                isEditable={isEditable}
                multilevel={multilevels}
                level={level}
            />
            <Tag>
                {features.map((feature, index) => (
                    <Feature
                        className='level'
                        paddingWidth={2}
                        isEditable={isEditable}
                        isNameEditable
                        isReplacementEditable
                        index={index}
                        data={feature}
                        onChange={handleChange(index)}
                        onClickDelete={handleClickDeleteFeature(index)}
                        renderNode={renderNode(feature)}
                    />
                ))}
            </Tag>
            {isEditable &&
            <Grid.Column width={16}>
                <Button style={{width: '140px'}} positive onClick={handleClickAddFeature}>Add Feature</Button>
            </Grid.Column>
            }
        </>
    )
};

ClassLevel.propTypes = {
    level: PropTypes.number,
    features: PropTypes.array,
    classIndex: PropTypes.number,
    headingSize: PropTypes.string,
    paddingWidth: PropTypes.number,
    isEditable: PropTypes.bool,
};

ClassLevel.defaultProps = {
    level: 0,
    features: [],
    headingSize: 'h3',
    paddingWidth: 0,
    isEditable: false,
};

export default ClassLevel;