import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useQuill} from 'react-quilljs';
import {Button, Form, Modal} from "semantic-ui-react";
import {reduceImage} from "../../Helpers/ImageHelper";
import {useMutation} from "@apollo/client";
import {UPLOAD_IMAGE} from "../../../graphql/Mutations";

const QuillTextEditor = props => {
    const {defaultValue, placeholder, name, disabled, setQuill, type, textType} = props;
    const [defaultValueLoaded, setDefaultValueLoaded] = useState(false);
    const [imageModalOpen, setImageModalOpen] = useState(false);

    const [uploadImageMutation] = useMutation(UPLOAD_IMAGE, {
        onCompleted () {
            handleCloseImageModal();
        },
        onError (error) {
            console.log(error.message);
        }
    });

    const NORMAL = {
        modules: {
            toolbar: {
                container: [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{align: []}],

                    [{list: 'ordered'}, {list: 'bullet'}],
                    [{indent: '-1'}, {indent: '+1'}],

                    [{size: ['small', false, 'large', 'huge']}],
                    [{header: [1, 2, 3, 4, 5, 6, false]}],
                    ['link', 'image'],
                    [{color: []}, {background: []}],

                    ['clean'],
                ],
                handlers: {
                    image: () => setImageModalOpen(true)
                }
            },
            clipboard: {
                matchVisual: false,
            }
        },
        formats: [
            'bold', 'italic', 'underline', 'strike',
            'align', 'list', 'indent',
            'size', 'header',
            'link', 'image',
            'color', 'background',
            'clean',
        ]
    };

    const MINIMAL = {
        modules: {
            toolbar: null
        },
        formats: []
    };

    const TYPES = {
        normal: NORMAL,
        minimal: MINIMAL,
    };

    const theme = disabled || type === 'minimal' ? 'bubble' : 'snow';

    const modules = TYPES[type].modules;
    const formats = TYPES[type].formats;

    const {quill, quillRef} = useQuill({defaultValue, theme, modules, formats, placeholder});
    if (quill) {
        quill.enable(!disabled);
    }

    useEffect(() => {
        if (quill) {
            if (defaultValue && !defaultValueLoaded) {
                let contents = null;
                setDefaultValueLoaded(true);
                try {
                    contents = JSON.parse(defaultValue);
                    quill.setContents(contents);
                } catch {
                    quill.setText(defaultValue);
                }
            }
            setQuill(quill, name);
        }
    }, [quill, defaultValue, defaultValueLoaded, setDefaultValueLoaded]);

    const styles = !disabled ? {border: '1px solid lightgray', marginTop: '4px', marginBottom: '4px'} : {};

    const [uploadImage, setUploadImage] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const onUploadImage = (e, val) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            reduceImage(reader.result, ImageData => setUploadImage(ImageData), 600);
        };
        reader.onerror = error => console.log(error);
    };

    const handleUploadImage = () => {
        uploadImageMutation({
            variables: {
                type: textType,
                data: uploadImage
            }
        }).then((result) => {
            if (!result || !result.data || !result.data.Image || !result.data.Image.Create) {
                return false;
            }
            const data = result.data.Image.Create;
            const range = quill.getSelection(true);
            const url = `/images/${data.Filename}`;
            quill.insertEmbed(range.index, 'image', url);
            quill.setSelection(range.index + 1);
            handleCloseImageModal();
        })
    };

    const handleCloseImageModal = () => {
        setUploadImage(null);
        setImageModalOpen(false);
    };

    return (
        <>
            <b>{name}</b><br/>
            <div style={styles}>
                <div ref={quillRef}/>
            </div>
            <Modal
                onClose={handleCloseImageModal}
                open={imageModalOpen}
            >
                <Modal.Content>
                    <Form>
                        <Form.Group>
                            <Form.Input fluid type='file' label='Image' width={16}
                                        placeholder='Select File' accept="image/*" onChange={onUploadImage} />
                        </Form.Group>
                        <Form.Group>
                            <img style={{objectFit: 'cover', margin: '0 auto'}}
                                 src={uploadImage || 'https://react.semantic-ui.com/images/wireframe/white-image.png'} />
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button disabled={isUploading} negative onClick={handleCloseImageModal}>
                        Cancel
                    </Button>
                    <Button
                        loading={isUploading}
                        disabled={isUploading || !uploadImage}
                        content="Upload"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={handleUploadImage}
                        positive
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};

QuillTextEditor.propTypes = {
    name: PropTypes.string,
    setQuill: PropTypes.func,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    textType: PropTypes.string,
};

QuillTextEditor.defaultProps = {
    placeholder: '',
    defaultValue: '',
    name: '',
    setQuill() {},
    disabled: false,
    type: 'normal',
    textType: 'Generic'
};

export default QuillTextEditor;
