import React from 'react';
import {useHistory, useParams} from 'react-router-dom';

import {Header, Menu} from 'semantic-ui-react';
import ApplicationTemplateCreatePage from "./Application/ApplicationTemplateCreatePage";
import {useQuery} from "@apollo/client";
import {GET_CURRENT_USER} from "../../graphql/Queries";
import {isMobile} from "react-device-detect";
import {ADMIN_TEMPLATES} from "../../constants/routes";

const AdminPage = () => {
    const {loading: userLoading, data: userData, refetch: userRefetch} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'cache-and-network'});
    const {tab} = useParams();
    const history = useHistory();

    return (
        <div>
            <Header as="h2">Admin</Header>
                <Menu tabular={!isMobile} stackable={isMobile} style={{marginBottom: '8px'}}>
                    <Menu.Item
                        name='Templates'
                        active={tab === "default" || tab === "templates"}
                        onClick={() => history.push(ADMIN_TEMPLATES)}
                    />
                </Menu>
                {(tab === "templates" || tab === 'default') && <ApplicationTemplateCreatePage />}
        </div>
    );
}

export default AdminPage;
